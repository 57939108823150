<script >
// import netFeedback from "@/components/netFeedback.vue";

export default {
  data(){
    return{
      dialogNet:false
    }
  },
  // components: {netFeedback},
  computed:{
    isPC() {
      let creenWidth=window.innerWidth
      console.log("this.creenWidth-------",creenWidth)
      return creenWidth>1000;
    },
  },
  mounted() {

    let creenWidth=window.innerWidth
    console.log("-------------creenwidth",creenWidth)
    // if(creenWidth<1000){
    //
    // }
  },
  methods:{
    uptop(){
      console.log("zhiding")
      this.$emit("uptop",true)
    },
    upkefu(){
      window.open("https://channel-scrm.xiaoshouyi.com/embeded/im/iframe-h5.html?tenantToken=60cb1c79deb2cf2b3e2c53ad2ed91d09&siteId=3588905289569767&webPageTitle=在线咨询")
    },
    showNet(){
      window.open("/netFeedback")
    },
    hideNet() {
      localStorage.setItem("skip", 0)
      this.dialogNet = false;
    },
  }
}
</script>

<template>

  <div :class="[isPC ? 'pc-style' : 'mobile-style']">
<!--    <div class="uptop"  @click="uptop"></div>-->
<!--    <div style="height: 1px;background-color: rgba(128,128,128,0.3);width: 100%"></div>-->
    <!-- <div style="height: 1px;background-color: rgba(128,128,128,0.3);width: 100%"></div> -->
    <!-- <div style="height: 1px;background-color: #15257b;width: 100%"></div> -->
    <div style="padding: 0.5vh 0 0 ;" @click="upkefu">
      <div class="upkefu" ></div>
      <div class="title1" > 在线咨询</div>
    </div>
    <div style="padding:  0 0 1vh;" @click="showNet">
      <div class="upkefu2"></div>
      <div class="title1" > 服务反馈</div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.pc-style {
  z-index: 99;
  cursor: pointer;
  position: fixed;
  top: 40%;
  right: 0;
  padding: 5px;
  width: 3vw;
  height: fit-content;;
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
  // background-color: rgb(50, 119, 220);
  // background-color: #15257b;
  background-color: rgba(8,78,138,1);

  //border-radius: 50%;
  //box-shadow: 5px 0 14px 0px #D4E4FF;

  .uptop {
    width: 2vw;
    height: 2vw;

    margin: 1vh auto;
    background-image: url("../assets/up.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
  }

  .upkefu {
    margin: 0.5vh auto;
    width: 2vw;
    height: 2vw;
    background-image: url("../assets/kefu2.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
  }
  .title1{

    font-size: 0.6vw;text-align: center;margin-top: 0.5vh;color: white
  }
  .feedback {
    padding: 0.5vh 0;
    margin: 1vh auto;
    font-size: 0.8vw;
    text-align: center;
    color: white;
  }

}
.mobile-style{
  cursor: pointer;
  position: fixed;
  top:40%;
  z-index: 98  ;
  right:0;
  background-color: rgb(8, 78, 137);
  padding:1vw;
  width: 15vw;
  height:fit-content;
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
  //border-radius: 50%;
  //border: 5px solid #1295d9;
  .uptop{
    width:15px;
    height:15px;
    margin:5px auto;
    background-image: url("../assets/up.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
  }
  .upkefu{
    margin:0.5vh auto;
    width:7vw;
    height:7vw;
    background-image: url("../assets/Vector (3).png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
  }
  .upkefu2{
    margin:0.5vh auto;
    width:9vw;
    height:9vw;
    background-image: url("../assets/Frame.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
  }
  .title1{

    font-size: 3vw;text-align: center;margin-top: 0.5vh;color: white
  }
  .wechaticon {
    width: 200px;
    height: 50px;
    background-color: #f0f0f0;
    transition: background-color 0.3s; /* 平滑过渡效果 */
  }
  .wechaticon.show-tooltip .tooltip {
    /* 当showTooltip为true时显示提示框 */
    opacity: 1;
  }

  .wechaticon:hover {
    background-color: #3498db; /* 鼠标悬停时的背景颜色 */
  }

  .feedback{
    cursor: pointer;
    width: fit-content;
    height:fit-content;
    color: white;
    font-size: 8px;
    margin:0 auto;
    text-align: center;
    //padding: 10px 0;
    display: flex;
    justify-content: center;
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
    //border: 5px solid #1295d9;
  }
}
</style>
