<script>
export default {
  methods:{
    upkefu(){
      window.open("https://channel-scrm.xiaoshouyi.com/embeded/im/iframe-h5.html?tenantToken=60cb1c79deb2cf2b3e2c53ad2ed91d09&siteId=3588905289569767&webPageTitle=在线咨询")
    },
    navigate(path){
      this.$emit("navigate",path)
    }
  }
}

</script>

<template>
<div class="OurServiceContainer">
  <div class="title">
    我们的服务
  </div>
  <div class="titlenew">
    工程地质融合，深挖增产潜能，助力油气田开发提效
  </div>
  <div class="learnMore" @click="upkefu()">
               定制您的专属服务
            </div>
  <div class="card">

    <div class="imgDesign" >
      <img src="@/assets/image 40.png">
      <div class="mengban">
        <div class="mengbanspan">
          油藏地质精细研究服务
        </div>
      </div>
      <!--    <div class="imgButton">-->
      <!--      <span>立即体验</span>-->
      <!--    </div>-->
    </div>
    <div @click="navigate('comment')" class="item">
      <img src="../../assets/Arrow 2.png">
      &nbsp;&nbsp;油藏地质评价服务
    </div>
    <div @click="navigate('exploit')" class="item">
      <img src="../../assets/Arrow 2.png">
      &nbsp;&nbsp;开发方案设计服务
    </div>
    <div class="item">
      <img src="../../assets/Arrow 2.png">
      &nbsp;&nbsp;油气藏研究特色技术
    </div>
  </div>
  <div class="card">

    <div class="imgDesign" >
      <img src="@/assets/image (6).png">
      <div class="mengban">
        <div class="mengbanspan">
          地质工程相结合的技术服务
        </div>
      </div>
      <!--    <div class="imgButton">-->
      <!--      <span>立即体验</span>-->
      <!--    </div>-->
    </div>
    <div @click="navigate('intelligent')" class="item">
      <img src="../../assets/Arrow 2.png">
      &nbsp;&nbsp;智能工程迭代优化技术服务
    </div>
    <div @click="navigate('connect')" class="item">
      <img src="../../assets/Arrow 2.png">
      &nbsp;&nbsp;地质工程相结合老井挖潜系列技术
    </div>

  </div>
  <div class="card">

    <div class="imgDesign" >
      <img src="@/assets/image (7).png">
      <div class="mengban">
        <div class="mengbanspan">
          油气藏增产改造技术服务
        </div>
      </div>
      <!--    <div class="imgButton">-->
      <!--      <span>立即体验</span>-->
      <!--    </div>-->
    </div>
    <div @click="navigate('single')" class="item">
      <img src="../../assets/Arrow 2.png">
      &nbsp;&nbsp;一体化增产技术服务及配套技术
    </div>
    <div @click="navigate('integration')" class="item">
      <img src="../../assets/Arrow 2.png">
      &nbsp;&nbsp;特色增产产品
    </div>

  </div>
  <div class="card">

    <div class="imgDesign" >
      <img src="@/assets/image (8).png">
      <div class="mengban">
        <div class="mengbanspan">
          油气田开发动态监测服务
        </div>
      </div>
      <!--    <div class="imgButton">-->
      <!--      <span>立即体验</span>-->
      <!--    </div>-->
    </div>
    <div @click="navigate('istributevue')" class="item">
      <img src="../../assets/Arrow 2.png">
      &nbsp;&nbsp;分布式光纤实时监测技术
    </div>
    <div @click="navigate('number')" class="item">
      <img src="../../assets/Arrow 2.png">
      &nbsp;&nbsp;数字岩⽯岩屑
    </div>
    <div @click="navigate('gasreservoir')" class="item">
      <img src="../../assets/Arrow 2.png">
      &nbsp;&nbsp;油气藏测井技术
    </div>

  </div>
  <div class="card" style="padding-bottom: 3vh">

    <div class="imgDesign" style="">
      <img src="@/assets/image (9).png">
      <div class="mengban">
        <div class="mengbanspan">
          油气田一体化增产总包服务
        </div>
      </div>
      <!--    <div class="imgButton">-->
      <!--      <span>立即体验</span>-->
      <!--    </div>-->
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
.OurServiceContainer{
  .title{
    color: black;
    padding: 4vh 0 2vh 0;
    font-size: 6vw;
    letter-spacing: 0.5vw;
  }
  .titlenew{
  color: black;
    font-size: 5vw;
    padding: 0 0 2vh 0;
}
.learnMore{
      // font-size: 5vw;
      // font-weight: bolder;
      color: #0B71D6;
    }
  .imgDesign {
    margin: 3vh 0 1vh;
    position: relative;
    width: 100%;
    height: 37vw;
    border-radius: 9px;
    overflow: hidden;
    img{
      object-fit: contain;

    }
    .mengban{
      width: 100%;
      height: 20vw;
      background: linear-gradient(to bottom, rgb(0, 0, 0,0.6), rgba(0, 0, 0, 0.2),rgba(0, 0, 255, 0));
      position: absolute;
      top:0;
      .mengbanspan{
        color: white;
        font-size: 4.5vw;
        letter-spacing: 0.5vw;
        margin: 3vw;
      }
    }
    .imgButton{
      position: absolute;
      display: flex;
      left: 0;
      right: 0;
      bottom: 2vh;
      span{
        margin: auto;
        color: white;
        padding: 0.3vh 6vw;
        border-radius: 15px;
        background-color: #123456;
      }
    }

  }
  .card{
    .item{
      padding: 1vw 0;
      color: #084E8A;
      img{

        width: 6vw;
        height: 2vw;
      }
    }

  }
}
</style>
