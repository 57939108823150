<script>
export default {
  methods:{
    showNet(){
      console.log("----chuda---------------")
      window.open("/netFeedback")
    },
    upkefu(){
      window.open("https://channel-scrm.xiaoshouyi.com/embeded/im/iframe-h5.html?tenantToken=60cb1c79deb2cf2b3e2c53ad2ed91d09&siteId=3588905289569767&webPageTitle=在线咨询")
    },
  }

}
</script>

<template>
<div class="FeedbackContainer">
  <div style="padding: 0 10px">
    <div class="title">
      您的服务与反馈
    </div>
    <div class="title2">
      让我们更好地服务您
    </div>
  </div>

  <div class="imagedesign" >
    <img src="../../assets/image 39.png">
  </div>
  <div style="padding: 0 10px 2vh">
    <div class="title3" @click="showNet">
      您的反馈将帮我们优化方案，提升效率，为您创造更大的价值。
      <img src="../../assets/Arrow 2.png" style="width: 5vw">
      &nbsp;&nbsp;<span style="color: #084E8A">留下建议</span>
    </div>
  </div>
  <div class="imagedesign" >
    <img src="../../assets/image (4).png">
  </div>
  <div style="padding: 0 10px 2vh">
    <div class="title3" @click="upkefu">
      <span style="color: #084E8A">在线咨询<br></span>
      <div style="font-size: 5vw;margin: 1vh 0">
        一起找到最佳解决方案<br>
      </div>
      携手制定专属策略，祝您实现目标。<br>
      <img src="../../assets/Arrow 2.png" style="width: 5vw">
      &nbsp;&nbsp;<span style="color: #084E8A">开启咨询</span>
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
.FeedbackContainer{
  .title{
    font-size: 6vw;
    color: #084E8A;
    padding: 4vh 0 2vh;
  }
  .title2{
    font-size: 5vw;
    letter-spacing: 0.5vw;
  }
  .imagedesign{
    width: 100vw;
    height: 50vw;
    margin: 3vh 0;
    position: relative;
    img{
      position: absolute;
      top: 0;
    }
  }
  .title3{
    font-size: 3.3vw;
    line-height: 6vw;
  }
}
</style>
