var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"AboutUsContainer"},[_c('div',{staticClass:"title"},[_vm._v(" 关于我们 ")]),_c('div',{staticClass:"title2"},[_vm._v(" 研究院简介 ")]),_c('div',{staticClass:"title3"},[_vm._v(" 以油藏地质和工程技术为核心，地质工程相结合，增产特色，配合数字化、可视化手段，为油田客户提供增产提效研究优化解决方案服务，提高工程开发效率。 ")]),_vm._m(0),_vm._m(1),_vm._m(2),_c('div',{staticClass:"video-container",staticStyle:{"margin":"1vh 0"}},[_c('video',{ref:"myVideo",staticStyle:{"border-radius":"9px"},attrs:{"id":"myVideo","width":"100%","height":"100%","controls":""}},[_c('source',{attrs:{"src":require("@/video/Newyanjiu.mp4"),"type":"video/mp4"}})])]),_vm._m(3),_c('div',{staticClass:"title"},[_vm._v(" 联系我们 ")]),_vm._m(4),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"block"},[_c('div',{staticClass:"imgblock"},[_c('img',{attrs:{"src":require("../../assets/Vector.png")}})]),_c('div',{staticClass:"titleblock"},[_c('div',[_c('span',{staticStyle:{"font-size":"17vw"}},[_vm._v("100"),_c('br')]),_c('span',{staticStyle:{"font-size":"4.5vw"}},[_vm._v("我们拥有100个油藏数据库")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"block",staticStyle:{"background-color":"#123456","border":"#8c939d 2px solid"}},[_c('div',{staticClass:"imgblock"},[_c('img',{staticStyle:{"height":"20vw","width":"22vw"},attrs:{"src":require("../../assets/Capa_1.png")}})]),_c('div',{staticClass:"titleblock"},[_c('div',[_c('span',{staticStyle:{"font-size":"17vw","color":"white"}},[_vm._v("55"),_c('br')]),_c('span',{staticStyle:{"font-size":"4.5vw","color":"white"}},[_vm._v("我们服务55+国家和地区")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"block2"},[_c('div',{staticClass:"item"},[_c('div',{staticStyle:{"width":"100%"}},[_c('span',{staticStyle:{"font-size":"17vw"}},[_vm._v("5000"),_c('br')]),_c('span',{staticStyle:{"font-size":"4.6vw"}},[_vm._v("拥有5000名资深专家")])])]),_c('div',{staticClass:"item"},[_c('div',{staticStyle:{"width":"100%"}},[_c('span',{staticStyle:{"font-size":"17vw"}},[_vm._v("300"),_c('br')]),_c('span',{staticStyle:{"font-size":"4.6vw"}},[_vm._v("拥有300多项特色技术")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"imgDesign"},[_c('img',{staticClass:"backimg",attrs:{"src":require("@/assets/image 41.png")}}),_c('div',{staticStyle:{"position":"absolute","bottom":"0","width":"100%","padding":"2.5vw","display":"flex"}},[_c('div',{staticClass:"part1"},[_c('div',{staticClass:"imgdesign"},[_c('img',{attrs:{"src":require("../../assets/Group.png")}})]),_c('div',{staticClass:"title1",staticStyle:{"padding":"1vh 0","font-size":"4.5vw"}},[_vm._v(" 专业⼈才共创平台 ")]),_c('div',{staticClass:"title1"},[_vm._v(" 油⽓藏研究专业⼈才"),_c('br'),_vm._v(" 属地专家⼈才"),_c('br'),_vm._v(" 地质⼯程相结合⼈才"),_c('br')])]),_c('div',{staticClass:"part1",staticStyle:{"border-left":"white 1px solid","padding":"0 3vw"}},[_c('div',{staticClass:"imgdesign"},[_c('img',{attrs:{"src":require("../../assets/Vector (2).png")}})]),_c('div',{staticClass:"title1",staticStyle:{"padding":"1vh 0","font-size":"4.5vw"}},[_vm._v(" 企业共创平台 ")]),_c('div',{staticClass:"title1"},[_vm._v(" 油⽓藏研究型企业共创"),_c('br'),_vm._v(" 油⽓藏服务型企业共创"),_c('br'),_vm._v(" ⼀体化⼯程技术服务"),_c('br')])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticStyle:{"color":"white","font-size":"3.5vw","line-height":"6vw"}},[_vm._v(" 如有任何疑问，请使用以下信息"),_c('br'),_vm._v(" 发送电子邮件、致电或访问我们的办公室 ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"endblock",staticStyle:{"padding-bottom":"6vh"}},[_c('div',{staticClass:"end"},[_c('div',{staticClass:"imgdesign"},[_c('img',{attrs:{"src":require("@/assets/Vector (3).png")}})]),_c('div',{staticClass:"textdesign"},[_c('div',{staticClass:"txt1"},[_vm._v(" 在线咨询 ")]),_c('div',{staticClass:"txt2"},[_vm._v(" 24-7 全天候在线 ")])])]),_c('div',{staticClass:"end",staticStyle:{"margin-top":"2vh"}},[_c('div',{staticClass:"imgdesign"},[_c('img',{staticStyle:{"height":"5.5vw"},attrs:{"src":require("@/assets/Vector (4).png")}})]),_c('div',{staticClass:"textdesign"},[_c('div',{staticClass:"txt1"},[_vm._v(" 邮箱 ")]),_c('div',{staticClass:"txt2"},[_vm._v(" OGDRl@antonoil.com ")])])])])
}]

export { render, staticRenderFns }