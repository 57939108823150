<template>
  <div>
  <div class="scroll-container">
    <div class="scroll-content">
      <!-- 显示原始图片 -->
      <div class="back"  v-for="(element) in expert" :key="element.id">
<!--        <img :src="src" :alt="'Image ' + index" />-->
        <div class="card">
          <div class="imageDesign">

            <img :src="element.img">
          </div>
          <div class="titleDesign" >
<!--            <div class="title1">-->
<!--              {{ element.title }}-->
<!--            </div>-->
            <div class="title2">
              {{ element.contend }}
            </div>
          </div>
        </div>
      </div>
      <div class="back"  v-for="(element) in expert" :key="element.id">
        <!--        <img :src="src" :alt="'Image ' + index" />-->
        <div class="card">
          <div class="imageDesign">

            <img :src="element.img">
          </div>
          <div class="titleDesign" >
            <!--            <div class="title1">-->
            <!--              {{ element.title }}-->
            <!--            </div>-->
            <div class="title2">
              {{ element.contend }}
            </div>
          </div>
        </div>
      </div>
<!--      <div class="back"  v-for="(element) in expert" :key="element.id">-->
<!--        &lt;!&ndash;        <img :src="src" :alt="'Image ' + index" />&ndash;&gt;-->
<!--        <div class="card">-->
<!--          <div class="imageDesign">-->

<!--            <img :src="element.img">-->
<!--          </div>-->
<!--          <div class="titleDesign" >-->
<!--            <div class="title1">-->
<!--              {{ element.title }}-->
<!--            </div>-->
<!--            <div class="title2">-->
<!--              {{ element.contend }}-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="image" v-for="(src, index) in images" :key="'original-' + index">-->
<!--&lt;!&ndash;        <img :src="src" :alt="'Image ' + index" />&ndash;&gt;-->
<!--      </div>-->
<!--       显示复制的图片，用于无缝滚动 -->
<!--      <div class="image" v-for="(src, index) in images" :key="'copy-' + index">-->
<!--        <img :src="src" :alt="'Image ' + index + ' copy'" />-->
<!--      </div>-->
    </div>
  </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      expert: [
        {
          id:1,
          img: require("@/assets"+"/expert/user1.jpg"),
          title: "陈专家",
          contend: "构造解释专家"
        },
        {
          id:2,
          img: require("@/assets"+"/expert/user2.png"),
          title: "陈专家",
          contend: "增产技术专家"
        },
        {
          id:3,
          img: require("@/assets"+"/expert/user3.jpg"),
          title: "程专家",
          contend: "开发地质专家"
        },
        {
          id:4,
          img: require("@/assets"+"/expert/user4.png"),
          title: "陈专家",
          contend: "构造解释专家"
        },
        {
          id:5,
          img: require("@/assets"+"/expert/user5.jpg"),
          title: "田专家",
          contend: "整体解决方案专家"
        },
        {
          id:6,
          img: require("@/assets"+"/expert/user6.png"),
          title: "梁专家",
          contend: "测井解释专家"
        },

        {
          id:7,
          img: require("@/assets"+"/expert/user7.jpg"),
          title: "王专家",
          contend: "油气藏工程专家"
        },
        {
          id:8,
          img: require("@/assets"+"/expert/user8.jpg"),
          title: "梁专家",
          contend: "油藏地质专家"
        },
        {
          id:9,
          img: require("@/assets"+"/expert/user9.jpg"),
          title: "梁专家",
          contend: "储层预测专家"
        },
        {
          id:10,
          img: require("@/assets"+"/expert/user10.png"),
          title: "刘专家",
          contend: "开发地质专家"
        },
        {
          id:11,
          img: require("@/assets"+"/expert/user11.jpg"),
          title: "刘专家",
          contend: "地质力学专家"
        },
        {
          id:12,
          img: require("@/assets"+"/expert/user12.png"),
          title: "伍专家",
          contend: "油气藏工程专家"
        },
      ],
      images: [
        'https://via.placeholder.com/150?text=Image+1',
        'https://via.placeholder.com/150?text=Image+2',
        'https://via.placeholder.com/150?text=Image+3',
        'https://via.placeholder.com/150?text=Image+4',
        'https://via.placeholder.com/150?text=Image+5'
      ],
      backWidth:110,
      backGap:10
    };
  },
};
</script>

<style scoped>
.scroll-container {
  width: 100%; /* 容器宽度 */
  overflow: hidden; /* 隐藏溢出的内容 */
  position: relative;
}

.scroll-content {
  display: flex;
  animation: scroll-left 30s linear infinite; /* 启动滚动动画，20秒一轮 */
}

.back{
  background-color: transparent;
  margin-left: 10px;
  display: flex;
  //border: 1px solid #909399;
  border-radius: 9px;
  height: fit-content;
  width: fit-content;
}
.card{
  margin: auto;
  width: 50vw;
  height: 15vw;
  //padding: 5px;
  background-color: rgba(233, 236, 244, 0.3);
  display: flex;
  border: white 1px solid;
  border-radius: 9px;
}
.imageDesign{
  margin: auto;
  width: 13vw;
  height: 13vw;
}
.titleDesign{
  //width: 100%;
  width: 35vw;
  //height: 40%;
  //margin-top: 10px;
  color: white;
  display: flex;

  .title1{
    font-family: arial,sans-serif,Microsoft Yahei;
    font-weight: bolder;
    text-align: center;
    font-size: 15px;
  }
  .title2{
    font-family: arial,sans-serif,Microsoft Yahei;
    text-align: center;
    font-size: 4vw;
    display: flex;
    align-content: center;
    //height: 100%;
    margin: auto;
  }
}

.imageDesign img {
  width: 100%; /* 图片宽度 */
  height: 100%; /* 图片高度 */
  border-radius: 9px;
  object-fit: cover;
}

/* 定义滚动动画 */
@keyframes scroll-left {
  0% {
    transform: translateX(0); /* 从最左边开始 */
  }
  100% {
    transform: translateX(-1728px); /* N*backWidth+(N+1)*gap */
  }
}
</style>
