<template>
    <div v-if="model===false">
      <div ref="total" class="total">
        <kefu @uptop="uptop"></kefu>
        <div class="screen1">
          <video id="myVideo" ref="myVideo" autoplay height="100%"
                 loop muted playsinline style="object-fit: cover;position:absolute;top:0;left:0; z-index: -1"
                 width="100%">
            <source src="@/video/zuixin.mp4" type="video/mp4">
          </video>
          <div class="topback">
            <headtip @showDialog="showPartner" @showskip="scrollskip"></headtip>
          </div>

          <!--      logo  you气通GPT社区 合作伙伴  登录-->
<!--          <div v-if="scrollTrue===false" class="up" @click="uptop"></div>-->
          <!--       地质工程云平台GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
          <transition name="run" appear>
          <div class="layer2" style="z-index: 999999999999">
            <!-- <div class="title1">地质工程云平台
              <div class="title2">人员跨地域、跨专业实时联动、高效协同工作，工程设计方案、工程优化方案实时输出的人工智能云平台</div> -->
          <div>
              <div class="title1" >定制您的专属研究院
                <div class="titlenew1">开启油气开发增产提效之旅</div>
                <div class="titlenew"> 精细 &nbsp;/ &nbsp;   精准 &nbsp;/ &nbsp;    智能</div>
            </div>

            <!-- <div class="title31" style="margin-top: 6vh" @click="position6">
              <div class="hoverImg"><img src="@/assets/img02.png"/></div>
              <div class="hoverTitleup">全球在线专家支持服务</div>
                           <div class="hoverTitleup2"> 联通全球服务网点及后台技术人员，为用户提供算力、技术力量、油藏地质信息、项目信息及时互通的一体化共享云服务。
                           </div>
            </div> -->
            <!-- <div class="title31"  @click="position2">
              <div class="hoverImg"><img src="@/assets/img01.png"/></div>
              <div class="hoverTitleup">工程地质GPTs</div>
             <div class="hoverTitleup2">您可以在线免费使用基于AI大模型技术的应用模型，通过GPT辅助地质分析及工程优化，辅助工程师快速形成解决方案。
             </div>
            </div> -->

            <!-- <div class="title31" @click="position7">
              <div class="hoverImg"><img src="@/assets/img03.png"/></div>
              <div class="hoverTitleup">油气田开发系列技术服务</div>
             <div class="hoverTitleup2"> 全球油气开发客户面向未来可持续发展，安东提供全方位解决方案。
             </div>
            </div> -->
            <div class="titlenew-content" @click="position6">
              <div class="content-item">
              <div class="Img"><img src="@/assets/new/new3.png"/></div>
              <div class="Img-title">全球在线专家支持服务</div>
           </div>
           <div class="vertical-line"></div>
            <div class="titlenew-content" @click="position7">
              <div class="content-item1">
              <div class="Img"><img src="@/assets/new/new4.png"/></div>
              <div class="Img-title">精准工程技术服务</div>
            </div>
          </div>
            </div>
                             <!-- 合作咨询按钮 -->
                             <div class="hezuo">
            <div class="titlenew1" style="margin-top:3vw;color: white;   font-family: Roboto, sans-serif;
        text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.7); font-weight: bold;font-size: 1.2vw" @click="dialogVisible=true;">
                合作咨询
            </div>
            <div class="titlenew2" @click="about" style="margin-top:3vw;color: white;   font-family: Roboto, sans-serif;
        text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.7); font-weight: bold;font-size: 1.2vw">
              了解更多
              </div>
            </div>
          </div>
          </div>
          </transition>
          <!--      平台大图-->
          <div class="layer3">
            <!-- <el-popover
                placement="bottom"
                popper-class="down-popover"
                trigger="click"
                width="900"
            >
              <div class="searchBack" v-if="search.length>0">
                <li v-for="item in search" :key="item">
                  <div class="searchContent" @click="routerSkip(item.link)">
                    <div class="title1" v-html="item.title"></div>
                    <div class="title2" v-html="item.contend"></div>
                  </div>

                </li>

              </div>
              <div v-else class="searchBackno">
                暂未检索到您要查找的关于<span style="color: #eb7c31;margin: 0 0.5vw">“{{keyTitle}}”</span>的信息，换个关键词试试吧！
              </div>
              <div slot="reference" class="search"  >
                <el-input v-model="keyTitle" style="border: none" @input="getSearch" placeholder="请输入您要查找的GPT或内容关键词" ></el-input>
                <i class="el-icon-search"
                   style="margin-right:1.1vw;z-index: 999999999;font-size: 1.5vw;color:gray;font-weight: bold;height:7vh;display: flex;align-items: center"></i>
                  </div>
            </el-popover> -->
          </div>
        </div>
        <!--       油藏地质精细研究服务-->
        <!-- <div  class="screenFace">

          <div style="display: flex;margin:0 auto;">
            <div style="display: flex;margin:0 auto 0 auto;text-align: center;"> -->
              <!--              <div style="height:2.2vw;text-align: right"><img loading="lazy" src="@/assets/icon_first.png">-->
              <!--              </div>-->
              <!-- <div class="title" v-sliden-in="{duration:800}" v-zoom-in="{duration:800}">您面临什么问题？</div>
            </div>
          </div>

          <div class="pro2">
            <div  >
              <div class="grand1"  >
                <div class="back">
                  <div class="photo">
                    <img src="@/assets/face01.png">
                  </div>
                </div>
                <div class="back1">
                  <div class="title1">
                    如何利用有限的专家资源对全球项目高效提供支持?
                  </div>

                </div>
              </div>
            </div>
            <div > -->
              <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
              <!-- <div class="grand1"  >
                <div class="back">
                  <div class="photo">
                    <img src="@/assets/face02.png">

                  </div>
                </div>
                <div class="back1">
                  <div class="title1"> 如何快速响应现场问题<br>形成解决方案?</div>

                </div>
              </div>
            </div>
            <div > -->
              <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
              <!-- <div class="grand1"  >
                <div class="back">
                  <div class="photo">
                    <img src="@/assets/face03.png">
                  </div>
                </div>
                <div class="back1">
                  <div class="title1"> 如何管理油藏地质数据<br>为业务赋能?</div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <!-- 在线共享共创平台 -->
        <div class="screennews10">
             <!-- <zaixian></zaixian> -->
          <div class="icon-photo" >
            <img src="@/assets/new/icon.png" style="width:7%;height:7%;margin-left: 43vw;margin-bottom: 3vh;" class="icon">
          </div>
          <div class="title-container">
            <div class="title" v-sliden-in="{duration:800}" v-zoom-in="{duration:800}">&nbsp;在 线 共 享 共 创 平 台</div>
            <div class="light" style="text-align: center">
          全球首个开放的油气开发共享、共创平台，油气知识智能问答、油气人才共享、油气地质工程AI智能应用共创，让油气开发变简单。
          </div>
            </div>
        <div class="container-new10">
          <div class="contentphoto">
           <div class="title-top">油气知识GPT</div>
           <div class="title-bottom">这里为您提供油气行业最专<br>业的油气知识应用 </div>
           <div class="photonew10">
            <el-popover
                placement="bottom"
                popper-class="down-popover"
                trigger="click"
                width="900"
            >
              <div class="searchBack" v-if="search.length>0">
                <li v-for="item in search" :key="item">
                  <div class="searchContent" @click="routerSkip(item.link)">
                    <div class="title1" v-html="item.title"></div>
                    <div class="title2" v-html="item.contend"></div>
                  </div>

                </li>

              </div>
              <div v-else class="searchBackno">
                暂未检索到您要查找的关于<span style="color: #eb7c31;margin: 0 0.5vw">“{{keyTitle}}”</span>的信息，换个关键词试试吧！
              </div>
              <div slot="reference" class="search"  >
                <el-input v-model="keyTitle" style="border: none" @input="getSearch" placeholder="请输入您要查找的GPT或内容关键词" ></el-input>
                <i class="el-icon-search"
                   style="margin-right:1.1vw;z-index: 999999999;font-size: 1.5vw;color:gray;font-weight: bold;height:7vh;display: flex;align-items: center"></i>
                  </div>
            </el-popover>
           <img src="@/assets/new/立即体验1.png" class="imagenew"  @click="about" >
           </div>
      
          </div>
            <div class="consult10">
           <div class="title-top">油气地质工程AI模型共创</div>
           <div class="title-bottom">您可以体验油气行业各项人工智能应用，或者上传您的人工智能模型，<br>与行业专家共享、共创。 </div>
           <div class="zong">
           <div class="photonew11" @click="recog">
           <img src="@/assets/new/11.png" class="imagenew">
          </div>
          <div class="photonew12" @click="fibernew">
           <img src="@/assets/new/22.png" class="imagenew">
          </div>
          <div class="photonew13" @click="eur">
           <img src="@/assets/new/33.png" class="imagenew">
          </div>
            </div>
            </div>
          </div>
        <!-- <div ref="generalMove" class="screenConsult">

          <div style="display: flex;margin:0 auto;">
            <div style="display: flex;margin:0 auto 0 auto;text-align: center;">
              <div class="title">&nbsp;油气地质工程AI模型共创</div>
            </div>
          </div>
          <div class="light" style="text-align: center">
            您可以体验油气行业各项人工智能应用，或者上传您的人工智能模型，与行业专家共享、共创。
          </div>
          <div class="pro2">

            <div class="swiper-containerpc">
              <div class="swiper-wrapper">
                <div class="swiper-slide" @click="recog">
                  <div class="grand1">
                    <div class="back">
                      <div class="photo">
                        <img src="@/assets/groundHome1.png">
                        <div class="photohover"></div>

                      </div>
                    </div>
                    <div class="back1">
                      <div class="title1"> 智能油藏地质解释GPTS</div>
                      <div class="title2">
                        了解更多

                      </div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide" @click="fibernew">
                  <div class="grand1">
                    <div class="back">
                      <div class="photo">
                        <img src="@/assets/groundHome2.png">
                        <div class="photohover"></div>
                      </div>
                    </div>
                    <div class="back1">
                      <div class="title1"> 光纤漏点解释GPTS</div>
                      <div class="title2">
                        了解更多

                      </div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide" @click="eur">
                  <div class="grand1">
                    <div class="back">
                      <div class="photo">
                        <img src="@/assets/groundHome3.png">
                        <div class="photohover"></div>
                      </div>
                    </div>
                    <div class="back1">
                      <div class="title1">压裂方案参数设计优化GPTs</div>
                      <div class="title2">
                        了解更多

                      </div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide" @click="plug">
                  <div class="grand1">
                    <div class="back">
                      <div class="photo">
                        <img src="@/assets/groundHome4.png">
                        <div class="photohover"></div>
                      </div>
                    </div>
                    <div class="back1">
                      <div class="title1">智能砂堵预警GPTS</div>
                      <div class="title2">
                        了解更多

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-pagination"></div>
            </div>
          </div>
        </div> -->
      </div>

              <!-- 油气人才共享-->
              <div ref="consultMove" class="screenGpts">
          <div style="display: flex;margin:0 auto;">
            <div style="display: flex;margin:0 auto 0 auto;text-align: center;">
              <!--              <div style="height:2.2vw;text-align: right"><img loading="lazy" src="@/assets/icon_first.png">-->
              <!--              </div>-->
              <div class="title" v-sliden-in="{duration:800}" v-zoom-in="{duration:800}">&nbsp;全 球 共 享 支 持 平 台</div>
            </div>
          </div>
          <div class="light" style="text-align: center;width: 100%;">
             全行业专业人才社区，您可以随时随地与行业大拿进行交流，通过线上模式帮助您解决油气开发的一切难题。
            <br>
          </div>

          <div>
            <div style="width: 80vw;height:60vh;margin-top: 13.8vh;position: relative">
              <dv-flyline-chart :config="config" :dev="true" style="position:absolute;;width:80vw;height:60vh;"/>
              <dv-flyline-chart :config="config2" :dev="true" style="position:absolute;width:80vw;height:60vh;"/>
              <dv-flyline-chart :config="config3" :dev="true" style="position:absolute;width:80vw;height:60vh;"/>
              <dv-flyline-chart :config="config4" :dev="true" style="position:absolute;width:80vw;height:60vh;"/>
              <!--              <img src="../assets/second.png">-->
            </div>
          </div>
          <div class="searchBack" >
            <div class="searchItem" v-if="expert.length>0" style="display:flex" >
              <li v-for="(item, index)  in expert" :ref="`rollul-${index}`" :key="index"  :class="{anim:animate==true}">
                <div class="searchContent">
                  <div class="photo">
                    <img style="border-radius: 0;height:100%" :src="item.img"/>
                  </div>
                  <div class="content">
                    <!-- <div class="title1" v-html="item.title"></div> -->
                    <div class="title2" v-html="item.contend"></div>
                  </div>
                </div>
              </li>
            </div>
            <!-- <div class="loginUp" @click="about">关于我们</div> -->
          </div>
          <div class="login">
            <!-- <div @click="login1"  class="loginUp">了解更多</div> -->
            <div @click="about"  class="loginUp">了解更多</div>
            <div @click="login1" class="loginIn" style="text-align: center">登录</div>
          </div>
        </div>

        <!-- 新增模块 -->
         <!-- <div class="screennews2">
          <div class="title-container">
            <h2 v-sliden-in="{duration:800}" v-zoom-in="{duration:800}" class="title">
                 增 产 提 效 研 究 课 题
                 </h2>
          </div>
           <div class="container-content">
            <div class="content-photo1">
              <span class="photo-text">非常规油气藏精细地质研究</span>
              <span class="subphoto-text">多维度、全方位加深油气藏认识，聚焦潜力区，深挖高效开发技术要点，各个击破、步步为营，打破单项技术提效的四维壁垒，形成以<br>“点、线、面、体”相互联动、动态结合的油气藏开发整体解决方案，实现油气藏高效开发，为客户创造突破性贡献。</span>
              <img src="@/assets/new/切片1.png">
            </div>
             <div class="subphoto-content">
              <div class="content-photo2">
              <span class="photo-text">老井复产增产技术</span>
              <span class="subphoto-text">以提高采收率、提高采油速度、降低注入压力和提高注入量为核心目标，伴随产油井、产气井和注水井生命中后期，为客户提产增效做贡献。</span>
              <img src="@/assets/new/new1.png">
              </div>
              <div class="content-photo3">
              <span class="photo-text">深层页岩气高效改造</span>
              <span class="subphoto-text">深层页岩气构造、断裂和应力特征复杂，钻井、压裂施工难度大，如何有效控制套管变形及压窜等风险，增加有效缝网体积是页岩气高效开发的关键。</span>
                <img src="@/assets/new/new2.png">
              </div>
             </div>
           </div>
         </div> -->
             <!-- 新增模块测试 -->
              <div class="screennews11">
                <div class="icon-photo" >
            <img src="@/assets/new/icon1.png" style="width:7%;height:7%;margin-left: 42.5vw;margin-bottom: 3vh;" class="icon">
          </div>
                  <!-- 标题 -->
          <div class="title-container">
            <h2 v-sliden-in="{duration:800}" v-zoom-in="{duration:800}" class="title">
                 增 产 提 效 研 究 课 题
                 </h2>
          </div>
                <div class="container11">
                <div class="image-wrapper">
                 <!-- <img src="@/assets/new/切片1.png" alt="Image" class="image"> -->
                 <!-- <img src="@/assets/new/非常规油气藏.png" alt="Image" class="image"> -->
                 <!-- <img src="@/assets/new/非常规油气藏精细地质研究.png" alt="Image" class="image"> -->
                 <img src="@/assets/new/技术1.png" alt="Image" class="image">
                 <div class="title11">
                  <h3 style="color: rgba(85,85,85,1);margin-left: 2vw;" >非常规油气藏精细地质研究</h3>
                 </div>
                  </div>
            
                    <div class="content11">
               <p style="font-weight:300;color:#616161;margin-left:1.8vw;">多维度、全方位加深油气藏认识，聚焦潜力区，深挖高效开发技术要点，各个击破、步步为营，打破单项技术提效的四维壁垒，
                形成以“点、线、面、体”相互联动、动态结合的油气藏开发整体解决方案，实现油气藏高效开发，为客户创造突破性贡献。</p>
            <div class="discover-btn" @click="about" >了解更多 ></div>
               </div>
               </div>
                <div class="subcontainer">
                  <div class="container12">
                <div class="image-wrapper">
                 <!-- <img src="@/assets/new/new1.png" alt="Image" class="image"> -->
                 <!-- <img src="@/assets/new/老井复产增产技术.png" alt="Image" class="image"> -->
                 <img src="@/assets/new/技术2.png" alt="Image" class="image">
                 <div class="title11">
                  <h3 style="color:rgba(85,85,85,1);margin-left: 2vw;">老井复产增产技术</h3>
                 </div>
                  </div>
                    <div class="content11">
               <p style="font-weight:300;color:#616161;margin-left:1.8vw;">以提高采收率、提高采油速度、降低注入压力和提高注入量为核心目标，伴随产油井、产气井和注水井生命中后期，为客户提产增效做贡献。</p>
            <div class="discover-btn" @click="about" >了解更多 ></div>
               </div>
               </div>

               <div class="container13">
                <div class="image-wrapper">
                 <!-- <img src="@/assets/new/new2.png" alt="Image" class="image"> -->
                 <!-- <img src="@/assets/new/深层页岩气改造.png" alt="Image" class="image"> -->
                 <img  src="@/assets/new/技术3.png" alt="Image" class="image">
                          <div class="title11">
                  <h3 style="color:rgba(85,85,85,1);margin-left: 2vw;">深层页岩气高效改造</h3>
                 </div>
                  </div>
                    <div class="content11">
               <p style="font-weight:300;color:#616161;margin-left:1.9vw;">深层页岩气构造、断裂和应力特征复杂，钻井、压裂施工难度大，如何有效控制套管变形及压窜等风险，增加有效缝网体积是页岩气高效开发的关键。</p>
            <div class="discover-btn" @click="about" >了解更多 ></div>
               </div>
               </div>
                </div>
                </div>
                 <!--       油藏地质精细研究服务-->
        <div ref="professional" class="screenConsultnew" style="background-color: white">
          <div class="icon-photo" >
            <img src="@/assets/new/icon.png" style="width:7%;height:7%;margin-left:43vw;margin-bottom: 3vh;" class="icon">
          </div>
<div style="display: flex;margin:0 auto;">
  <div style="display: flex;margin:0 auto 0 auto;text-align: center;">
    <!--              <div style="height:2.2vw;text-align: right"><img loading="lazy" src="@/assets/icon_first.png">-->
    <!--              </div>-->
    <div class="title1" v-sliden-in="{duration:800}" v-zoom-in="{duration:800}">&nbsp;精 准 工 程 技 术 服 务</div>
  </div>
</div>
<!-- <div class="light1" style="text-align: center;width: 100%;">
  面向全局，聚焦单井落地精细研究，以地质工程相结合为特色研究，为客户提供全面地质研究评价、开发方案编制服务,<br><span @click="clickConsult('地质工程云平台')" style="color: red; cursor: pointer;">点击咨询</span>。
</div> -->

<!-- <div class="serviceStyle">
  <div :class="getClass(0)" @mouseenter="mouseSwiper(0)" @mouseleave="overSwiper(0)" >
    <div class="back">
      <div class="photo">
        <img src="@/assets/new1.png">
      </div>
    </div>
    <div class="title"  >
      油藏地质精细研究服务
    </div>
    <div class="span"></div>
    <div class="light"  >
      <div class="lightbehind" @click="comment">
        <img class="biaoti" src="@/assets/biaoti1.svg"/>
        <div class="limit">油气藏综合地质评价服务</div>
        <div class="iconStyle"> <i class="el-icon-arrow-right"   style="color: white;font-weight:bold"></i></div>
      </div>
      <div class="lightbehind" @click="exploit">
        <img class="biaoti" src="@/assets/biaoti2.svg"/>
        <div class="limit">油气藏整体开发方案设计服务</div>
        <div class="iconStyle"> <i class="el-icon-arrow-right"   style="color: white;font-weight:bold"></i></div>
      </div>
      <div class="lightbehind" @click="forecast"><img class="biaoti" src="@/assets/biaoti3.svg"/>
        <div class="limit">油气藏研究特色技术</div>
        <div class="iconStyle"> <i class="el-icon-arrow-right"   style="color: white;font-weight:bold"></i></div>
      </div>
    </div>
  </div>
  <div :class="getClass(1)" @mouseenter="mouseSwiper(1)" @mouseleave="overSwiper(1)" >
    <div class="back">
      <div class="photo">
        <img src="@/assets/new2.png">
      </div>
    </div>
    <div class="title"  >
      工程地质技术服务
    </div>
    <div class="span"></div>
    <div class="light"  >
      <div class="lightbehind" @click="intelligent">
        <img class="biaoti" src="@/assets/biaoti4.svg"/>
        <div class="limit">智能工程迭代 优化GPTs</div>
        <div class="iconStyle"> <i class="el-icon-arrow-right"   style="color: white;font-weight:bold"></i></div>
      </div>
      <div class="lightbehind" @click="connect">
        <img class="biaoti" src="@/assets/biaoti5.svg"/>
        <div class="limit">地质工程相结合 老井挖潜系列技术</div>
        <div class="iconStyle"> <i class="el-icon-arrow-right"   style="color: white;font-weight:bold"></i></div>
      </div>
    </div>
  </div>
  <div :class="getClass(2)" @mouseenter="mouseSwiper(2)" @mouseleave="overSwiper(2)" >
    <div class="back">
      <div class="photo">
        <img src="@/assets/new3.png">
      </div>
    </div>
    <div class="title"  >
      油气增产技术服务
    </div>
    <div class="span"></div>
    <div class="light"  >
      <div class="lightbehind" @click="single"><img class="biaoti" src="@/assets/biaoti6.svg"/>
        <div class="limit">一体化增产技术服务 及配套技术</div>
        <div class="iconStyle"> <i class="el-icon-arrow-right"   style="color: white;font-weight:bold"></i></div>
      </div>
      <div class="lightbehind" @click="integration"><img class="biaoti" src="@/assets/biaoti7.svg"/>
        <div class="limit">特色增产产品</div>
        <div class="iconStyle"> <i class="el-icon-arrow-right"   style="color: white;font-weight:bold"></i></div>
      </div> -->
      <!--              <div @click="risk" class="lightbehind"><img class="biaoti"  src="@/assets/biaoti8.png"/>增产措施效果监测评估技术               <div class="iconStyle"><i   style="color: white;font-weight:bold" class="el-icon-arrow-right"></i></div></div>-->
    <!-- </div>
  </div>
  <div :class="getClass(3)" @mouseenter="mouseSwiper(3)" @mouseleave="overSwiper(3)" >
    <div class="back">
      <div class="photo">
        <img src="@/assets/new4.png">
      </div>

    </div>
    <div class="title"  >
      油气田开发动态监测服务
    </div>
    <div class="span"></div>
    <div class="light"  >
      <div class="lightbehind" @click="distributevue"><img class="biaoti" src="@/assets/biaoti10.svg"/>
        <div class="limit">分布式光纤实时 监测技术</div>
        <div class="iconStyle"> <i class="el-icon-arrow-right"   style="color: white;font-weight:bold"></i></div>
      </div>
      <div class="lightbehind" @click="gasreservoir"><img class="biaoti" src="@/assets/biaoti11.svg"/>
        <div class="limit">油气藏测井技术</div>
        <div class="iconStyle"> <i class="el-icon-arrow-right"   style="color: white;font-weight:bold"></i></div>
      </div>
      <div class="lightbehind" @click="number"><img class="biaoti" src="@/assets/biaoti1.svg"/>
        <div class="limit">数字岩石岩屑</div>
        <div class="iconStyle"> <i class="el-icon-arrow-right"   style="color: white;font-weight:bold"></i></div>
      </div>
    </div>
  </div>
  <div :class="getClass(4)" @mouseenter="mouseSwiper(4)" @mouseleave="overSwiper(4)" >
    <div class="back">
      <div class="photo">
        <img src="@/assets/new5.png">
      </div>
    </div>
    <div class="title"  >
      增产整体解决方案
    </div>
    <div class="span"></div>
    <div class="light"  >
      <div class="lightbehind" @click="increase"><img class="biaoti" src="@/assets/biaoti9.svg"/>
        <div class="limit">增产整体解决方案</div>
        <div class="iconStyle"> <i class="el-icon-arrow-right"   style="color: white;font-weight:bold"></i></div>
      </div>
    </div>
  </div>

</div> -->

<div class="serviceStyle">
  <div class="text-content">
                <div class="main-description">油藏地质精细研究服务
          </div>
          <div class="sub-description">
            面向全局，聚焦单井落地精细研究，以地质工程相结合为特色研究，为客户提供全面地质研究评价、开发方案编制服务。
            <!-- <span  @click="clickConsult('地质工程云平台')" style="color: #15257b; cursor: pointer;">点击咨询</span>。 -->
          </div>
          <!-- Discover More 按钮 -->
          <div class="discover-more">
            <button class="discover-btn" @click="about">了解更多 ></button>
          </div>
        </div>

  <!-- <div :class="getClass(0)" @mouseenter="mouseSwiper(0)" @mouseleave="overSwiper(0)" >
    <div class="back">
      <div class="photo">
        <img src="@/assets/new1.png">
      </div>
    </div>
    <div class="title" @click="comment" >
      油气藏综合地质评价服务
    </div>
    <div class="span"></div>
    <div class="light"  >
    </div>
  </div>
  <div :class="getClass(1)" @mouseenter="mouseSwiper(1)" @mouseleave="overSwiper(1)" >
    <div class="back">
      <div class="photo">
        <img src="@/assets/new/groundHome2.png">
      </div>
    </div>
    <div class="title" @click="exploit" >
      开发方案设计服务
    </div>
    <div class="span"></div>
    <div class="light"  >
    </div>
  </div>
  <div :class="getClass(2)" @mouseenter="mouseSwiper(2)" @mouseleave="overSwiper(2)" >
    <div class="back">
      <div class="photo">
        <img src="@/assets/new/groundHome3.png">
      </div>
    </div>
    <div class="title"  @click="naturalfrac">
      天然裂缝预测技术服务
    </div>
    <div class="span"></div>
    <div class="light"></div>
  </div>
  <div :class="getClass(3)" @mouseenter="mouseSwiper(3)" @mouseleave="overSwiper(3)" >
    <div class="back">
      <div class="photo">
        <img src="@/assets/new/groundHome4.png">
      </div>

    </div>
    <div class="title" @click="geomechanics" >
      地质力学研究技术服务
    </div>
    <div class="span"></div>
    <div class="light"  >
    </div>
  </div>
  <div :class="getClass(4)" @mouseenter="mouseSwiper(4)" @mouseleave="overSwiper(4)" >
    <div class="back">
      <div class="photo">
        <img src="@/assets/new/groundHome5.png">
      </div>
    </div>
    <div class="title" @click="thinreservoir" >
     三维地质力学建模服务
    </div>
    <div class="span"></div>
    <div class="light"  >
    </div>
  </div> -->
  <div class="pro2">
<div class="swiper-containerpc">
  <div class="swiper-wrapper">
    <div class="swiper-slide">
      <div class="grand1">
        <div class="back">
          <div class="photo">
            <img src="@/assets/new1.png">
            <div class="photohover"></div>

          </div>
        </div>
        <div class="back1">
          <div class="title6" @click="comment" > 油气藏综合地质评价服务</div>

        </div>
      </div>
    </div>
    <div class="swiper-slide" >
      <div class="grand1">
        <div class="back">
          <div class="photo">
            <img src="@/assets/new/groundHome2.png">
            <div class="photohover"></div>
          </div>
        </div>
        <div class="back1">
          <div class="title3" @click="exploit"> 开发方案设计服务</div>
        </div>
      </div>
    </div>
    <div class="swiper-slide" >
      <div class="grand1">
        <div class="back">
          <div class="photo">
            <img src="@/assets/new/groundHome3.png">
            <div class="photohover"></div>
          </div>
        </div>
        <div class="back1">
          <div class="title4"  @click="naturalfrac"> 天然裂缝预测技术服务</div>
        </div>
      </div>
    </div>
    <div class="swiper-slide" >
      <div class="grand1">
        <div class="back">
          <div class="photo">
            <img src="@/assets/new/groundHome4.png">
            <div class="photohover"></div>
          </div>
        </div>
        <div class="back1">
          <div class="title5" @click="geomechanics">地质力学研究技术服务</div>
        </div>
      </div>
    </div>
    <div class="swiper-slide" >
      <div class="grand1">
        <div class="back">
          <div class="photo">
            <img src="@/assets/new/groundHome5.png">
            <div class="photohover"></div>
          </div>
        </div>
        <div class="back1">
          <div class="title5" @click="thinreservoir">三维地质力学建模服务</div>
        </div>
      </div>
    </div>
  </div>
  <div class="swiper-pagination"></div>
</div>
</div>
</div>

</div>
            <!-- who are we模块 -->
        <!-- <div class="screennews">
              <div class="title-container">
                <h2 v-sliden-in="{duration:800}" v-zoom-in="{duration:800}" class="title">
                 为 什 么 选 择 我 们
                 </h2>
              </div>
              <div class="content-container">
               <div class="text-content">
                <div class="main-description">以油藏地质和工程技术为核心，地质工程相结合，增产特色，配合数字化、可视化手段，为油田客户提供增产提效研究优化解决方案服务，提高工程开发效率，帮助客户精准命中目标油气藏，使客户油藏资产价值最大化。
          </div>
          <div class="sub-description">
            具有学科齐全、专业配套的地质工程一体化技术团队150余人，
            依托云平台及区域技术中心，构建全球化地质工程技术支持网络，
            服务全球主要油气产区。
          </div>
          <div class="discover-more">
            <button class="discover-btn" @click="about">关于我们 ></button>
          </div>
        </div>
        <div class="video-container">
          <video id="myVideo" ref="myVideo" width="99%" height="100%" controls>
            <source src="@/video/Newyanjiu.mp4" type="video/mp4">
          </video>
        </div>
        </div>
      </div> -->

                <!-- 平台介绍模块 -->
<!-- <div class="screennews1">
  主标题部分
  <div class="platform-intro">
    <h2 class="main-title">
      <div v-sliden-in="{duration:800}" v-zoom-in="{duration:800}">
        我们是富有张力的<span class="highlight-red">开放共创型平台组织</span>，
      </div>
    </h2>
    <p class="sub-title" v-sliden-in="{duration:800}" v-zoom-in="{duration:800}">
      自主研发与开放式合作兼容并济，提供油气田开发全方位解决方案。
    </p>
  </div>

  三大研究院介绍
  <div class="research-institutes">
    专业人才研究院
    <div class="institute-item">
      <h3 class="institute-title">油气行业专业人才共创的研究院</h3>
      <ul class="institute-list">
        <li>油气藏研究专业人才</li>
        <li>属地专家人才</li>
        <li>地质工程相结合一体化技术人才</li>
      </ul>
    </div>

    客户合作研究
    <div class="institute-item">
      <h3 class="institute-title">油气行业客户开放合作研究</h3>
      <ul class="institute-list">
        <li>与客户紧密结合</li>
        <li>联合研究</li>
        <li>敏捷开发</li>
      </ul>
    </div>

    民营企业研究院
    <div class="institute-item">
      <h3 class="institute-title">油气行业中、小民营企业共创的研究院</h3>
      <ul class="institute-list">
        <li>与有特色油气藏研究能力的民营企业合作</li>
        <li>与有特色油气藏服务能力的民营企业合作</li>
        <li>同时一体化服务于广大民营业主方</li>
      </ul>
    </div>
  </div>
</div> -->
      <!-- 新建与客户交流画面模块 -->
       <!-- <div class="screennews5">
        <div class="content-containernews5">
          <div class="content-itemnew1"> -->
            <!--  左侧图片-->
              <!-- <div class="photo-1">
                <img src="@/assets/new/1.jpg">
              </div> -->
          <!-- 右侧文字 -->
            <!-- <div class="title-content">
              <div style="width: 100%;padding:2vh 5vw">
                <div class="main-title">专业人才共创平台
              </div>
              <div class="sub-text">
               <div class="item1111">油气藏研究专业人才</div>
               <div class="item1111">属地专家人才</div>
               <div class="item1111">地质工程相结合的技术人才</div>
                </div>
              </div>

            </div>
          </div>
          <div class="content-itemnew2"> -->
            <!--  左侧文字-->
            <!-- <div class="title-content">
              <div style="width: 100%;padding:2vh 5vw">
                <div class="main-title">行业开放合作平台</div>
              <div class="sub-text">
                <div class="item1111">与客户紧密结合</div>
                <div class="item1111">联合研究</div>
                <div class="item1111">敏捷开发</div>
                </div>
              </div>
            </div> -->
            <!-- 右侧图片 -->
              <!-- <div class="photo-1"> -->
                <!-- <img src="@/assets/new/kehu.png"> -->
                <!-- <img src="@/assets/new/2.jpg">
              </div>
          </div>
          <div class="content-itemnew3" style="margin-bottom: 6vh;"> -->
            <!-- 左侧图片 -->
            <!-- <div class="photo-1">
                <img src="@/assets/new/3.jpg">
              </div> -->
              <!-- 右侧文字 -->
              <!-- <div class="title-content">
                <div style="width: 100%;padding:2vh 5vw">
                  <div class="main-title">民营企业共创平台</div>
              <div class="sub-text">
                <div class="item1111">油气藏研究型企业共创</div>
                <div class="item1111">油气藏服务型企业共创</div>
                <div class="item1111">一体化工程技术服务</div>
                </div>
                </div>
            </div>
          </div> -->
          <!-- 四大板块介绍 -->
           <!-- <div style="background-color: #f7f3f3;width:100%">
          <div class="content-itemnew4" >
                      <div class="item4">
              <h3 class="item4-title">3000+</h3>
              <p class="item4-text">成功案例</p>
              </div>
              <div class="item4">
              <h3 class="item4-title">300+</h3>
              <p class="item4-text">专家团队</p>
              </div>
              <div class="item4">
              <h3 class="item4-title">35+</h3>
              <p class="item4-text">服务的国家和地区</p>
              </div>
              <div class="item4">
              <h3 class="item4-title">3000+</h3>
              <p class="item4-text">精准工程技术</p>
              </div>
            </div>
          <div class="Know-more" style="float: right;">
            <div class="know-btn" @click="about" style="color:#15257b ;font-weight: bold;">了解更多</div>
          </div>
          </div>
          </div>
       </div> -->
        <!-- <div class="screenGet">

<div v-sliden-in="{duration:800}" v-zoom-in="{duration:800}" class="title">&nbsp;随心所欲建立您的研究团队
</div>

<div class="pro2">
  <div>
    <div class="grand1">
      <div class="back">
        <div class="photo">
          <img src="@/assets/get1.png">
        </div>
      </div>
      <div class="back1">
        <div class="title1">
         全流程资深专家团队
        </div>
        <div class="title2">
          具有学科齐全、专业配套的技术团队，能够根据您的业务需求精准匹配油藏地质、油藏开发研究团队。


        </div>
      </div>
    </div>
  </div>
  <div>
           <img src="@a/Q.jpeg" alt="图片2" />
    <div class="grand1">
      <div class="back">
        <div class="photo">
          <img src="@/assets/get2.png">

        </div>
      </div>
      <div class="back1">
        <div class="title1">     全球布局的专家团队

        </div>
        <div class="title2">    在全球主要油气产区均建立了技术中心，无论您在任何国家或地区，都能得到资深专家的支持。

        </div>

      </div>
    </div>
  </div>
  <div>
           <img src="@a/Q.jpeg" alt="图片2" />
    <div class="grand1">
      <div class="back">
        <div class="photo">
          <img src="@/assets/get3.png">
        </div>
      </div>
      <div class="back1">
        <div class="title1">  地质工程相结合的专家团队

        </div>
        <div class="title2">      团队涵盖储层反演地质力学、地质建模、数值模拟、生产动态、钻井、储层改造、试油等专业。
        </div>
      </div>
    </div>
  </div>
</div>
<div class="buttongroup">
<buuton class="about-us"  @click="about">关于我们</buuton>
</div>
</div> -->
        <!-- 地质工程相结合的技术服务 -->
         <div class="screennews6">
            <!-- <div class="title-container">
              <div class="title" v-sliden-in="{duration:800}" v-zoom-in="{duration:800}">&nbsp;地质工程相结合的技术服务</div>
            </div>
            <div class="light" style="text-align: center;width: 100%;">
              您可以获得量身定制的工程地质技术服务，工程服务与地质相结合，充分应用地质研究成果指导工程方案实施，<br><span  @click="clickConsult('地质工程云平台')" style="color: red; cursor: pointer;">点击咨询</span>。
            <br>
          </div> -->
            <div class="photocontent">
            <div class="photocontent1" >
              <span @click="intelligent" class="newtitle3">智能工程迭代优化技术</span>
              <img src="@/assets/new/智能工程迭代优化技术.png">
            </div>
            <div class="photocontent1" style="margin-left: -12vw;">
              <span  @click="connect" class="newtitle4">地质工程相结合老井挖潜系列技术</span>
              <img src="@/assets/new/地质工程相结合老井.jpg">
            </div>
            <div class="text-content" style="margin-top:6vh">
                <div class="main-description">地质工程相结合的技术服务
          </div>
          <div class="sub-description">
            您可以获得量身定制的工程地质技术服务，工程服务与地质相结合，充分应用地质研究成果指导工程方案实施。
            <!-- <span  @click="clickConsult('地质工程云平台')" style="color: #15257b; cursor: pointer;">点击咨询</span>。 -->
          </div>
          <!-- Discover More 按钮 -->
          <div class="discover-more">
            <button class="discover-btn" @click="about">了解更多 ></button>
          </div>
        </div>
          </div>
         </div>
         <!-- 油气藏增产改造技术服务 -->
         <div class="screennews7">
          <!-- <div class="title-container">
              <div class="title" v-sliden-in="{duration:800}" v-zoom-in="{duration:800}">&nbsp;油气藏增产改造技术服务</div>
            </div>
            <div class="light" style="text-align: center;width: 100%;">
           您可以获得以地质工程一体化方案设计，特色产品技术集成为核心的一体化增产服务及增产风险服务，<span @click="clickConsult('地质工程云平台')" style="color: red; cursor: pointer;">点击咨询</span>。
            </div> -->
          <div class="photocontent">
            <div class="text-content">
                <div class="main-description">油气藏增产改造技术服务
          </div>
          <div class="sub-description">
            您可以获得以地质工程一体化方案设计，特色产品技术集成为核心的一体化增产服务及增产风险服务。
            <!-- <span  @click="clickConsult('地质工程云平台')" style="color: #15257b; cursor: pointer;">点击咨询</span>。 -->
          </div>
          <!-- Discover More 按钮 -->
          <div class="discover-more">
            <button class="discover-btn" @click="about">了解更多 ></button>
          </div>
        </div>
            <div class="photocontent1" style="margin-right: -11vw;">
              <span @click="single" class="newtitle4">一体化增产技术服务及配套技术</span>
              <img src="@/assets/new/一体化增产服务.png">
            </div>
            <div class="photocontent1">
              <span @click="integration"  class="newtitle3">特色增产产品</span>
              <img src="@/assets/new/特色增产产品.png">
            </div>
            </div>
         </div>
         <!-- 油气田开发动态监测服务 -->
         <div class="screennews8">
          <!-- <div class="title-container">
            <div class="title" v-sliden-in="{duration:800}" v-zoom-in="{duration:800}">&nbsp;油气田开发动态监测服务</div>
            <div class="light" style="text-align: center;width: 100%;">
           您可以获得以光纤为特色，覆盖钻井，完井，生产油气开发全过程油藏地质评价及开发工程监测服务，便于油气开发过程中的精细评价及精准工程控制，<span @click="clickConsult('地质工程云平台')" style="color: red; cursor: pointer;">点击咨询</span>。
          </div>
            </div> -->
            <div class="containerzong">
          <div class="photocontent" style="margin: 1vh">
              <div class="photocontent1">
              <!-- <span @click="distributevue" class="newtitle3" >分布式光纤实时监测技术</span> -->
              <!-- <img src="@/assets/new/分布式光纤.png"> -->
              <img @click="distributevue" src="@/assets/new/Group3.png">
            </div>
            <div class="photocontent1">
              <span @click="gasreservoir" class="newtitle3">油气藏测井技术</span>
              <img src="@/assets/new/image.png" >
            </div>
            <div class="photocontent1">
              <!-- <span @click="number" class="newtitle3">数字岩石岩屑</span> -->
              <img @click="number"  src="@/assets/new/Group4.png">
            </div>
            <!-- <div class="pro2">
<div class="swiper-containerpc">
  <div class="swiper-wrapper">
    <div class="swiper-slide">
      <div class="grand1">
        <div class="back">
          <div class="photo">
            <img src="@/assets/new/分布式光纤.png">
            <div class="photohover"></div>

          </div>
        </div>
        <div class="back1">
          <div class="title1" @click="distributevue" > 分布式光纤实时监测技术</div>

        </div>
      </div>
    </div>
    <div class="swiper-slide" >
      <div class="grand1">
        <div class="back">
          <div class="photo">
            <img src="@/assets/new/油气藏测井技术.png">
            <div class="photohover"></div>
          </div>
        </div>
        <div class="back1">
          <div class="title1" @click="gasreservoir"> 油气藏测井技术</div>
        </div>
      </div>
    </div>
    <div class="swiper-slide" >
      <div class="grand1">
        <div class="back">
          <div class="photo">
            <img src="@/assets/new/数字岩石岩屑.png">
            <div class="photohover"></div>
          </div>
        </div>
        <div class="back1">
          <div class="title1" @click="number">数字岩石岩屑</div>
        </div>
      </div>
    </div>
    <div class="swiper-slide" >
      <div class="grand1">
        <div class="back">
          <div class="photo">
            <img src="@/assets/groundHome4.png">
            <div class="photohover"></div>
          </div>
        </div>
        <div class="back1">
          <div class="title1">智能砂堵预警GPTS</div>
        </div>
      </div>
    </div>
  </div>
  <div class="swiper-pagination"></div>
</div>
</div>  -->
            <div class="text-content" style="margin-top: 1vh;">
    <div class="main-description">油气田开发动态监测服务</div>
    <div class="sub-description">
      您可以获得以光纤为特色，覆盖钻井，完井，生产油气开发全过程油藏地质评价及开发工程监测服务，便于油气开发过程中的精细评价及精准工程控制。
    </div>
    <div class="discover-more">
      <button class="discover-btn" @click="about">了解更多 ></button>
    </div>
  </div>
            </div>
            </div>
         </div>
         <!-- 油气田一体化增产总包服务 -->
         <div class="screennews9">
          <!-- <div class="title-container">
            <div class="title" v-sliden-in="{duration:800}" v-zoom-in="{duration:800}">&nbsp;油气田一体化增产总包服务</div>
            <div class="light" style="text-align: center;width: 100%;">您可以获得以提高产量为目的，将精准工程技术落实到方案研究以及工程实施全过程，依靠成熟的项目管理经验，先进适用的技术组合，获得最高的投资回报，<span @click="clickConsult('地质工程云平台')" style="color: red; cursor: pointer;">点击咨询</span>。</div>
            </div> -->
          <div class="photocontent"><div class="text-content" style="margin-top: 6vh;margin-left: 3vw;">
                <div class="main-description">油气田一体化增产总包服务
          </div>
          <div class="sub-description">
            您可以获得以提高产量为目的，将精准工程技术落实到方案研究以及工程实施全过程，依靠成熟的项目管理经验，先进适用的技术组合，获得最高的投资回报。
            <!-- <span  @click="clickConsult('地质工程云平台')" style="color: #15257b; cursor: pointer;">点击咨询</span>。 -->
          </div>
          <!-- Discover More 按钮 -->
          <div class="discover-more">
            <button class="discover-btn" @click="about">了解更多 ></button>
          </div>
        </div>
            <div class="img">
              <!-- <img style="margin-left: 6vw;" src="@/assets/new/油气田一体化.png" @click="clickConsult('地质工程云平台')"> -->
              <img style="margin-left: 10vw;" src="@/assets/new/油气田一体化.png" @click="clickConsult('地质工程云平台')">
            </div>
         </div>
        </div>
        <!-- <div  class="screenSupport" >
          <div>
            <div class="title" v-sliden-in="{duration:800}" v-zoom-in="{duration:800}">在线数据库
            </div>
            <div class="light" >
              在线数据库，形成对在线<br>专家支持系统高效运行的支撑
            </div>
            <div class="light2" >

              <div>海量数据管理</div>
              <div>专业知识 Q&A</div>
              <div>生产数据 Q&A</div>
              <div>报告撰写助手</div>
              <div>每日精准 Q&A</div>
              <div>事故事件分析</div>
            </div>
          </div>
          <div class="photo">
            <img src="@/assets/support.png">
          </div>

        </div> -->

            <!-- who are we模块 -->
            <div class="screennews">
              <div class="icon-photo" >
            <img src="@/assets/new/icon1.png" style="width:7%;height:7%;margin-left: 42.5vw;margin-bottom: 3vh;" class="icon">
          </div>
              <!-- Who are we 标题 -->
              <div class="title-container">
                <h2  style="color: white;" v-sliden-in="{duration:800}" v-zoom-in="{duration:800}" class="title">
                关 于 我 们
                 </h2>
              </div>
                        <!-- 四大板块介绍 -->
           <!-- <div style="background-color: #f7f3f3;width:100%;margin-bottom: 40px;">
          <div class="content-itemnew4" >
                      <div class="item4">
              <h3 class="item4-title">10000+</h3>
              <p class="item4-text">成功案例数据库</p>
              </div>
              <div class="item4">
              <h3 class="item4-title">5000+</h3>
              <p class="item4-text">资深专家</p>
              </div>
              <div class="item4">
              <h3 class="item4-title">55+</h3>
              <p class="item4-text">国家和地区</p>
              </div>
              <div class="item4">
              <h3 class="item4-title">300+</h3>
              <p class="item4-text">特色技术</p>
              </div>
            </div>
          </div> -->
          <div class="zong-content">
            <div class="sub-content1" >
              <div class="photo-111">
           <img src="@/assets/new/Vector.png" style="width:32%;height:32%;margin-left: 4vw;margin-top: 4vh;">
           </div>
           <div class="photo-222" >
           <!-- <img src="@/assets/new/图标3.png"> -->
           <!-- <h3 class="item4-title" style="margin-top: 21vh;width:100%;height: 100%;"><span class="large-number">10</span>K
           <sup class="plus">+</sup></h3> -->
           <h3 class="item4-title" style="margin-top: 21vh;width:100%;height: 100%;"><span class="large-number">100</span></h3>
           <p class="item4-text" style="width: 100%;height:100%;margin-bottom: 1.6vh">我们拥有100个油藏数据库</p>
           </div>
            </div>
            <div class="sub-content2">
              <div class="photo-111">
           <img src="@/assets/new/Capa_1.png" style="width:32%;height:32%;margin-left: 5vw;margin-top: 4vh;">
           </div>
           <div class="photo-555" >
           <!-- <img src="@/assets/new/图标4.png"> -->
           <!-- <h3 class="item4-title" style="margin-left: 4vw;width: 100%;height:100%;"><span class="large-number">5</span>K<sup class="plus">+</sup></h3> -->
           <h3 class="item4-title" style="margin-top: 21vh;width: 100%;height:100%;margin-left: 3vw;"><span class="large-number">5000</span></h3>
           <p class="item4-text" style="width: 100%;height:100%;margin-bottom: 1vh;" >拥有5000资深专家</p>
           </div>
            </div>
            <div>
            <div class="sub-content3">
              <div class="photo-333">
                <!-- <img src="@/assets/new/图标5.png"> -->
                <!-- <h3 class="item4-title" style="width: 100%;height:100%;"><span class="large-number">55</span><sup class="plus">+</sup></h3> -->
                <h3 class="item4-title" style="width: 100%;height:100%;margin-left: 3vw;"><span class="large-number">55</span></h3>
                <p class="item4-text" style="width: 100%;height:100%;margin-left: -1.5vw;">我们服务55+国家和地区</p>
              </div>
              </div>
              <div class="sub-content4">
              <div class="photo-444">
                <!-- <img src="@/assets/new/图标6.png"> -->
                <!-- <h3 class="item4-title" style="width: 100%;height:100%;"><span class="large-number">300</span><sup class="plus">+</sup></h3> -->
                <h3 class="item4-title" style="width: 100%;height:100%;margin-left: 3vw;"><span class="large-number">300</span></h3>
                <p class="item4-text" style="width: 100%;height:100%;margin-left: -1.5vw;">拥有300多项特色技术</p>
               </div>
            </div>
            </div>
          </div>
              <!-- 主要内容区 -->
              <div class="content-container" style="margin-top: 4vh;">
              <!-- 左侧文字说明 -->
               <div class="text-content" style="margin-top: 10vh;" >
                <div class="text-title">研究院简介</div>
                <div class="main-description">以油藏地质和工程技术为核心，地质工程相结合，增产特色，配合数字化、可视化手段，为油田客户提供增产提效研究优化解决方案服务，提高工程开发效率。
          </div>
          <!-- <div class="sub-description">
            具有学科齐全、专业配套的地质工程一体化技术团队150余人，依托云平台及区域技术中心，构建全球化地质工程技术支持网络，服务全球主要油气产区。
          </div> -->
          <!-- Discover More 按钮 -->
          <!-- <div class="discover-more">
            <button class="discover-btn" @click="about">了解更多</button>
          </div> -->
        </div>
        <!-- 右侧视频 -->
        <div class="video-container">
          <video id="myVideo" ref="myVideo" width="99%" height="90%" controls>
            <source src="@/video/Newyanjiu.mp4" type="video/mp4">
          </video>
        </div>
        </div>
      </div>

       <!-- 新建与客户交流画面模块 -->
       <div class="screennews5">
        <div class="content-containernews5">
          <!-- <div class="content-itemnew1">
              <div class="photo-1">
                <img src="@/assets/new/1.jpg">
              </div>
            <div class="title-content">
              <div style="width: 100%;padding:2vh 5vw">
                <div class="main-title">专业人才共创平台
              </div>
              <div class="sub-text">
               <div class="item1111">油气藏研究专业人才</div>
               <div class="item1111">属地专家人才</div>
               <div class="item1111">地质工程相结合的技术人才</div>
                </div>
              </div>

            </div>
          </div>
          <div class="content-itemnew2">
            <div class="title-content">
              <div style="width: 100%;padding:2vh 5vw">
                <div class="main-title">行业开放合作平台</div>
              <div class="sub-text">
                <div class="item1111">与客户紧密结合</div>
                <div class="item1111">联合研究</div>
                <div class="item1111">敏捷开发</div>
                </div>
              </div>
            </div>
              <div class="photo-1">
                <img src="@/assets/new/2.jpg">
              </div>
          </div> -->
          <div class="content-itemnew3" style="margin-bottom: 6vh;">
            <!-- 左侧图片 -->
            <div class="photo-1">
                <img src="@/assets/new/3.jpg">
              </div>
              <!-- 右侧文字 -->
              <div class="title-content" style="margin-right:-1vw"  >
                <div class="section" >
                  <div class="icon" style="width:60px;height: 60px;margin-right: 8vw;">
                    <img src="@/assets/new/图标1.png">
                  </div>
                  <div class="main-title" >专业人才共创平台</div>
              <div class="sub-text" style="margin-right:2vw">
                <div class="item1111">油气藏研究型专业人才</div>
                <div class="item1111">属地专家人才</div>
                <div class="item1111">地质工程结合人才</div>
                </div>
                </div>
                <div class="vertical-line" ></div>
                <div class="section" >
                  <div class="icon" style="width:60px;height: 60px;margin-right: 6vw;">
                    <img src="@/assets/new/Vector (1).png">
                  </div>
                <div class="main-title" style="margin-right: 1vw;">企业共创平台</div>
              <div class="sub-text" style="margin-right:0.5vw">
                <div class="item1111">油气藏研究型企业共创</div>
                <div class="item1111">油气藏服务型企业共创</div>
                <div class="item1111">一体化工程技术服务</div>
                </div>
              </div>
            </div>
          </div>
          <!-- 四大板块介绍 -->
           <!-- <div style="background-color: #f7f3f3;width:100%">
          <div class="content-itemnew4" >
                      <div class="item4">
              <h3 class="item4-title">3000+</h3>
              <p class="item4-text">成功案例</p>
              </div>
              <div class="item4">
              <h3 class="item4-title">300+</h3>
              <p class="item4-text">专家团队</p>
              </div>
              <div class="item4">
              <h3 class="item4-title">35+</h3>
              <p class="item4-text">服务的国家和地区</p>
              </div>
              <div class="item4">
              <h3 class="item4-title">3000+</h3>
              <p class="item4-text">精准工程技术</p>
              </div>
            </div>
          <div class="Know-more" style="float: right;">
            <div class="know-btn" @click="about" style="color:#15257b ;font-weight: bold;">了解更多</div>
          </div>
          </div> -->
          </div>
       </div>

        <!--        &lt;!&ndash;       油藏地质精细研究服务&ndash;&gt;-->
        <div  class="screenMap" >
          <!-- <div class="containernewMap" style="display: flex;width:100%">
            <div class="titlenewMap1" >
              <div style="margin-bottom: -7vh;"><img style="width: 6%;"  src="@/assets/Vector (3).png"></div>
              <div class="high2" style=" color: white;font-size: 1.2vw;font-weight: bold;width: fit-content; margin:0 auto;line-height: 4vh;text-align: center;" >在线咨询</div>
                <div class="high1" style="color:white;font-size: 1.2vw;line-height: 5vh; text-align: center;">24-7 全天候在线</div>
            </div>
            <div class="titlenewMap2" style="margin-left: 70%;">
              <div style="margin-bottom: -7vh;"><img style="width: 6%;"  src="@/assets/Vector (4).png"></div>
              <div class="high2" style=" color: white;font-size: 1.2vw;font-weight: bold;width: fit-content; margin:0 auto;line-height: 4vh;text-align: center;">邮箱</div>
               <div class="high1" style="color:white;font-size: 1.2vw;line-height: 5vh; text-align: center;">24/7可用</div>
            </div>
          </div> -->
          <!-- <div class="icon-photo" >
            <img src="@/assets/new/icon.png" style="width:7%;height:7%;margin-left: 37.5vw;margin-bottom: 3vh;" class="icon">
          </div>
          <div class="title" v-sliden-in="{duration:800}" v-zoom-in="{duration:800}">联 系 我 们
          </div>
          <div class="light" >
            如有任何疑问，请使用以下信息发送电子邮件、致电或访问我们的办公室。
          </div> -->
          <div >
            <!-- <div style="width: 40vw"> -->
              <div style="margin-left: 5vw;">
              <div class="top" style="border: none;margin-top: 0" @click="upkefu2">
                <div style="margin-bottom: -7vh;"><img style="width: 3%;margin-left: 20vw;"  src="@/assets/Vector (3).png"></div>
                <div class="high2"  >在线咨询</div>
               <!-- <div class="high2"><img src="@/assets/arrow.png"></div> -->
                <div class="high1">24-7 全天候在线</div>
              </div>
            </div>
              <!-- <div class="top">
                <div class="high2" @click="dialogVisible=true;title=''">产品及合作咨询</div>
                <div class="high1">+86-13880250028</div>
               <div class="high1">24/7可用</div>
              </div> -->
              <div style="margin-left: 5vw;">
            <div class="top">
                <div style="margin-bottom: -7vh;"><img style="width: 3%;margin-top: 3vh;margin-left: 20vw;"  src="@/assets/Vector (4).png"></div>
                <div class="high1">邮箱</div>
                <div class="high2">OGDRI@antonoil.com</div>
               <!-- <div class="high1">24/7可用</div> -->
              </div>
              <div class="top">
                <!-- <div class="high1">办公室地址</div>
                <div class="high2">NARGESS 8, New Cairo 1, <br>Cairo Governorate
                </div>
               <div class="high1">地图所示</div> -->
              <!-- </div> -->
            </div>
          </div>
            <!-- <div class="photo"  style="height: 55vh;width: 50vw;">
              <img style="border-radius: 9px" src="@/assets/kailuo.png"/>
            </div> -->
          </div>


        </div>
        <!--底部-->
        <div class="screen7">
          <bottomTip @showComment="showComment" @showDialogBottom="scroll" @showNet="showNet"
                     @showProduct="showProduct"></bottomTip>
        </div>
        <netFeedback :dialogVisible="dialogNet"
                     @hideDialog="hideNet"></netFeedback>
        <productFeedback
            :dialogVisible="dialogProduct"
            @hideDialog="hideProduct"
        ></productFeedback>

        <commentFeedback
            :dialogVisible="dialogComment"
            @hideDialog="hideComment"
        ></commentFeedback>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
        <partnerTip :dialogVisible="dialogconsult" @hideDialog="hide"></partnerTip>
      </div>
    </div>
    <div v-else>
      <div id="totalModel" ref="totalModel" class="totalModel">
        <div class="screen1">
          <!--          <video id="myVideo" ref="myVideo" width="100%" height="340px" style="object-fit: cover;position:absolute;top:0;left:0; z-index: 0" autoplay loop muted playsinline>-->
          <!--            <source src="@/video/bannerv3.mp4" type="video/mp4">-->
          <!--          </video>-->
          <headOther @showDialog="showPartner" @showDialogTop="scroll" @button1="dialogVisible=true;" @button2="about" @position6="position6" @position7="position7"></headOther>
          <!--      logo  you气通GPT社区 合作伙伴  登录-->
        </div>
<!--        <div v-if="scrollTrue===false" class="up" @click="uptop"></div>-->
        <div  class="screenFace">
          <FreeModel @recog="recog" @fibernew="fibernew" @eur="eur"></FreeModel>
<!--          <div style="display: flex;margin:0 auto;">-->
<!--            <div style="display: flex;margin:0 auto 0 auto;text-align: center;">-->
<!--              &lt;!&ndash;              <div style="height:2.2vw;text-align: right"><img loading="lazy" src="@/assets/icon_first.png">&ndash;&gt;-->
<!--              &lt;!&ndash;              </div>&ndash;&gt;-->
<!--              <div class="title"  >您面临什么问题？</div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="pro2">-->
<!--            <div  >-->
<!--              <div class="grand1"  >-->
<!--                <div class="back">-->
<!--                  <div class="photo">-->
<!--                    <img src="@/assets/face01.png">-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="back1">-->
<!--                  <div class="title1">-->
<!--                    如何利用有限的专家资源对全球项目高效提供支持?-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div >-->
<!--              &lt;!&ndash;        <img src="@a/Q.jpeg" alt="图片2" />&ndash;&gt;-->
<!--              <div class="grand1"  >-->
<!--                <div class="back">-->
<!--                  <div class="photo">-->
<!--                    <img src="@/assets/face02.png">-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="back1">-->
<!--                  <div class="title1"> 如何快速响应现场问题形成解决方案?</div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div >-->
<!--              &lt;!&ndash;        <img src="@a/Q.jpeg" alt="图片2" />&ndash;&gt;-->
<!--              <div class="grand1"  >-->
<!--                <div class="back">-->
<!--                  <div class="photo">-->
<!--                    <img src="@/assets/face03.png">-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="back1">-->
<!--                  <div class="title1"> 如何管理油藏地质数据为业务赋能?</div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
        </div>
        <div ref="map" class="map" style="background-color: #123456">
          <Map @about="about" @login1="login1"></Map>
        </div>
<!--        <div ref="map" class="map" style="">-->

<!--          <div style="background-color: #bed6f5;">-->
<!--            <div class="title" style="color: #193871">-->
<!--              全球在线专家支持服务-->
<!--            </div>-->
<!--            <div class="light" style="color: #193871">-->
<!--              地质、工程专家团队24/7全天候在线，在线解决现场地质工程问题，快速形成解决方案。-->
<!--            </div>-->
<!--            <div class="light"-->
<!--                 style="color: #193871;margin: 5px auto 15px auto;width: fit-content;font-size: 14px;border-bottom: 1px solid #193871"-->
<!--                 @click="login">-->
<!--              点击登录-->
<!--            </div>-->
<!--          </div>-->
<!--          <div style="position: relative;height: 170px;width: 350px">-->
<!--            <dv-flyline-chart :config="configmodel" :dev="true" style="position:absolute;;height: 150px;width: 350px;"/>-->
<!--            <dv-flyline-chart :config="configmodel2" :dev="true" style="position:absolute;height: 150px;width: 350px;"/>-->
<!--            <dv-flyline-chart :config="configmodel3" :dev="true" style="position:absolute;height: 150px;width: 350px"/>-->
<!--            <dv-flyline-chart :config="configmodel4" :dev="true" style="position:absolute;height: 150px;width: 350px;"/>-->
<!--            &lt;!&ndash;              <img src="../assets/second.png">&ndash;&gt;-->
<!--          </div>-->
<!--&lt;!&ndash;          <img src="../assets/second.png" width="100%">&ndash;&gt;-->
<!--          <personcard></personcard>-->
<!--        </div>-->
        <div class="serviceFeedback" style="background-color: #d8d8d8;">
          <ServiceFeedback @showNet="showNet"></ServiceFeedback>
        </div>
        <div class="research" ref="professional">
          <Research></Research>
        </div>
        <div class="service" style="background-color: #f3f3f3;padding: 0 10px">
          <OurService @navigate="navigatTo"></OurService>
        </div>
        <div class="about" style="padding: 0 10px">
          <AboutUs></AboutUs>
        </div>
        <div class="bottom" style="background-color: #123456;padding: 0 10px">
          <Bottom></Bottom>
        </div>
<!--        <div  class="screenGet">-->
<!--          <div style="display: flex;margin:0 auto;">-->
<!--            <div style="display: flex;margin:0 auto 0 auto;text-align: center;">-->
<!--              &lt;!&ndash;              <div style="height:2.2vw;text-align: right"><img loading="lazy" src="@/assets/icon_first.png">&ndash;&gt;-->
<!--              &lt;!&ndash;              </div>&ndash;&gt;-->
<!--              <div class="title"  >您可以得到什么？</div>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="pro2">-->
<!--            <div>-->
<!--              <div class="grand1">-->
<!--                <div class="back">-->
<!--                  <div class="photo">-->
<!--                    <img src="@/assets/get1.png">-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="back1">-->
<!--                  <div class="title1">-->
<!--                    数据管理和信息洞察-->
<!--                  </div>-->
<!--                  <div class="title2">-->
<!--                    高效的数据索引和检索机制，实现海量数据的智能管理和获取准确的数据支持。-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div>-->
<!--              &lt;!&ndash;        <img src="@a/Q.jpeg" alt="图片2" />&ndash;&gt;-->
<!--              <div class="grand1">-->
<!--                <div class="back">-->
<!--                  <div class="photo">-->
<!--                    <img src="@/assets/get2.png">-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="back1">-->
<!--                  <div class="title1">     实时云服务-->
<!--                  </div>-->
<!--                  <div class="title2">    将人工智能应用与在线专家团队集成，提供各种解决方案的实时输出。-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div>-->
<!--              &lt;!&ndash;        <img src="@a/Q.jpeg" alt="图片2" />&ndash;&gt;-->
<!--              <div class="grand1">-->
<!--                <div class="back">-->
<!--                  <div class="photo">-->
<!--                    <img src="@/assets/get3.png">-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="back1">-->
<!--                  <div class="title1">  人工智能辅助决策-->
<!--                  </div>-->
<!--                  <div class="title2">      通过数据挖掘和模式识别，系统可以提供高精度的决策支持协助。-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div  class="screenSupport" >-->
<!--          <div>-->
<!--            <div class="title"  > 在线数据库</div>-->
<!--            <div class="light" >-->
<!--              在线数据库，形成对在线专家支持系统高效运行的支撑-->
<!--            </div>-->
<!--            <div class="light2" >-->
<!--              <div>海量数据管理</div>-->
<!--              <div>专业知识 Q&A</div>-->
<!--              <div>生产数据 Q&A</div>-->
<!--              <div>报告撰写助手</div>-->
<!--              <div>每日精准 Q&A</div>-->
<!--              <div>事故事件分析</div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="photo">-->
<!--            <img src="@/assets/support.png">-->
<!--          </div>-->

<!--        </div>-->
<!--        <div ref="GEGPTs" class="GEGPTs" style="">-->
<!--          <div class="title" style="color: #193871">-->
<!--            工程地质GPTs-->
<!--          </div>-->
<!--          <div class="light" style="color: #193871">-->
<!--            您可以在线免费使用基于AI大模型技术的应用模型，通过GPT辅助地质分析及工程优化，辅助工程师快速形成解决方案。-->

<!--          </div>-->
<!--          <div class="pro2">-->
<!--            <div class="swiper-container0">-->
<!--              <div class="swiper-wrapper">-->
<!--                <div class="swiper-slide" @click="recog">-->
<!--                  &lt;!&ndash;        <img src="@a/Q.jpeg" alt="图片2" />&ndash;&gt;-->
<!--                  <div class="grand1">-->
<!--                    <div class="back">-->
<!--                      <div class="photo">-->
<!--                        <img src="@/assets/groundHome1.png">-->
<!--                        <div class="photolist">01</div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="back1">-->
<!--                      <div class="title1"> 智能油藏地质解释GPTS</div>-->
<!--                      <div class="title2">-->
<!--                        了解更多-->

<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="swiper-slide" @click="fibernew">-->
<!--                  &lt;!&ndash;        <img src="@a/Q.jpeg" alt="图片2" />&ndash;&gt;-->
<!--                  <div class="grand1">-->
<!--                    <div class="back">-->
<!--                      <div class="photo">-->
<!--                        <img src="@/assets/groundHome2.png">-->
<!--                        <div class="photolist">02</div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="back1">-->
<!--                      <div class="title1"> 光纤漏点解释GPTS</div>-->
<!--                      <div class="title2">-->
<!--                        了解更多-->

<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="swiper-slide" @click="eur">-->
<!--                  <div class="grand1">-->
<!--                    <div class="back">-->
<!--                      <div class="photo">-->
<!--                        <img src="@/assets/groundHome3.png">-->
<!--                        <div class="photolist">03</div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="back1">-->
<!--                      <div class="title1">压裂方案参数设计优化GPTs</div>-->
<!--                      <div class="title2">-->
<!--                        了解更多-->

<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="swiper-slide" @click="plug">-->
<!--                  &lt;!&ndash;        <img src="@a/Q.jpeg" alt="图片2" />&ndash;&gt;-->
<!--                  <div class="grand1">-->
<!--                    <div class="back">-->
<!--                      <div class="photo">-->
<!--                        <img src="@/assets/groundHome4.png">-->
<!--                        <div class="photolist">04</div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="back1">-->
<!--                      <div class="title1"> 智能砂堵预警GPTS</div>-->
<!--                      <div class="title2">-->
<!--                        了解更多-->

<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="swiper-pagination"></div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div ref="seriesService" class="seriesService" style="padding: 0 0 10px 0">-->
<!--          <div class="title" style="color: #193871">-->
<!--            油气田开发系列技术服务-->
<!--          </div>-->
<!--          <div class="light" style="color: #193871">-->
<!--            您可以获得定制化的专属解决方案与油气田开发特色技术，帮助您降本增效。-->

<!--          </div>-->

<!--          <div class="swiper-container1">-->
<!--            <div class="swiper-wrapper">-->
<!--              <div class="swiper-slide">-->
<!--                <div class="box1">-->
<!--                  <div class="back">-->
<!--                    <div class="photo">-->
<!--                      <img src="@/assets/new1.png">-->
<!--                    </div>-->
<!--                    <div class="photolist">-->
<!--                      <img src="@/assets/newicon1.png">-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="title" style="color: #193871">-->
<!--                    油藏地质精细研究服务-->
<!--                  </div>-->
<!--                  <div class="light" style="color: #193871">-->
<!--                    <div class="lightbehind" @click="comment"><img class="biaoti" src="@/assets/biaoti1.png"/>油气藏综合地质评价服务-->
<!--                      <div class="iconStyle">了解更多<i class="el-icon-arrow-right"   style="color: blue;font-weight:bold"></i></div>-->
<!--                    </div>-->
<!--                    <div class="lightbehind" @click="exploit"><img class="biaoti" src="@/assets/biaoti2.png"/>油气藏整体开发方案设计服务-->
<!--                      <div class="iconStyle">了解更多<i class="el-icon-arrow-right"   style="color: blue;font-weight:bold"></i></div>-->
<!--                    </div>-->
<!--                    <div class="lightbehind" @click="forecast"><img class="biaoti" src="@/assets/biaoti3.png"/>油气藏研究特色技术-->
<!--                      <div class="iconStyle">了解更多<i class="el-icon-arrow-right"   style="color: blue;font-weight:bold"></i></div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="swiper-slide">-->
<!--                &lt;!&ndash;        <img src="@a/Q.jpeg" alt="图片2" />&ndash;&gt;-->
<!--                <div class="box1">-->
<!--                  <div class="back">-->
<!--                    <div class="photo">-->
<!--                      <img src="@/assets/new2.png">-->
<!--                    </div>-->
<!--                    <div class="photolist">-->
<!--                      <img src="@/assets/newicon2.png">-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="title" style="color: #193871">-->
<!--                    工程地质技术服务-->
<!--                  </div>-->
<!--                  <div class="light" style="color: #193871">-->
<!--                    <div class="lightbehind" @click="intelligent"><img class="biaoti" src="@/assets/biaoti4.png"/>智能工程迭代优化GPTs-->
<!--                      <div class="iconStyle">了解更多<i class="el-icon-arrow-right"   style="color: white;font-weight:bold"></i></div>-->
<!--                    </div>-->
<!--                    <div class="lightbehind" @click="connect"><img class="biaoti" src="@/assets/biaoti5.png"/>地质工程相结合老井挖潜系列技术-->
<!--                      <div class="iconStyle">了解更多<i class="el-icon-arrow-right"   style="color: white;font-weight:bold"></i></div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="swiper-slide">-->
<!--                &lt;!&ndash;        <img src="@a/Q.jpeg" alt="图片2" />&ndash;&gt;-->
<!--                <div class="box1">-->
<!--                  <div class="back">-->
<!--                    <div class="photo">-->
<!--                      <img src="@/assets/new3.png">-->
<!--                    </div>-->
<!--                    <div class="photolist">-->
<!--                      <img src="@/assets/newicon3.png">-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="title" style="color: #193871">-->
<!--                    油气增产技术服务-->
<!--                  </div>-->
<!--                  <div class="light" style="color: #193871">-->
<!--                    <div class="lightbehind" @click="single"><img class="biaoti" src="@/assets/biaoti6.png"/>一体化增产技术服务及配套技术-->
<!--                      <div class="iconStyle">了解更多<i class="el-icon-arrow-right"   style="color: blue;font-weight:bold"></i></div>-->
<!--                    </div>-->
<!--                    <div class="lightbehind" @click="integration"><img class="biaoti" src="@/assets/biaoti7.png"/>特色增产产品-->
<!--                      <div class="iconStyle">了解更多<i class="el-icon-arrow-right"   style="color: blue;font-weight:bold"></i></div>-->
<!--                    </div>-->
<!--                    &lt;!&ndash;              <div @click="risk" class="lightbehind"><img class="biaoti"  src="@/assets/biaoti8.png"/>增产措施效果监测评估技术               <div class="iconStyle"><i   style="color: white;font-weight:bold" class="el-icon-arrow-right"></i></div></div>&ndash;&gt;-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="swiper-slide">-->
<!--                <div class="box1">-->
<!--                  <div class="back">-->
<!--                    <div class="photo">-->
<!--                      <img src="@/assets/new4.png">-->
<!--                    </div>-->
<!--                    <div class="photolist">-->
<!--                      <img src="@/assets/newicon4.png">-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="title" style="color: #193871">-->
<!--                    油气田开发动态监测服务-->
<!--                  </div>-->
<!--                  <div class="light" style="color: #193871">-->
<!--                    <div class="lightbehind" @click="distributevue"><img class="biaoti" src="@/assets/biaoti10.png"/>分布式光纤实时监测技术-->
<!--                      <div class="iconStyle">了解更多<i class="el-icon-arrow-right"   style="color: blue;font-weight:bold"></i></div>-->
<!--                    </div>-->
<!--                    <div class="lightbehind" @click="gasreservoir"><img class="biaoti" src="@/assets/biaoti11.png"/>油气藏测井技术-->
<!--                      <div class="iconStyle">了解更多<i class="el-icon-arrow-right"   style="color: blue;font-weight:bold"></i></div>-->
<!--                    </div>-->
<!--                    <div class="lightbehind" @click="number"><img class="biaoti" src="@/assets/biaoti12.png"/>数字岩石岩屑-->
<!--                      <div class="iconStyle">了解更多<i class="el-icon-arrow-right"   style="color: blue;font-weight:bold"></i></div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="swiper-slide">-->
<!--                <div class="box1">-->
<!--                  <div class="back">-->
<!--                    <div class="photo">-->
<!--                      <img src="@/assets/new5.png">-->
<!--                    </div>-->
<!--                    <div class="photolist">-->
<!--                      <img src="@/assets/newicon5.png">-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="title" style="color: #193871">-->
<!--                    增产整体解决方案-->
<!--                  </div>-->
<!--                  <div class="light" style="color: #193871">-->
<!--                    <div class="lightbehind" @click="increase"><img class="biaoti" src="@/assets/biaoti9.png"/>增产整体解决方案-->
<!--                      <div class="iconStyle">了解更多<i class="el-icon-arrow-right"   style="color: blue;font-weight:bold"></i></div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="swiper-pagination"></div>-->
<!--          </div>-->

<!--        </div>-->

<!--        <div  class="screenMap" >-->
<!--          <div class="title" v-sliden-in="{duration:800}" v-zoom-in="{duration:800}">联  系  我  们-->
<!--          </div>-->
<!--          <div class="light" >-->
<!--            如有任何疑问，请使用以下信息发送电子邮件、致电或访问我们的办公室。-->
<!--          </div>-->
<!--          <div>-->
<!--            <div >-->
<!--              <div class="top" style="border: none;margin-top: 0" @click="upkefu2">-->
<!--                <div class="high2"  >在线客服</div>-->
<!--&lt;!&ndash;                <div class="high2"><img src="@/assets/arrow.png"></div>&ndash;&gt;-->
<!--                <div class="high1">8:00-17:00可用</div>-->
<!--              </div>-->
<!--              <div class="top">-->
<!--                <div class="high2" @click="dialogVisible=true;title=''">产品及合作咨询</div>-->
<!--                <div class="high1">+86-13880250028</div>-->
<!--&lt;!&ndash;                <div class="high1">24/7可用</div>&ndash;&gt;-->
<!--              </div>-->
<!--              <div class="top">-->
<!--                <div class="high1">邮箱</div>-->
<!--                <div class="high2">OGDRI@antonoil.com</div>-->
<!--&lt;!&ndash;                <div class="high1">24/7可用</div>&ndash;&gt;-->
<!--              </div>-->
<!--              <div class="top">-->
<!--                <div class="high1">办公室地址</div>-->
<!--                <div class="high2">NARGESS 8, New Cairo 1, <br>Cairo Governorate-->
<!--                </div>-->
<!--                &lt;!&ndash;                <div class="high1">地图所示</div>&ndash;&gt;-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="photo"  style="height: 250px;width: 100%;margin-top: 25px">-->
<!--              <img style="border-radius: 9px" src="@/assets/kailuo.png"/>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <!--底部-->
<!--        <div ref="bottom" class="screen7">-->
<!--          <bottomTip @showDialogBottom="scroll"></bottomTip>-->
<!--        </div>-->
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
        <partnerTip :dialogVisible="dialogconsult" @hideDialog="hide"></partnerTip>
      </div>
    </div>
</template>
<script>
import PartnerTip from "@/components/partnerTip.vue";
import headtip from "@/views/headTip.vue";
import headOther from "@/views/headOther.vue";
import bottomTip from "@/views/bottomTip.vue";
import consultTip from "@/components/consultTip.vue";
import {distribute} from "gsap/gsap-core";
import commentFeedback from "@/components/commentFeedback.vue";
import productFeedback from "@/components/productFeedback.vue";
import netFeedback from "@/components/netFeedback.vue";
import Swiper from 'swiper'
import 'swiper/dist/css/swiper.css'
import {vSlidenIn} from '@/utils/vSlidenIn.js';
import newMap from '@/components/map.vue'
import {vZoomIn} from '@/utils/vZoomIn.js';
// import Personcard from "@/components/personcard.vue";
import FreeModel from "@/views/component/FreeModel.vue"
import Map from "@/views/component/Map.vue"
import ServiceFeedback from "@/views/component/ServiceFeedback.vue"
import Research from "@/views/component/research.vue"
import OurService from "@/views/component/OurService.vue";
import AboutUs from "@/views/component/AboutUs.vue"
import Bottom from "@/views/component/Bottom.vue";
// import Kefu from "@/components/kefu.vue";
// import zaixian from "@/views/componentPc/zaixian.vue";
export default {
  name: "Home",
  components: {
    Bottom,
    OurService,
    // Kefu,
    // Personcard,
    bottomTip,
    // eslint-disable-next-line vue/no-unused-components
    newMap,
    PartnerTip,
    consultTip,
    headtip,
    headOther,
    commentFeedback,
    productFeedback,
    netFeedback,
    FreeModel,
    Map,
    ServiceFeedback,
    Research,
    AboutUs,
    // zaixian,

  },
  directives: {
    slidenIn: vSlidenIn,
    zoomIn: vZoomIn
  },
  data() {
    return {
      animate: true,
      expert: [
        {
          id:1,
          img: require("@/assets/expert/user1.jpg"),
          title: "陈专家",
          contend: "构造解释专家"
        },
        {
          id:2,
          img: require("@/assets/expert/user2.png"),
          title: "陈专家",
          contend: "增产技术专家"
        },
        {
          id:3,
          img: require("@/assets/expert/user3.jpg"),
          title: "程专家",
          contend: "开发地质专家"
        },
        {
          id:4,
          img: require("@/assets/expert/user4.png"),
          title: "陈专家",
          contend: "构造解释专家"
        },
        {
          id:5,
          img: require("@/assets/expert/user5.jpg"),
          title: "田专家",
          contend: "整体解决方案专家"
        },
        {
          id:6,
          img: require("@/assets/expert/user6.png"),
          title: "梁专家",
          contend: "测井解释专家"
        },

        {
          id:7,
          img: require("@/assets/expert/user7.jpg"),
          title: "王专家",
          contend: "油气藏工程专家"
        },
        {
          id:8,
          img: require("@/assets/expert/user8.jpg"),
          title: "梁专家",
          contend: "油藏地质专家"
        },
        {
          id:9,
          img: require("@/assets/expert/user9.jpg"),
          title: "梁专家",
          contend: "储层预测专家"
        },
        {
          id:10,
          img: require("@/assets/expert/user10.png"),
          title: "刘专家",
          contend: "开发地质专家"
        },
        {
          id:11,
          img: require("@/assets/expert/user11.jpg"),
          title: "刘专家",
          contend: "地质力学专家"
        },
        {
          id:12,
          img: require("@/assets/expert/user12.png"),
          title: "伍专家",
          contend: "油气藏工程专家"
        },
      ],
      config: {
        centerPoint: [0.56, 0.52],
        points: [
          {
            position: [0.28, 0.68],
            text: '',
          },
          {
            position: [0.25, 0.17],
            text: ' ',
          },
          {
            position: [0.56, 0.74],
            text: '',
          },

        ],
        k: -0.5,
        lineWidth:2,
        flylineColor: ' #2167d9',
        flylineRadius:350,
        duration:[20,30],
        orbitColor: '',
        bgImgUrl: require("../assets/second.png"),
        centerPointImg: {
          url: require("../assets/center.png"),
        },curvature:5,
        pointsImg: {
          width: 80,
          height: 80,
          url: require("../assets/assets.png"),
        },
        text:{
          offset:[0,35],
          fontSize:14,
          color:'#13237a'
        }
      },
      config2: {
        centerPoint: [0.61, 0.30],
        points: [
          {
            position: [0.63, 0.18],
            text: '',
          },
          {
            position: [0.65, 0.40],
            text: '',
          },
        ],
        k: -0.5,
        flylineColor: '#2167d9',
        flylineRadius:450,
        lineWidth:2,
        orbitColor: '',
        curvature:3,
        bgImgUrl: '',
        centerPointImg: {
          url: require("../assets/center.png"),
        },
        pointsImg: {
          width: 80,
          height: 80,
          url: require("../assets/assets.png"),
        },
        text:{
          offset:[0,35],
          fontSize:14,
          color:'#13237a'
        }
      },
      config3: {
        centerPoint: [0.81, 0.36],
        points: [


     {
            position: [0.90, 0.23],
            text: '',
          }, {
            position: [0.93, 0.79],
            text: '',
          },

        ],
        k: -1,
        flylineColor: '#2167d9 ',
        flylineRadius:550,
        lineWidth:2,
        orbitColor: '',
        curvature:3.5,
        bgImgUrl: '',
        centerPointImg: {
          url: require("../assets/center.png"),
        },
        pointsImg: {
          width: 80,
          height: 80,
          url: require("../assets/assets.png"),
        },
        text:{
          offset:[0,35],
          fontSize:14,
          color:'#13237a'
        }
      },
      config4: {
        centerPoint: [0.61, 0.34],
        points: [
          {
            position: [0.56, 0.52],
          },
          {
            position:  [0.81, 0.36],
          },
        ],
        k: -0.5,
        flylineColor: ' #2167d9',
        flylineRadius:350,
        lineWidth:2,
        orbitColor: '',
        curvature:4,
        bgImgUrl: '',
        centerPointImg: {
          url: '',
        },
        pointsImg: {
          width: 80,
          height: 80,
          url: '',
        },
        text:{
          offset:[0,35],
          fontSize:14,
          color:'#13237a'
        }
      },
      configmodel: {
        halo:{
          show:true,
          radius:50,
        },
        centerPoint: [0.59, 0.48],
        points: [
          {
            position: [0.28, 0.68],
            text: ' ',
          },
          {
            position: [0.25, 0.17],
            text: '  ',
          },
          {
            position: [0.56, 0.74],
            text: ' ',
          },

        ],
        k: -0.5,
        lineWidth:1,
        flylineColor: ' ',
        flylineRadius:50,
        duration:[20,30],
        orbitColor: '',
        bgImgUrl: require("../assets/second.png"),
        centerPointImg: {
         width: 30,
          height: 30,
          url: require("../assets/center.png"),
        },curvature:5,
        pointsImg: {
          width: 40,
          height: 40,
          url: require("../assets/assets.png"),
        },
        text:{
          offset:[0,18],
          fontSize:10,
          color:'#13237a'
        }
      },
      configmodel2: {
        centerPoint: [0.60, 0.28],
        points: [
          {
            position: [0.63, 0.13],
            text: ' ',
          },
          {
            position: [0.70, 0.43],
            text: ' ',
          },
        ],
        halo:{
          radius:50
        },
        k: -0.5,
        flylineColor: ' ',
        flylineRadius:50,
        lineWidth:1,
        orbitColor: '',
        curvature:3,
        bgImgUrl: '',
        centerPointImg: {
         width: 30,
          height: 30,
          url: require("../assets/center.png"),
        },
        pointsImg: {
          width: 40,
          height: 40,
          url: require("../assets/assets.png"),
        },
        text:{
          offset:[0,18],
          fontSize:10,
          color:'#13237a'
        }
      },
      configmodel3: {
        centerPoint: [0.81, 0.36],
        halo:{
          radius:50
        },
        points: [


          {
            position: [0.90, 0.23],
            text: ' ',
          }, {
            position: [0.93, 0.79],
            text: ' ',
          },

        ],
        k: -1,
        flylineColor: '  ',
        flylineRadius:50,
        lineWidth:1,
        orbitColor: '',
        curvature:3.5,
        bgImgUrl: '',
        centerPointImg: {
         width: 30,
          height: 30,
          url: require("../assets/center.png"),
        },
        pointsImg: {
          width: 40,
          height: 40,
          url: require("../assets/assets.png"),
        },
        text:{
          offset:[0,18],
          fontSize:10,
          color:'#13237a'
        }
      },
      configmodel4: {
        halo:{
          show:false
        },
        centerPoint: [0.61, 0.34],
        points: [
          {
            position: [0.56, 0.52],
          },
          {
            position:  [0.81, 0.36],
          },
        ],
        k: -0.5,
        flylineColor: ' ',
        flylineRadius:50,
        lineWidth:1,
        orbitColor: '',
        curvature:4,
        bgImgUrl: '',
        centerPointImg: {
          url: '',
        },
        pointsImg: {
         width: 30,
          height: 30,
          url: '',
        },
        text:{
          offset:[0,35],
          fontSize:10,
          color:'#13237a'
        }
      },
      scrollPosition: null,
      model: false,
      swiperSlid:[0,0,0,0,0],
      keyTitle:'',
      search: [
        {
          link: "gptTranslate",
          title: "工艺类GPTs",
          contend: "工艺类GPTs，不仅有助于提高开发效果和采收率，同时降低开发过程中的风险损失，确保油气田的安全稳定开发"
        },
        {
          link: "gptTranslate",
          title: "解释类GPTs",
          contend: "解释类GPTs，基于油气开发目标解释，不仅有助于工程作业人员实时掌握油气藏地质地质条件以及工程作业情况，还为开发过程中的决策和调整提供了有力支持安全稳定开发"
        },
        {
          link: "gptTranslate",
          title: "工艺类GPTs",
          contend: "工艺类GPTs，不仅有助于提高开发效果和采收率，同时降低开发过程中的风险损失，确保油气田的安全稳定开发"
        },
        {
          link: "gptTranslate",
          title: "解释类GPTs",
          contend: "解释类GPTs，基于油气开发目标解释，不仅有助于工程作业人员实时掌握油气藏地质地质条件以及工程作业情况，还为开发过程中的决策和调整提供了有力支持安全稳定开发"
        },
        {
          link: "gptTranslate",
          title: "工艺类GPTs",
          contend: "工艺类GPTs，不仅有助于提高开发效果和采收率，同时降低开发过程中的风险损失，确保油气田的安全稳定开发"
        },
        {
          link: "gptTranslate",
          title: "解释类GPTs",
          contend: "解释类GPTs，基于油气开发目标解释，不仅有助于工程作业人员实时掌握油气藏地质地质条件以及工程作业情况，还为开发过程中的决策和调整提供了有力支持安全稳定开发"
        },
      ],
      swiper: null,
      avator: require("../assets/user.jpg"),
      userName: "",
      userId: null,
      dialogVisible: false,
      dialogComment: false,
      dialogProduct: false,
      dialogNet: false,
      title: "数智油田",
      dialogconsult: false,
      product: 1,
      infogpt: 1,
      currentIndex: 0,
      itemsPerPage: 4,
      letters: '0123456789ABCDEF',
      carouselItems: [
        {
          img: require("../assets/logo1.png"),
          title: "智能钻井",
          text:
              "着陆段地质层分类预警：基于六个维度数据特征，建立最小AI模型，准确率提升20%。" +
              "<br>" +
              "水平段上下穿层预警：水平段穿层实时判断、上下切角实时预警建立最小模型。",
        },
        {
          img: require("../assets/logo2.png"),
          title: "智能压裂",
          text:
              "影响压裂效果的指标权重分析：影响压裂效果（EUR）的多维地质工程参数的指标权重分析。\n" +
              "<br>" +
              "压裂产量EUR预测：基于给定的多维地质工程参数，对EUR的AI预测模型准确率78%。",
        },
        {
          img: require("../assets/logo3.png"),
          title: "智能采油气",
          text: "磕头机的生产冲次辅助决策：构建智能采油气机器人提供辅助决策。",
        },
        {
          img: require("../assets/logo4.png"),
          title: "地层监测",
          text: "基于光纤的井筒完整性监测：基于光纤DTS温度数据建立对井筒故障监测及位置预警的最小模型。",
        },
        {
          img: require("../assets/logo5.png"),
          title: "设备设施监测",
          text: "设备故障检测：基于开源的滚动轴承数据建立模型，实时判断轴承故障类型、故障大小、故障位置",
        },
      ],
      groupedItems: [],
      scrollY: 0,
      scrollHeight: 0,
      scrollTrue: true,
    };
  },

  computed: {
    // unconventional() {
    //   return unconventional
    // }
    // network() {
    //   return network
    // },
    // technology() {
    //   return technology
    // },

  },
  created() {
    this.getSearch()
    localStorage.setItem("home", 1)
    // 通用GPT
    if (sessionStorage.getItem("ground") === '1') {
      this.$nextTick(() => {
        this.position2();
      });
    }
    // 油气资讯
    else if (sessionStorage.getItem("ground") === '2') {
      this.$nextTick(() => {
        this.position7();
      });
    }
    // 底部
    else if (sessionStorage.getItem("ground") === '3') {
      this.$nextTick(() => {
        this.position6();
      });
    }
    // 油气专业GPTs
    else if (sessionStorage.getItem("ground") === '4') {
      this.$nextTick(() => {
        this.position3();
      });
    } else if (sessionStorage.getItem("ground") === '6') {
      this.$nextTick(() => {
        this.posHandle('generalAuto')
      });
    } else if (sessionStorage.getItem("ground") === '0') {
      this.$nextTick(() => {
        this.uptop();
      });
    }


  },

  beforeDestroy() {
    if (this.swiper) {
      this.swiper.destroy();
      this.swiper = null;
    }
    this.$refs.total.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    setTimeout( this.mapExpert(),1000)
    this.updateModel();
    this.initSwiper();
    const videoEl = this.$refs.myVideo;
    videoEl.play();
    this.$refs.total.addEventListener('scroll', this.handleScroll);

    // 通用GPT
    if (sessionStorage.getItem("skip") === '1') {
      this.$nextTick(() => {
        this.skipp1();
      });
    }
    // 油气资讯
    else if (sessionStorage.getItem("skip") === '2') {
      this.$nextTick(() => {
        this.skipp2();
      });
    }
    // 底部
    else if (sessionStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
        // 油气专业GPTs
        // else if (sessionStorage.getItem("skip") === '4') {
        //   this.$nextTick(() => {
        //     this.skipp4();
        //   });
        // }
    // 油气专业GPTs
    else if (sessionStorage.getItem("skip") === '5') {
      this.$nextTick(() => {
        this.skipp5();
      });
    }
    // 油气开发作业GPT应用服务
    else if (sessionStorage.getItem("skip") === '6') {
      this.$nextTick(() => {
        this.skipp6();
      });
    } else if (sessionStorage.getItem("skip") === '7') {
      this.$nextTick(() => {
        this.skipp7();
      });
    } else if (sessionStorage.getItem("skip") === '8') {
      this.$nextTick(() => {
        this.skipp8();
      });
    } else if (sessionStorage.getItem("skip") === '10') {
      this.$nextTick(() => {
        this.skipp10();
      });
    } else if (sessionStorage.getItem("skip") === '11') {
      this.$nextTick(() => {
        this.skipp11();
      });
    } else if (sessionStorage.getItem("skip") === '12') {
      this.$nextTick(() => {
        this.skipp12();
      });
    } else if (sessionStorage.getItem("skip") === '13') {
      this.$nextTick(() => {
        this.skipp13();
      });
    } else if (sessionStorage.getItem("skip") === '14') {
      this.$nextTick(() => {
        this.skipp14();
      });
    }
    // 添加滚动事件监听器
    this.updateGroupedItems();
  },

  methods: {
    navigatTo(path){
      this[path]()
    },
    mapExpert(){
      this.expert.forEach((index,item)=>{
        console.log("__________",item)
        this.expert.push(index)
      })
    },
    routerSkip(link) {
      window.open(link, "_blank");
    },
    getSearch() {
      // console.log( this.search.length)
      this.$axios
          .get("api/search/getThirdByName_Introduce?keyword=" + this.keyTitle, {
            // .get("http://192.168.200.50:16542/search/getThirdByName_Introduce?keyword=" + this.keyTitle, {
            headers: {
              Authorization: "eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6IjUyNjZiN2QxLTNhOWUtNDBiOC05MGJlLTNjMTU1OGM3YmI3YiJ9.OsIDjD4UL8AZHu5j5KO-26IQwJu6uVvu59KNM18vuLmjzXMJFl-lX2vyyw_4L1AXC6wot6lpxhv_JZUVe1LokQ",
            },
          })
          .then((res) => {
            console.log("search", res.data)
            this.search = []
            var searchdata = res.data.data.thirdPageList

            searchdata.forEach((item, index) => {
              console.log(res.data.data.keywordList[0]!=="")
              if(res.data.data.keywordList[0]!==""){
                item.leighttitle = this.highlightText(item.title,  res.data.data.keywordList);
                item.leightcontend = this.highlightText(item.introduce,  res.data.data.keywordList);
                this.$set(this.search, index, {
                  link: item.url,
                  title: item.leighttitle,
                  contend: item.leightcontend,
                });
              }
              else{
                this.$set(this.search, index, {
                  link: item.url,
                  title: item.title,
                  contend: item.introduce,
                });
              }


            })
            console.log("成都", this.search.length)
          })
          .catch((err) => {
            console.log(err);
          });
    },
    highlightText(text, keywords) {
      const regex = this.createHighlightRegex(keywords);
      return text.replace(regex, (match) => `<span style="color: #eb7c31">${match}</span>`);
    },
    createHighlightRegex(keywords) {
      const escapedKeywords = keywords.map(keyword => keyword.replace(/[/\\^$*+?.()|[\]{}]/g, '\\$&'));
      const regexPattern = escapedKeywords.join('|');
      return new RegExp(regexPattern, 'gi');
    },
    mouseSwiper(index) {

      this.swiperSlid = this.swiperSlid.map(() => 0);
      this.$set(this.swiperSlid, index, 1);
    },
    overSwiper(index) {
      this.$set(this.swiperSlid, index, 0);
      // if (this.swiperSlid.every(value => value === 0)) {
      //   this.swiperSlid[index] = 1;
      // }
    },
    getClass(index) {
      return this.swiperSlid[index] === 1 ? 'box' : 'box1';
    },

    updateModel() {
      const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      this.model = screenWidth <= 700;
    },
    initSwiper() {
      // 确保 swiper-container 已经被渲染
      this.$nextTick(() => {

        this.swiper = new Swiper(".swiper-containerpc", {
          autoplay: 3000,
          disableOnInteraction: true,
          slidesPerView: 3,
          spaceBetween: 15,
          loop: false,
          pagination: ".swiper-pagination",
          paginationType: "bullets",
          paginationClickable: true,
          pauseOnMouseEnter: true, // 鼠标悬停时暂停自动播放
        });
        this.swiper = new Swiper(".swiper-containerpc2", {
          autoplay: 50000,
          disableOnInteraction: true,
          slidesPerView: 'auto',
          spaceBetween: 15,
          loop: false,
          pagination: ".swiper-pagination",
          paginationType: "bullets",
          paginationClickable: true,
          pauseOnMouseEnter: true, // 鼠标悬停时暂停自动播放
        });
        this.swiper = new Swiper(".swiper-container0", {
          autoplay: 3000,
          disableOnInteraction: true,
          slidesPerView: 1.2,
          spaceBetween: 10,
          loop: false,
          pagination: ".swiper-pagination",
          paginationType: "bullets",
          paginationClickable: true,
          pauseOnMouseEnter: true, // 鼠标悬停时暂停自动播放
        });

        this.swiper = new Swiper(".swiper-container1", {
          autoplay: 3000,
          disableOnInteraction: true,
          slidesPerView: 1.2,
          spaceBetween: 10,
          loop: false,
          pagination: ".swiper-pagination",
          paginationType: "bullets",
          paginationClickable: true,
          pauseOnMouseEnter: true, // 鼠标悬停时暂停自动播放
        });

      });
    },
    login() {
      this.$router.push("/login")
    },
    login1() {
      window.open("/login")
    },
    about(){
      window.open("/about")
    },
    hideComment() {
      localStorage.setItem("skip", 0)
      this.dialogComment = false;
    },
    hideProduct() {
      localStorage.setItem("skip", 0)
      this.dialogProduct = false;
    },
    hideNet() {
      localStorage.setItem("skip", 0)
      this.dialogNet = false;
    },

    fibernew() {
      window.open("fibernew")
    },
    eur() {
      window.open("eur")
    },
    plug() {
      window.open("plug")
    },
    recog() {
      window.open("recognition")
    },
    posHandle(ref) {
      let element = this.$refs[ref];
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      document.querySelector('.total').scroll({
        top: rect,
        behavior: 'smooth'
      })
    },
    distribute() {
      return distribute
    },

    handleScroll() {
      if (this.model === false) {
        this.scrollHeight = this.$refs.total.scrollTop;
      } else {
        this.scrollHeight = this.$refs.totalModel.scrollTop;
      }

      if (this.scrollHeight < 100) {
        this.scrollTrue = true
      } else {
        this.scrollTrue = false
      }
    },

    clickConsult(item) {
      this.title = item
      this.dialogVisible = true;
      sessionStorage.setItem('title', item)
    },
    show() {
      localStorage.setItem("skip", 0)
      this.dialogconsult = true;
    },
    comment() {
      window.open("/geoevaluation", "_blank");
    },
    exploit() {
      window.open("/schemedesign", "_blank");
    },
    intelligent() {
      window.open("/intelligentengineering", "_blank");
    },
    connect() {
      window.open("/potentialtapping", "_blank");
    },
    special() {
      window.open("/specialengineering", "_blank");
    },
    forecast() {
      window.open("/featuretech", "_blank");
    },
    ground() {
      window.open("/ground", "_blank");
    },
    modeling() {
      window.open("/modeling", "_blank");
    },
    single() {
      window.open("/integratedstimulation", "_blank");
    },
    integration() {
      window.open("/featuredproducts", "_blank");
    },
    risk() {
      window.open("/stimulationmonitoring", "_blank");
    },
    unconventional() {
      window.open("/unconventional", "_blank");
    },
    drill() {
      window.open("/drill", "_blank");
    },
    realtime() {
      window.open("/realtime", "_blank");
    },
    area() {
      window.open("/area", "_blank");
    },
    unite() {
      window.open("/unite", "_blank");
    },
    scene() {
      window.open("/scene", "_blank");
    },
    increase() {
      window.open("/TurnkeySolution", "_blank");
    },
    distributevue() {
      window.open("/FiberOptic", "_blank");
    },
    gasreservoir() {
      window.open("/logging", "_blank");
    },
    number() {
      window.open("/digital", "_blank");
    },
    naturalfrac(){
      window.open("/naturalfrac", "_blank");
    },
    geomechanics(){
      window.open("/geomechanics", "_blank");
    },
    thinreservoir(){
      window.open("/thinreservoir", "_blank");
    },


    gpts() {
      localStorage.setItem("skip", 0)
      window.open("https://www.oilgascommunity.com/recently?id=39", "_blank");
    },
    // 油藏地质
    // ground() {
    //   localStorage.setItem("skip", 0)
    //   window.open("/groundOil", "_blank");
    // },
    oilgpt() {
      localStorage.setItem("skip", 0)
      window.open("/gptOil", "_blank");
    },
    // 钻井
    hole() {
      localStorage.setItem("skip", 0)
      window.open("/makeHole", "_blank");
    },
    oilDesign() {
      localStorage.setItem("skip", 0)
      window.open("/gptDesign", "_blank");
    },
    oilTranslate() {
      localStorage.setItem("skip", 0)
      window.open("/gptTranslate", "_blank");
    },
    oilTechnology() {
      localStorage.setItem("skip", 0)
      window.open("/gptTechnology", "_blank");
    },
    oilgpt2() {
      localStorage.setItem("skip", 0)
      window.open("/gptTranslate", "_blank");
    },
    // 压裂
    frack() {
      localStorage.setItem("skip", 0)
      window.open("/frackOil", "_blank");
    },
    oilgpt3() {
      localStorage.setItem("skip", 0)
      window.open("/gptHole", "_blank");
    },
    // 采油
    getOil() {
      localStorage.setItem("skip", 0)
      window.open("/getOil", "_blank");
    },

    // <!--设备检测-->
    equipment() {
      localStorage.setItem("skip", 0)
      window.open("/monitorEqu", "_blank");
    },

    // 地层监测
    monitor() {
      localStorage.setItem("skip", 0)
      window.open("/monitorOil", "_blank");
    },

    // 油田管理
    menage() {
      localStorage.setItem("skip", 0)
      window.open("/menageOil", "_blank");
    },

    station() {
      localStorage.setItem("skip", 0)
      window.open("/station", "_blank");
    },
    security() {
      localStorage.setItem("skip", 0)
      window.open("/security", "_blank");
    },
    productANxun() {
      localStorage.setItem("skip", 0)
      window.open("/product", "_blank");
    },
    equipmentAnxun() {
      localStorage.setItem("skip", 0)
      window.open("/equipmentAnxun", "_blank");
    },
    technologyAnxun() {
      localStorage.setItem("skip", 0)
      window.open("/technology", "_blank");
    },
    monitorAnxun() {
      localStorage.setItem("skip", 0)
      window.open("/monitorAnxun", "_blank");
    },
    networkAnxun() {
      localStorage.setItem("skip", 0)
      window.open("/network", "_blank");
    },
    basic() {
      localStorage.setItem("skip", 0)
      window.open("/basic", "_blank");
    },
    setting() {
      localStorage.setItem("skip", 0)
      window.open("/setting", "_blank");
    },
    traning() {
      const element = this.$refs.aiPeixun;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      this.$nextTick(() => {
        this.$refs.total.scrollTop = rect
      });
    },
    governance() {
      window.open("/governance", "_blank");
    },
    analysis() {
      window.open("/analysis", "_blank");
    },
    traningAi() {
      window.open("/traning", "_blank");
    },

    consult() {
      window.open("/consult", "_blank");
    },
    ERP() {
      window.open("/ERP", "_blank");
    },
    java() {
      window.open("/java", "_blank");
    },
    web() {
      window.open("/web", "_blank");
    },
    service() {
      const element = this.$refs.oilZixun;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.total.scrollTop = rect
      });
    },
    meitiProduct() {
      window.open("http://www.oilmates-omm.com/h-col-106.html", "_blank");
    },
    meiti3D() {
      window.open("http://www.oilmates-omm.com/h-col-102.html", "_blank");
    },
    meitiDesign() {
      window.open("http://www.oilmates-omm.com/h-col-104.html", "_blank");
    },
    construct() {
      const element = this.$refs.companyZijian;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      this.$nextTick(() => {
        this.$refs.total.scrollTop = rect
      });
    },
    constructPlat() {
      window.open("/construct", "_blank");
    },
    business() {
      window.open("/business", "_blank");
    },
    company() {
      window.open("/company", "_blank");
    },
    computingPower() {
      window.open("/computingPower", "_blank");
    },

    menageService() {
      const element = this.$refs.menageService;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      this.$nextTick(() => {
        this.$refs.total.scrollTop = rect
      });
    },
    application() {
      window.open("/application", "_blank");
    },
    oilApplication() {
      window.open("/oilApplication", "_blank");
    },

    knowledge() {
      window.open("/gptKnowledge", "_blank");
    },
    data() {
      window.open("/gptData", "_blank");
    },
    wisdom() {
      window.open("/wisdom", "_blank");
    },
    plat() {
      window.open("/plat", "_blank");
    },
    design() {
      window.open("/design", "_blank");
    },
    automation() {
      window.open("/automation", "_blank");
    },

    traning1() {
      sessionStorage.setItem("skip", 11)
      this.$router.push("/traning")
    },
    service1() {
      sessionStorage.setItem("skip", 11)
      this.$router.push("/service")
    },
    construct1() {
      sessionStorage.setItem("skip", 11)
      this.$router.push("/construct")
    },
    menageService1() {
      const element = this.$refs.menageServices;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    application1() {
      sessionStorage.setItem("skip", 11)
      this.$router.push("/application")
    },
    oilApplication1() {
      sessionStorage.setItem("skip", 11)
      this.$router.push("/oilApplication")
    },

    knowledge1() {
      sessionStorage.setItem("skip", 10)
      this.$router.push("/gptKnowledge")
    },
    data1() {
      this.$router.push("/gptData")
      sessionStorage.setItem("skip", 10)
    },
    wisdom1() {
      sessionStorage.setItem("skip", 10)
      this.$router.push("/wisdom");
    },
    plat1() {
      sessionStorage.setItem("skip", 10)
      this.$router.push("/plat")
    },
    design1() {
      sessionStorage.setItem("skip", 10)
      this.$router.push("/design")
    },
    automation1() {
      sessionStorage.setItem("skip", 10)
      this.$router.push("/automation")
    },

    gpts1() {
      window.open("https://www.oilgascommunity.com/recently?id=39", "_blank");
    },
    // 油藏地质
    ground1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/groundOil")
    },
    oilgpt1() {
      sessionStorage.setItem("skip", 5)
      this.$router.push("/gptOil")
    },
    // 钻井
    hole1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/makeHole")
    },

    oilgpt21() {
      sessionStorage.setItem("skip", 5)
      this.$router.push("/gptTranslate")
    },
    // 压裂
    frack1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/frackOil")
    },
    oilgpt31() {
      sessionStorage.setItem("skip", 5)
      this.$router.push("/gptHole")
    },
    // 采油
    getOil1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/getOil")
    },

    // <!--设备检测-->
    equipment1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/monitorEqu")
    },

    // 地层监测
    monitor1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/monitorOil")
    },

    // 油田管理
    menage1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/menageOil")
    },

    station1() {
      sessionStorage.setItem("skip", 7)
      this.$router.push("/station")
    },
    security1() {
      sessionStorage.setItem("skip", 7)
      this.$router.push("/security")
    },
    productANxun1() {
      sessionStorage.setItem("skip", 7)
      this.$router.push("/product")
    },
    equipmentAnxun1() {
      sessionStorage.setItem("skip", 7)
      this.$router.push("/equipmentAnxun")
    },
    technologyAnxun1() {
      sessionStorage.setItem("skip", 7)
      this.$router.push("/technology")
    },
    monitorAnxun1() {
      sessionStorage.setItem("skip", 8)
      this.$router.push("/monitorAnxun")
    },
    networkAnxun1() {
      sessionStorage.setItem("skip", 8)
      this.$router.push("/network")
    },
    basic1() {
      sessionStorage.setItem("skip", 8)
      this.$router.push("/basic")
    },
    setting1() {
      sessionStorage.setItem("skip", 8)
      this.$router.push("/setting")
    },
    showPartner() {
      localStorage.setItem("skip", 0)
      this.dialogconsult = true;
    },
    showComment() {
      this.dialogComment = true
      // console.log("chufa")
    },
    showProduct() {
      this.dialogProduct = true
    },
    showNet() {
      console.log("------showNet------")
      this.dialogNet = true
    },
    scroll(value) {
      console.log(value)
      // 通用GPT
      if (value == 1) {
        this.$nextTick(() => {
          this.position1();
        });
      } else if (value == 2) {
        this.$nextTick(() => {
          this.position2();
        });
      } else if (value == 3) {
        this.$nextTick(() => {
          this.position3();
        });
      } else if (value == 4) {
        this.$nextTick(() => {
          this.position3();
        });
      } else if (value == 5) {
        this.$nextTick(() => {
          this.position5();
          // this.posHandle('generalAutoMobile')
        });
      } else if (value == 6) {
        this.$nextTick(() => {
          this.position6();
          // this.posHandle('generalAuto')
        });
      } else if (value == 7) {
        this.$nextTick(() => {
          this.position7();
          // this.posHandle('generalAuto')
        });
      } else if (value == 0) {
        this.$nextTick(() => {
          this.uptop();
        });
      }
    },
    scrollskip(value) {
      console.log(value)
      // 通用GPT
      if (value == 1) {
        this.$nextTick(() => {
          this.position2();
        });
      } else if (value == 2) {
        this.$nextTick(() => {
          this.position7();
        });
      } else if (value == 3) {
        this.$nextTick(() => {
          this.position6();
        });
      } else if (value == 4) {
        this.$nextTick(() => {
          this.position3();
        });
      } else if (value == 0) {
        this.$nextTick(() => {
          this.uptop();
        });
      }
    },
    hideDialog() {
      localStorage.setItem("skip", 0)
      this.dialogVisible = false;
    },

    hide() {
      localStorage.setItem("skip", 0)
      this.dialogconsult = false;
    },
    expo() {
      localStorage.setItem("skip", 0)
      window.open("https://cn.oilgasmall.com/", "_blank");
    },
    job() {
      localStorage.setItem("skip", 0)
      window.open("https://oilgasjobai.com/", "_blank");
    },
    info() {
      localStorage.setItem("skip", 0)
      window.open("https://cn.oilgasinfoai.com/", "_blank");
    },
    wen() {
      localStorage.setItem("skip", 0)
      window.open("https://yiyan.baidu.com/", "_blank");
    },
    dou() {
      localStorage.setItem("skip", 0)
      window.open("https://www.doubao.com/", "_blank");
    },
    xun() {
      localStorage.setItem("skip", 0)
      window.open("https://xinghuo.xfyun.cn/", "_blank");
    },
    tong() {
      localStorage.setItem("skip", 0)
      window.open("https://tongyi.aliyun.com/qianwen/", "_blank");
    },
    zhi() {
      localStorage.setItem("skip", 0)
      window.open("https://chatglm.cn/", "_blank");
    },
    teng() {
      localStorage.setItem("skip", 0)
      window.open("https://hunyuan.tencent.com/", "_blank");
    },
    tian() {
      localStorage.setItem("skip", 0)
      window.open("https://home.tiangong.cn/", "_blank");
    },
    ge() {
      localStorage.setItem("skip", 0)
      window.open("https://yige.baidu.com/", "_blank");
    },
    wan() {
      localStorage.setItem("skip", 0)
      window.open("https://ai.kezhan365.com/", "_blank");
    },
    yi() {
      localStorage.setItem("skip", 0)
      window.open("https://aigc.yizhentv.com/", "_blank");
    },


    expo1() {
      sessionStorage.setItem("skip", 2)
      // 在 Vue 组件或路由导航守卫中使用 router.push() 导航到外部链接
      const externalLink = 'https://cn.oilgasmall.com/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
    },
    job1() {
      sessionStorage.setItem("skip", 2)
      const externalLink = 'https://oilgasjobai.com/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

    },
    info1() {
      sessionStorage.setItem("skip", 2)
      const externalLink = 'https://cn.oilgasinfoai.com/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

    },
    wen1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://yiyan.baidu.com/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

    },
    traningAi1() {
      sessionStorage.setItem("skip", 12)
      this.$router.push("/traning")
    },
    consult1() {
      sessionStorage.setItem("skip", 12)
      this.$router.push("/consult")
    },
    constructPlat1() {
      sessionStorage.setItem("skip", 13)
      this.$router.push("/construct")

    },
    business1() {
      sessionStorage.setItem("skip", 13)
      this.$router.push("/business")
    },
    company1() {
      sessionStorage.setItem("skip", 13)
      this.$router.push("/company")
    },
    computingPower1() {
      sessionStorage.setItem("skip", 13)
      this.$router.push("/computingPower")
    },
    inter() {
      window.open("inter")
    },

    position2() {
      if (this.model === false) {
        const element = this.$refs.generalMove;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

        // eslint-disable-next-line no-inner-declarations
        function scrollToElement(element, to, duration) {
          if (duration <= 0) return;
          const difference = to - element.scrollTop;
          const perTick = difference / duration * 10;
          setTimeout(() => {
            element.scrollTop = element.scrollTop + perTick;
            if (element.scrollTop === to) return;
            scrollToElement(element, to, duration - 10);
          }, 10);
        }

        scrollToElement(this.$refs.total, rect, 500); // 滚动持续时间为500毫秒
      } else {
        console.log("1545")
        const element = this.$refs.GEGPTs;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

        // eslint-disable-next-line no-inner-declarations
        function scrollToElement(element, to, duration) {
          if (duration <= 0) return;
          const difference = to - element.scrollTop;
          const perTick = difference / duration * 10;
          setTimeout(() => {
            element.scrollTop = element.scrollTop + perTick;
            if (element.scrollTop === to) return;
            scrollToElement(element, to, duration - 10);
          }, 10);
        }

        scrollToElement(this.$refs.totalModel, rect, 500); // 滚动持续时间为500毫秒
      }
    },
    position3() {
      if (this.model === false) {
        const element = this.$refs.aiPeixun;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


        // eslint-disable-next-line no-inner-declarations
        function scrollToElement(element, to, duration) {
          if (duration <= 0) return;
          const difference = to - element.scrollTop;
          const perTick = difference / duration * 10;
          setTimeout(() => {
            element.scrollTop = element.scrollTop + perTick;
            if (element.scrollTop === to) return;
            scrollToElement(element, to, duration - 10);
          }, 10);
        }

        scrollToElement(this.$refs.total, rect, 500); // 滚动持续时间为500毫秒
      } else {
        const element = this.$refs.aiPeixunMobile;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


        // eslint-disable-next-line no-inner-declarations
        function scrollToElement(element, to, duration) {
          if (duration <= 0) return;
          const difference = to - element.scrollTop;
          const perTick = difference / duration * 10;
          setTimeout(() => {
            element.scrollTop = element.scrollTop + perTick;
            if (element.scrollTop === to) return;
            scrollToElement(element, to, duration - 10);
          }, 10);
        }

        scrollToElement(this.$refs.totalModel, rect, 500); // 滚动持续时间为500毫秒
      }
    },
    position5() {
      if (this.model === false) {
        const element = this.$refs.generalAuto;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


        // eslint-disable-next-line no-inner-declarations
        function scrollToElement(element, to, duration) {
          if (duration <= 0) return;
          const difference = to - element.scrollTop;
          const perTick = difference / duration * 10;
          setTimeout(() => {
            element.scrollTop = element.scrollTop + perTick;
            if (element.scrollTop === to) return;
            scrollToElement(element, to, duration - 10);
          }, 10);
        }

        scrollToElement(this.$refs.total, rect, 500); // 滚动持续时间为500毫秒
      } else {
        const element = this.$refs.generalAutoMobile;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


        // eslint-disable-next-line no-inner-declarations
        function scrollToElement(element, to, duration) {
          if (duration <= 0) return;
          const difference = to - element.scrollTop;
          const perTick = difference / duration * 10;
          setTimeout(() => {
            element.scrollTop = element.scrollTop + perTick;
            if (element.scrollTop === to) return;
            scrollToElement(element, to, duration - 10);
          }, 10);
        }

        scrollToElement(this.$refs.totalModel, rect, 500); // 滚动持续时间为500毫秒
      }
    },
    position6() {
      if (this.model === false) {
        const element = this.$refs.consultMove;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

        // eslint-disable-next-line no-inner-declarations
        function scrollToElement(element, to, duration) {
          if (duration <= 0) return;
          const difference = to - element.scrollTop;
          const perTick = difference / duration * 10;
          setTimeout(() => {
            element.scrollTop = element.scrollTop + perTick;
            if (element.scrollTop === to) return;
            scrollToElement(element, to, duration - 10);
          }, 10);
        }

        scrollToElement(this.$refs.total, rect, 500); // 滚动持续时间为500毫秒
      } else {
        const element = this.$refs.map;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

        // eslint-disable-next-line no-inner-declarations
        function scrollToElement(element, to, duration) {
          if (duration <= 0) return;
          const difference = to - element.scrollTop;
          const perTick = difference / duration * 10;
          setTimeout(() => {
            element.scrollTop = element.scrollTop + perTick;
            if (element.scrollTop === to) return;
            scrollToElement(element, to, duration - 10);
          }, 10);
        }

        scrollToElement(this.$refs.totalModel, rect, 500); // 滚动持续时间为500毫秒
      }

    },
    position7() {
      if (this.model === false) {
        const element = this.$refs.professional;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

        // eslint-disable-next-line no-inner-declarations
        function scrollToElement(element, to, duration) {
          if (duration <= 0) return;
          const difference = to - element.scrollTop;
          const perTick = difference / duration * 10;
          setTimeout(() => {
            element.scrollTop = element.scrollTop + perTick;
            if (element.scrollTop === to) return;
            scrollToElement(element, to, duration - 10);
          }, 10);
        }

        scrollToElement(this.$refs.total, rect, 500); // 滚动持续时间为500毫秒
      } else {
        const element = this.$refs.seriesService;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

        // eslint-disable-next-line no-inner-declarations
        function scrollToElement(element, to, duration) {
          if (duration <= 0) return;
          const difference = to - element.scrollTop;
          const perTick = difference / duration * 10;
          setTimeout(() => {
            element.scrollTop = element.scrollTop + perTick;
            if (element.scrollTop === to) return;
            scrollToElement(element, to, duration - 10);
          }, 10);
        }

        scrollToElement(this.$refs.totalModel, rect, 500); // 滚动持续时间为500毫秒
      }

    },
    position8() {
      if (this.model === false) {
        const element = this.$refs.generalAuto;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

        // eslint-disable-next-line no-inner-declarations
        function scrollToElement(element, to, duration) {
          if (duration <= 0) return;
          const difference = to - element.scrollTop;
          const perTick = difference / duration * 10;
          setTimeout(() => {
            element.scrollTop = element.scrollTop + perTick;
            if (element.scrollTop === to) return;
            scrollToElement(element, to, duration - 10);
          }, 10);
        }

        scrollToElement(this.$refs.total, rect, 500); // 滚动持续时间为500毫秒
      } else {
        const element = this.$refs.professionalMobile;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

        // eslint-disable-next-line no-inner-declarations
        function scrollToElement(element, to, duration) {
          if (duration <= 0) return;
          const difference = to - element.scrollTop;
          const perTick = difference / duration * 10;
          setTimeout(() => {
            element.scrollTop = element.scrollTop + perTick;
            if (element.scrollTop === to) return;
            scrollToElement(element, to, duration - 10);
          }, 10);
        }

        scrollToElement(this.$refs.totalModel, rect, 500); // 滚动持续时间为500毫秒
      }

    },
    skipp1() {
      const element = this.$refs.general;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp2() {
      const element = this.$refs.zixun;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp4() {
      const element = this.$refs.professional;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.total.scrollTop = rect
      });
    },
    skipp5() {
      const element = this.$refs.professional1;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp6() {
      const element = this.$refs.upload;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp7() {
      const element = this.$refs.station;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp8() {
      const element = this.$refs.technology;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp10() {
      const element = this.$refs.menageServices;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    uptop() {

      if (this.model === false) {
        const element = this.$refs.total;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

        this.$nextTick(() => {
          this.$refs.total.scrollTop = rect
        });
      } else {
        const elementModel = this.$refs.totalModel;
        const rect = elementModel.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

        this.$nextTick(() => {
          this.$refs.totalModel.scrollTop = rect
        });
      }


    },
    skipp11() {
      const element = this.$refs.company;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp12() {
      const element = this.$refs.traningConsult;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp13() {
      const element = this.$refs.companyConduct;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp14() {
      const element = this.$refs.media;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    dou1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://www.doubao.com/';
      this.$router.replace({name: 'ExternalLinkHandler', params: {url: externalLink}});
    },
    xun1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://xinghuo.xfyun.cn/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

    },
    tong1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://tongyi.aliyun.com/qianwen/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

    },
    zhi1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://chatglm.cn/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
    },
    teng1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://hunyuan.tencent.com/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
    },
    tian1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://home.tiangong.cn/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
    },
    ge1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://yige.baidu.com/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
    },
    wan1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://ai.kezhan365.com/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
    },
    yi1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://aigc.yizhentv.com/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
    },
    meitiProduct1() {
      sessionStorage.setItem("skip", 14)
      const externalLink = 'http://www.oilmates-omm.com/h-col-106.html';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

    },
    meiti3D1() {
      sessionStorage.setItem("skip", 14)
      const externalLink = 'http://www.oilmates-omm.com/h-col-102.html';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

    },
    meitiDesign1() {
      sessionStorage.setItem("skip", 14)
      const externalLink = 'http://www.oilmates-omm.com/h-col-104.html';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

    },
    updateGroupedItems() {
      const startIndex = this.currentIndex;
      this.groupedItems = [];

      for (let i = 0; i < this.itemsPerPage; i++) {
        const group = this.carouselItems.slice(
            startIndex + i,
            startIndex + i + this.itemsPerPage
        );
        this.groupedItems.push(group);
      }
    },
    upkefu2() {
      // window.open("https://channel-scrm.xiaoshouyi.com/embeded/im/iframe-h5.html?tenantToken=60cb1c79deb2cf2b3e2c53ad2ed91d09&siteId=3452726083343114&webPageTitle=在线咨询")
    window.open("https://channel-scrm.xiaoshouyi.com/embeded/im/iframe-h5.html?tenantToken=60cb1c79deb2cf2b3e2c53ad2ed91d09&siteId=3588905289569767&webPageTitle=在线咨询")
    },
    leftnext() {
      console.log(this.currentIndex);
      if (this.currentIndex > 0) {
        this.currentIndex--;
        this.updateGroupedItems();
      }
    },
    rightnext() {
      if (this.currentIndex < 1) {
        this.currentIndex++;
        this.updateGroupedItems();
      }
    },
    onCarouselChange(index) {
      this.currentIndex = index;
      this.updateGroupedItems();
    },
  },
};
</script>
<style>
::v-deep .down-popover{
  left: 80% !important;
}

.searchBack {
/* background-color: rgba(102, 102, 102, 0.2); */
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  column-gap: 1.5vw;
  row-gap: 2vh;
  padding: 3vh 2vw;
  list-style-type: none;
}
.searchBackno{
  display: flex;
  padding: 3vh 2vw;
  font-weight: bold;
/* justify-content: center; */
/* align-items: center; */
}
.searchContent {
  cursor: pointer;
/* background: #e5ebfa; */
  border: white 1px solid;
  background-color: white;
  box-shadow: 5px 0 14px 0px #e5ebfa;
  padding: 2vh 1.5vw;
  height: 100%;
  border-radius: 9px;
/* color: white; */
}
.searchContent .title1 {
    font-size: 1vw;
    font-weight: bold;
    line-height: 3.5vh;
  }

  .searchContent .title2 {
    font-size: 0.8vw;
    line-height: 2.8vh;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
    overflow: hidden;
    text-overflow: ellipsis;
    height:5.6vh ;
  }


.searchContent:hover {
  border: #9b85f4 1px solid;


}
@media (max-width: 757px) {
  .el-message-box {
    width: 300px !important;
    margin: 100px auto !important; /* 水平居中 */
  }

  .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 0;
    margin-top: 0;
  }

  .swiper-pagination {
    position: relative;
  }

  .swiper-pagination-bullet {
    width: 25px;
    height: 5px;
    border-radius: 9px;
  }

  .swiper-pagination-bullet-active {
    background-color: rgb(47, 110, 255);
  }
}

.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 0;
  /* margin-top: 2vh; */
}

.swiper-pagination {
  position: relative;
}

.swiper-pagination-bullet {
  width: 25px;
  border-radius: 9px;
}

.swiper-pagination-bullet-active {
  background-color: rgb(47, 110, 255);
}
</style>
<style lang="scss" scoped>
.avator {
  border: 2px solid #fff;
}




.userName {
  color: #2f6eff;
  font-size: 16px;
  line-height: 1.3;
  text-align: justify;
  margin-left: 10px;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.total {
  // background: #bed8f9;
  background: white;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
  position: relative;
  .run-enter-active {
    animation: fadeInLeft 0.8s linear 0s;
  }
  .run-leave-active {
    animation: fadeInLeft 0.8s linear 0s;
  }
  .screen1 {
    z-index: 3;
    position: relative;
    width: 100%;
    height: 100vh;
    padding: 0 0 2.84301606922126vh 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;

    .topback {
      position: fixed;
      z-index: 9;
      width: 100vw;
      padding: 1vh 0;
      background-color: rgb(52, 48, 48, 0.3);
    }

    .up {
      cursor: pointer;
      position: fixed;
      top: 88%;
      right: 2%;
      padding: 5px;
      width: 2.5vw;
      height: 2.5vw;
      border-radius: 50%;
      //border: 5px solid #1295d9;
      background-image: url("../assets/up.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
    }

    .layer2 {
      // margin: 0vh auto auto 8vw ;
      margin: 0vh auto ;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60vh;
      .title1 {
        transition: transform 0.3s ease-out;
        padding: 20vh 0 0  0;
        height: fit-content;
        font-size: 3.8vw;
        font-family: Roboto, sans-serif;
        font-weight: bold;
        // color: white;
        // color:#15257b;
        color:#f8f8f8;
        line-height: 9vh;
        text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.7); /* 添加阴影 */
        margin-top:15vh;
        margin-bottom: 6vh;
        .titlenew1{
          font-size: 3.8vw;
          font-weight: bold;
          color:#f8f8f8;
          margin-top:1.5vw;
          font-family: Roboto, sans-serif;
          text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.7); /* 添加阴影 */
        }
      .titlenew{
          font-size: 2vw;
          font-weight: bold;
          // color: white;
          // color:#15257b;
          color:#f8f8f8;
          margin-top:1.3vw;
          font-family: Roboto, sans-serif;
          text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.7); /* 添加阴影 */
      }
        .title2 {
          margin-top: 4vh;
          font-size: 1.6vw;
          font-family: Roboto, sans-serif;;
          font-weight: bold;
          color: white;
          line-height: 6vh;
          width: 40vw;
        }
      }


      .title31 {
        transition: transform 0.3s ease-out, height 0.5s ease-out;
        cursor: pointer;
        width: 35vw;
        display: flex;

        font-size: 2vw;
       font-family: Roboto, sans-serif;
        font-weight: bold;
        color: #13237a;
        line-height: 5vh;
        overflow: hidden; /* 隐藏溢出的内容 */
        .hoverImg {
          height: 2vw;
          min-width: 2vw;
          margin: 2vh 0 1vh 1vw;
        }

        .hoverTitleup {
          display: flex;
          align-items: center;
          height: 8vh;
          margin: 0 auto 0 1vw;
          width: fit-content;
          text-align: left;
          cursor: pointer;
          transition: transform 0.3s ease-out;
          font-size: 1.5vw;
          font-weight: bold;
        }

        .hoverTitleup2 {
          display: flex;
          align-items: center;
          margin: 0 auto 1vh auto;
          text-align: left;
          cursor: pointer;
          transition: max-height 0.5s ease-out, opacity 0.5s ease-out; /* 添加高度和透明度过渡 */
          font-size: 1.1vw;
          font-weight: bold;
          opacity: 0;
          max-height: 0; /* 初始最大高度为0 */
          overflow: hidden; /* 隐藏溢出的内容 */
        }

        .hoverTitle {
          display: flex;
          margin-right: 2vw;
          cursor: pointer;
          font-weight: normal;
          transition: transform 0.3s ease-out;
        }
      }

      .title31:hover {
        background-image: url("../assets/imgback.png");
        background-size: 100% auto;
        background-position: center;
        background-repeat: no-repeat;
        background-color: transparent;
        border-radius: 9px;
        height: fit-content; /* 悬停时高度增加 */
        .hoverTitleup2 {
          display: flex;
          //opacity: 1; /* 悬停时变为完全不透明 */
          //max-height: 15vh; /* 悬停时的最大高度，根据实际内容调整 */
        }
      }

      .title32 {
        position: absolute;
        bottom: 2vh;
        left: 49.5%;
        transform: translateX(-50%);
        transition: transform 0.3s ease-out, height 0.5s ease-out;
        cursor: pointer;
        width: 25vw;
        padding: 2vh 2vw;
        font-size: 1.1vw;
       font-family: Roboto, sans-serif;
        font-weight: bold;
        color: #13237a;
        line-height: 4vh;
        overflow: hidden; /* 隐藏溢出的内容 */
        .hoverImg {
          height: 3vw;
          width: fit-content;
          margin: 2vh auto 1vh auto;
        }

        .hoverTitleup {
          display: flex;
          height: 8vh;
          align-items: center;
          justify-content: center;
          margin: 0 auto 0 auto;
          text-align: center;
          cursor: pointer;
          transition: transform 0.3s ease-out;
          font-size: 1.3vw;
          font-weight: bold;
        }

        .hoverTitleup2 {
          display: flex;
          align-items: center;
          margin: 0 auto 1vh auto;
          text-align: left;
          cursor: pointer;
          transition: max-height 0.5s ease-out, opacity 0.5s ease-out; /* 添加高度和透明度过渡 */
          font-size: 1.1vw;
          font-weight: bold;
          opacity: 0;
          max-height: 0; /* 初始最大高度为0 */
          overflow: hidden; /* 隐藏溢出的内容 */
        }

        .hoverTitle {
          display: flex;
          margin-right: 2vw;
          cursor: pointer;
          font-weight: normal;
          transition: transform 0.3s ease-out;
        }
      }

      .title32:hover {
        background-image: url("../assets/imgback.png");
        background-size: 100% auto;
        background-position: center;
        background-repeat: no-repeat;
        background-color: transparent;
        border-radius: 9px;
        height: fit-content; /* 悬停时高度增加 */
        .hoverTitleup2 {
          display: flex;
          opacity: 1; /* 悬停时变为完全不透明 */
          max-height: 15vh; /* 悬停时的最大高度，根据实际内容调整 */
        }
      }

      .title33 {
        position: absolute;
        bottom: 2vh;
        right: 12vw;
        transition: transform 0.3s ease-out, height 0.5s ease-out;
        cursor: pointer;
        width: 25vw;
        padding: 2vh 2vw;
        font-size: 1.1vw;
       font-family: Roboto, sans-serif;
        font-weight: bold;
        color: #13237a;
        line-height: 4vh;
        overflow: hidden; /* 隐藏溢出的内容 */
        .hoverImg {
          height: 3vw;
          width: fit-content;
          margin: 2vh auto 1vh auto;
        }

        .hoverTitleup {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto 0 auto;
          text-align: center;
          cursor: pointer;
          height: 8vh;
          transition: transform 0.3s ease-out;
          font-size: 1.3vw;
          font-weight: bold;
        }

        .hoverTitleup2 {
          display: flex;
          align-items: center;
          margin: 0 auto 1vh auto;
          text-align: left;
          cursor: pointer;
          transition: max-height 0.5s ease-out, opacity 0.5s ease-out; /* 添加高度和透明度过渡 */
          font-size: 1.1vw;
          font-weight: bold;
          opacity: 0;
          max-height: 0; /* 初始最大高度为0 */
          overflow: hidden; /* 隐藏溢出的内容 */
        }

        .hoverTitle {
          display: flex;
          margin-right: 2vw;
          cursor: pointer;
          font-weight: normal;
          transition: transform 0.3s ease-out;
        }
      }

      .title33:hover {
        background-image: url("../assets/imgback.png");
        background-size: 100% auto;
        background-position: center;
        background-repeat: no-repeat;
        background-color: transparent;
        border-radius: 9px;
        height: fit-content; /* 悬停时高度增加 */
        .hoverTitleup2 {
          display: flex;
          opacity: 1; /* 悬停时变为完全不透明 */
          max-height: 15vh; /* 悬停时的最大高度，根据实际内容调整 */
        }
      }
    }
    .titlenew-content{
       display: flex;
       flex-wrap: wrap;  /* 如果你有多个项，允许换行 */
      //  justify-content: space-between; /* 分布布局，可以调整 */
      justify-content: space-around; /* 分布布局，可以调整 */
       gap: 20px; /* 元素之间的间距 */
      //  margin-left: 4vw;
      margin-right: 0.8vw;
    }.titlenew-content > div {
    display: flex;
    flex-direction: column; /* 垂直排列图标和文字 */
    align-items: center; /* 使图标和文字水平居中 */
    cursor: pointer; /* 点击时显示手形光标 */
}
        .Img{
          width: 60px;  /* 设置图标的固定宽度 */
          height: 60px; /* 设置图标的固定高度 */
        }
        .Img img {
    width: 100%;  /* 让图标充满其容器 */
    height: 100%; /* 让图标充满其容器 */
    object-fit: contain; /* 保持图标的比例 */
}
        .Img-title{
          font-size: 1.3vw; /* 文字大小，可以根据需要调整 */
         font-weight: bold;
         text-align: center; /* 文字居中 */
         color:#f8f8f8;
        line-height: 5vh;
        text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.7); /* 添加阴影 */
        transition: color 0.3s, transform 0.3s;
        }
.titlenew-content:hover .Img-title {
  color: #15257b; /* 鼠标悬停时文字颜色变化 */
  transform: translateX(5px); /* 鼠标悬停时文字平移效果 */
}
        .vertical-line {
       width: 1px; /* 设置宽度为1px */
       height: 100%; /* 使其高度与父容器相同 */
       background-color: white; /* 设置背景颜色为白色 */
}
      .vertical-line::after {
       content: ''; /* 必须设置content，即使内容为空 */
        display: block; /* 将伪元素转换为块级元素 */
        width: 2px; /* 设置宽度为1px */
        height: 10%; /* 使其高度与父容器相同 */
        background-color: white; /* 设置背景颜色为白色 */
        position: absolute; /* 绝对定位 */
        left: 50%; /* 将伪元素水平居中 */
        transform: translateX(-50%); /* 通过平移将其居中 */
}
// .titlenew-content {
//   display: flex;
//   cursor: pointer;
//   margin-top:4vh;
// }

// .content-item {
//   display: flex;
//   align-items: center;
//   margin-left:2vw;
//   margin-top:4vh;
// }
// .content-item1 {
//   display: flex;
//   align-items: center;
//   margin-left:6vw;
// }

// .Img {
//   margin-right: 10px; /* 图标和文字之间的间距 */
// }

// .Img img {
//   width: 60px; /* 设置图标的大小 */
//   height: 50px;
// }

// .Img-title {
//   font-size: 1.3vw;
//   font-weight: bold;
//   color:#f8f8f8;
//   transition: color 0.3s, transform 0.3s;
//   text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.7); /* 添加阴影 */
// }

// .titlenew-content:hover .Img-title {
//   color: #007bff; /* 鼠标悬停时文字颜色变化 */
//   transform: translateX(5px); /* 鼠标悬停时文字平移效果 */
// }

    .layer3 {
      position: absolute;
      bottom: 20vh;
      left: 47.5%;
      transform: translateX(-50%);
      width: 40vw;
      background-size: 100% auto;
      background-position: center;
      background-repeat: no-repeat;
      background-color: transparent;
      opacity: 1;
      margin-bottom: -140px;
      .search {
        display: flex;
        border-radius: 53px;
        width: 40vw;;
        height:7vh;
        background-color: white;
        margin-left: 2vw;

        ::v-deep .el-input {
          border-radius: 50%;
          height:7vh;
          width: 40vw;
          font-size: 1.2vw;
        }
        ::v-deep .el-input__inner {
          border-radius: 50%;
          height:7vh !important;
          border: white 1px solid !important;
          //background-color: transparent !important;
          background-color: transparent !important;
        }

      }

    }
    .hezuo{
        display: flex;
        justify-content: space-around;
        align-items: center;
        .titlenew1{
        margin-top: 3vw;
        color:#f8f8f8;
        font-family: Roboto, sans-serif;
        text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.7); /* 添加阴影 */
        // margin-left: 3vw;
        padding:10px 40px;
        border:2px solid white;
        border-radius: 40px;
        transition: all 0.3s ease; /* 平滑过渡 */
      }
      .titlenew2{
        margin-top: 3vw;
        margin-left: 6vw;
        color:#f8f8f8;
        font-family: Roboto, sans-serif;
        text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.7); /* 添加阴影 */
        padding:10px 40px;
        border:2px solid white;
        border-radius: 40px;
        transition: all 0.3s ease; /* 平滑过渡 */
      }
      .titlenew1:hover, .titlenew2:hover {
        background-color: white; /* 背景变为白色 */
        color: #15257b !important; /* 字体变为黑色 */
        text-shadow: none; /* 去除文字阴影 */
    // border-color: #000; /* 边框颜色变为黑色 */
     }

  /* 可选：添加渐变效果 */
     .titlenew1:hover, .titlenew2:hover {
    background: linear-gradient(45deg, #ffffff, #e0e0e0); /* 背景渐变 */
}
//       .titlenew1::after, .titlenew2::after {
//     content: "→"; /* 设置箭头字符 */
//     font-size: 1.8vw; /* 可以调整箭头大小 */
//     margin-left: 10px; /* 箭头与文字之间的间距 */
//     color: #ffffff; /* 箭头颜色 */
//     vertical-align: -0.2em; /* 向下调整箭头位置 */
// }
    }

  }
  .screenFace {
    //z-index: 10;
    width: 100vw;
    height: fit-content;
    //border-radius: 9px;
    padding: 13vh 11.09763142692085vw 13vh 8.09763142692085vw;
    background-image: url("../assets/faceBack.png");
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;

    ::v-deep img{
      object-fit: contain;
    }
    .title {

      height: fit-content;
      opacity: 1;
      font-size:3vw;
      color: white;
     font-family: Roboto, sans-serif;
      font-weight: 700;
      line-height: 10vh
    }

    .light {
      margin-top: 3vh;
      text-align: left;
      width: 50vw;
      height: fit-content;
      font-size: 1.8vw;
     font-family: Roboto, sans-serif;
      font-weight: 400;
      color: #15257b;
      line-height: 6vh;
    }

    .pro2 {
      height: fit-content;
      padding-top: 15vh;
      display: grid;
      grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
      overflow-x: hidden;
      //z-index: 89156;

      .grand1 {
        width: 100%;
        padding: 1vh 1vw 2vh 1vw;
        opacity: 1;
        position: relative;

        height: fit-content;
        .back {
          width: 100%;
          //margin:-8vh auto 0 0;
          height:100%;
          border-radius: 9px;
          // border-radius:6px;
          // display: flex;
        }

        .back1 {
          width: 100%;
          height:fit-content;

          border-top: none;
          border-bottom-right-radius: 9px;
          border-bottom-left-radius: 9px;
          // border-radius:6px;
          padding: 1vh 5%;
          justify-content: space-between;
        }

        .photo {
          //background-image: url("../assets/pro1.jpg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 6vw;
          display: flex;
          margin:0 auto;
          height: 6vw;
          position: relative;
        }
        .photohover{
          position: absolute;
          bottom: 0;
          left: 0; /* 初始位置在容器左侧外面 */
          width: 0%; /* 初始宽度为0 */
          height: 2vh;
          background-color: #2f6dfd;
          transition: width 0.5s ease; /* 平滑过渡效果 */
        }
        .photolist {
          font-size: 4.5vw;
          color: #e3e8ed;
          margin:auto 1vw 0 1vw;
          height: 100%;
          font-weight: bold;
        }

        .title1 {
          margin-top: 6vh;
          text-align: center;
          font-size: 1.5vw;
          font-family: Roboto, sans-serif;
          font-weight: bold;
          color: white;
          overflow: hidden;
          height: fit-content;
          line-height: 5.5vh;
        }

        .title2 {
          display: flex;
          opacity: 4;
          width: fit-content;
          height: fit-content;
          // text-align: justify;
          font-size: 0.9vw;
          font-family: Roboto, sans-serif;
          font-weight: 500;
          color: white;
          margin-top: 2vh;
          border-radius: 23px;
          padding: 5px 15px;
          background-color: #2168DB;
          //line-height: 35px;
        }
      }
      .grand1:hover{
        .photohover {
          width: 100%; /* 鼠标悬浮时宽度变为100% */
        }
      }

    }

    //.pro2 {
    //  height: fit-content;
    //  width: 100%;
    //  margin-top: 10vh;
    //  //z-index: 89156;
    //  display: grid;
    //  grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
    //  row-gap:10vh;
    //  column-gap: 1vw;
    //  .grand1 {
    //    cursor: pointer;
    //    width: 100%;
    //    border-bottom-right-radius: 53px;
    //    border-top-left-radius: 9px;
    //    border-top-right-radius: 9px;
    //    border-bottom-left-radius: 9px;
    //
    //    padding: 1vh 1vw;
    //    opacity: 1;
    //    position: relative;
    //    background-image: url("../assets/groundBack.png");
    //    background-position: left;
    //    background-size: cover;
    //    background-repeat: no-repeat;
    //    background-color: transparent;
    //    height: fit-content;
    //    .back {
    //      width: 100%;
    //      margin:-8vh auto 0 0;
    //      height:fit-content;
    //      border-radius: 9px;
    //      // border-radius:6px;
    //      // display: flex;
    //    }
    //
    //    .back1 {
    //      width: 100%;
    //      height:fit-content;
    //      margin-top:1vh;
    //      border-top: none;
    //      border-bottom-right-radius: 9px;
    //      border-bottom-left-radius: 9px;
    //      // border-radius:6px;
    //      padding: 1vh 1vw;
    //      justify-content: space-between;
    //    }
    //
    //    .photo {
    //      position: relative;
    //      //background-image: url("../assets/pro1.jpg");
    //      background-size: cover;
    //      background-position: center;
    //      background-repeat: no-repeat;
    //      background-color: transparent; /* 这里设置背景颜色为透明 */
    //      opacity: 1.25;
    //      width: 12vw;
    //      display: flex;
    //      margin:-5vh 0  0 5%;
    //      border-radius: 9px;
    //      height: fit-content;
    //    }
    //    .photohover{
    //      position: absolute;
    //      bottom: 0;
    //      left: 0; /* 初始位置在容器左侧外面 */
    //      width: 0%; /* 初始宽度为0 */
    //      height: 2vh;
    //      background-color: #2f6dfd;
    //      transition: width 0.5s ease; /* 平滑过渡效果 */
    //    }
    //    .photolist {
    //      font-size: 5vw;
    //      color: #e3e8ed;
    //      margin:auto 0 0 2.5vw;
    //      height: 100%;
    //      font-weight: bold;
    //    }
    //
    //    .title1 {
    //      text-align: left;
    //      font-size: 1vw;
    //      font-family: Roboto, sans-serif;
    //      font-weight: bold;
    //      width: 60vw;
    //      overflow: hidden;
    //      height: fit-content;
    //      //line-height: 35px;
    //    }
    //
    //    .title2 {
    //      display: flex;
    //
    //      opacity: 4;
    //      width: fit-content;
    //      height: fit-content;
    //      // text-align: justify;
    //      font-size: 0.9vw;
    //      font-family: Roboto, sans-serif;
    //      font-weight: 500;
    //      color: white;
    //      margin-top: 2vh;
    //      border-radius: 23px;
    //      padding: 5px 15px;
    //      background-color: #2168DB;
    //      //line-height: 35px;
    //    }
    //  }
    //  .grand1:hover{
    //    .photohover {
    //      width: 100%; /* 鼠标悬浮时宽度变为100% */
    //    }
    //  }
    //
    //}

  }
  .screennews10{
    width: 100vw;
    height: fit-content;
    // padding: 7vh 2.09763142692085vw 4vh 8.09763142692085vw;
    padding:5vh 4.09763142692085vw 8vh 4.09763142692085vw;
    background-color: white;
    // background-color: #f8f8f8;
    position: relative;
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
    .title-container {
        // margin-bottom: 5vh;
        .title {
            // color: #15257b;
            color: rgba(8,78,138,1);
            // font-size: 3vw;
            // font-weight: 700;
            font-size: 60px;
            font-weight: 400;
            text-align: center;
            margin: 0;
            line-height: 1.2;
        }
    }

.light {
margin: 3vh auto 3vh auto;
text-align: center;
width:66vw;
height: fit-content;
font-size: 1.5vw;
font-family: Roboto, sans-serif;
font-weight: 400;
// color: #15257b;
color: #555555;
line-height: 6vh;
// margin-left:8vw;
}
.container-new10{
  display: flex;
  width:100%;
}
.contentphoto{
position: relative;
display:inline-block;
border: 1px solid #E1E1E1; /* 2px 宽度，实线，黑色边框 */
box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* 阴影效果 */
border-radius:25px ;
margin-top:4vh;
width:36%
}
.photonew10{
  position: absolute;
  background-image: url("../assets/new/立即体验1.png");
  background-size: cover; /* 背景图填充容器 */
  width: 95%;          /* 设置容器宽度 */
  height: 70%;  
  // height: 30vh;       /* 设置容器高度 */
transition: background-image 0.3s ease-out; /* 添加图片透明度的过渡效果 */
border-radius: 25px;
margin-top:3.3vh;
margin-left:0.6vw;
}
.photonew10:hover{
  background-image: url("../assets/new/立即体验.png");
}
.photonew10 img{
  width: 100%;
  height: 70%;
  // object-fit: cover;
  // position: absolute;
  top: 0;
  left: 0;
  opacity: 0;  /* 让 img 标签中的图片不可见 */
}
.search {
  position: relative;
  z-index:3;
  margin-top: 2vh;
        display: flex;
        border-radius: 53px;
        // width: 40vw;;
        // height:7vh;
        background-color: white;
    

        ::v-deep .el-input {
          border-radius: 50%;
          // height:7vh;
          // width: 40vw;
          width:20vw;
          font-size: 15px;
          font-weight: 400;
        }
        ::v-deep .el-input__inner {
          border-radius: 50%;
          height:7vh !important;
          border: white 1px solid !important;
          background-color: transparent !important;
        }

      }
.title-top  {
    width: 100%;
    color: #555555;
    text-align: center;
    font-size: 2vw;
    font-weight: 400;
    padding: 10px;
    left: 15%;
    line-height:40px;
}
.title-bottom {
    width: 100%;
    color: #616161;
    text-align: center;
    font-size: 2vw;
    font-weight: 400;
    margin-top:10px;
    letter-spacing: 0.2em; /* 增加字间距 */
    line-height:3vh;
}
.click-experience {
    color: red; /* 设置“点击体验”部分的文字为红色 */
    font-weight: bold;
}
.zong{
  display: flex;
  gap: 1vw; /* 设定这两个元素之间的间距 */
}
.consult10{
  position: relative;
  display:inline-block;
  // margin-bottom: 100px; /* 使用负的 margin 使元素向上移动 */
  border-radius:25px ;
  border: 1px solid #E1E1E1; /* 2px 宽度，实线，黑色边框 */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* 阴影效果 */
  margin-top:4vh;
  margin-left:1vw;
  width:100%;
}
.photonew11{
  background-image: url("../assets/new/11.png");
  background-size: cover; /* 背景图填充容器 */
  width: 100%;          /* 设置容器宽度 */
  height: 100%;         /* 设置容器高度 */
  transition: background-image 0.3s ease-out; /* 添加图片透明度的过渡效果 */
  border-radius: 25px;
  margin-top:25px;
  margin-left: 1vw;
  margin-bottom:1vh;
}
.photonew11:hover{
  background-image: url("../assets/new/智能地质.png");
}
.photonew11 img{
  width: 100%;
  height: 100%;
  // object-fit: cover;
  // position: absolute;
  top: 0;
  left: 0;
  opacity: 0;  /* 让 img 标签中的图片不可见 */
}

.photonew12{
  background-image: url("../assets/new/22.png");
  background-size: cover; /* 背景图填充容器 */
  width: 100%;          /* 设置容器宽度 */
  height: 100%;         /* 设置容器高度 */
  transition: background-image 0.3s ease-out; /* 添加图片透明度的过渡效果 */
  border-radius: 25px;
  margin-top:25px;
}
.photonew12:hover{
  background-image: url("../assets/new/光纤漏点.png");
}
.photonew12 img{
  width: 100%;
  height: 100%;
  // object-fit: cover;
  // position: absolute;
  top: 0;
  left: 0;
  opacity: 0;  /* 让 img 标签中的图片不可见 */
}

.photonew13{
  background-image: url("../assets/new/33.png");
  background-size: cover; /* 背景图填充容器 */
  width: 100%;          /* 设置容器宽度 */
  height: 100%;         /* 设置容器高度 */
  transition: background-image 0.3s ease-out; /* 添加图片透明度的过渡效果 */
  border-radius: 25px;
  margin-top:25px;
  margin-right:1vw;
}
.photonew13:hover{
  background-image: url("../assets/new/压裂方案参数.png");
}
.photonew13 img{
  width: 100%;
  height: 100%;
  // object-fit: cover;
  // position: absolute;
  top: 0;
  left: 0;
  opacity: 0;  /* 让 img 标签中的图片不可见 */
}
.title-top  {
    width: 100%;
    color: #555555;
    text-align: center;
    font-size: 30px;
    font-weight: 400px;
    padding: 10px;
    left: 15%;
    line-height:40px;
}
.title-bottom {
    width: 100%;
    color: #616161;
    text-align: center;
    font-size: 22px;
    font-weight: 400;
    margin-top:10px;
    letter-spacing: 0.2em; /* 增加字间距 */
    line-height:30px;
}

  .screenConsult {
    //z-index: 10;
    width:60vw;
    height: fit-content;
    border-radius: 9px;
    padding: 5vh 10vw;
    background: transparent;
    color: #15257b;
    // background-color:white;
    margin-top: -7.5vh;
    .title {
      //height: 5vh;
      opacity: 1;
      font-size: 2vw;
      color: #15257b;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 10vh;
      text-align: left;
    }

    .light {
      // margin:3vh auto 4vh auto;
      text-align: justify;
      width: 100%;
      height: fit-content;
      font-size: 1.2vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #15257b;
      line-height: 3.94437577255871vh;
    }
    .pro2 {
      height: fit-content;
      padding-top: 2vh;
      overflow-x: hidden;
      //z-index: 89156;

      .grand1 {
        cursor: pointer;
        width: 100%;
        border-radius: 9px;
        padding: 1vw;
        opacity: 1;
        position: relative;
        background-color: white;
        height: fit-content;
        .back {
          width: 100%;
          margin:0 auto 0 0;
          height:fit-content;
          border-radius: 9px;
          // border-radius:6px;
          // display: flex;
        }

        .back1 {
          width: 100%;
          height:fit-content;
          margin-top:2vh;
          border-top: none;
          border-bottom-right-radius: 9px;
          border-bottom-left-radius: 9px;
          // border-radius:6px;
          padding: 1vh 0;
          justify-content: space-between;
        }

        .photo {
          //background-image: url("../assets/pro1.jpg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          display: flex;
          height: 45vh;
          border-top-right-radius: 9px;
          border-top-left-radius: 9px;
          position: relative;
        }
        .photohover{
          position: absolute;
          bottom: 0;
          left: 0; /* 初始位置在容器左侧外面 */
          width: 0%; /* 初始宽度为0 */
          height: 2vh;
          background-color: #2f6dfd;
          transition: width 0.5s ease; /* 平滑过渡效果 */
        }
        //.photolist {
        //  font-size: 5vw;
        //  color: #e3e8ed;
        //  margin:auto 1vw 0 2vw;
        //  height: 100%;
        //  width: fit-content;
        //  font-weight: bold;
        //}

        .title1 {
          text-align: left;
          font-size: 1.2vw;
          font-family: Roboto, sans-serif;
          font-weight: bold;
          width: 60vw;
          overflow: hidden;
          height: fit-content;
          //line-height: 35px;
        }

        .title2 {
          display: flex;
          opacity: 4;
          width: fit-content;
          height: fit-content;
          // text-align: justify;
          font-size:1vw;
          font-family: Roboto, sans-serif;
          font-weight: 500;
          color: white;
          margin-top: 2vh;
          border-radius: 23px;
          padding: 1vh 1vw;
          background-color: #2168DB;
          //line-height: 35px;
        }
      }
      .grand1:hover{
        .photohover {
          width: 100%; /* 鼠标悬浮时宽度变为100% */
        }
      }

    }

  }
  }
  .screennews11{
    width: 100vw;
    height: fit-content;
    padding: 7vh 4.09763142692085vw 8vh 4.09763142692085vw;
    // background-color: white;
    background-color: #f8f8f8;
    background-image:url("../assets/new/增产提效研究课题背景图.png");
    position: relative;
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
    .title-container {
        margin-bottom: 5vh;

        .title {
            // color: #15257b;
            color:white;
            // font-size: 3vw;
            // font-weight: 700;
            font-size: 60px;
            font-weight: 400;
            text-align: center;
            margin: 0;
            line-height: 1.2;
        }
    }
.container11 {
  position: relative;
  width: 100%;  /* 容器宽度 */
  height: fit-content;/* 容器高度 */
  overflow: hidden; /* 隐藏超出容器范围的内容 */
  // border:3px solid #ddd; /* 可选：容器的边框 */
  border-radius: 15px;
  box-sizing: border-box;  /* 包括边框和内边距在内计算容器尺寸 */
}

/* 图片包装器 */
.image-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden; /* 隐藏图片超出容器的部分 */
  background-color: white ;
}

/* 图片样式 */
.image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* 保持图片比例 */
  transition: transform 0.5s ease-out; /* 图片上移动画 */
}

/* 标题样式 */
.title11 {
  position: relative;
  bottom: 40px;
  left: 20px;
  // color: black;
  color:#555555;
  font-weight:400;
  font-size: 30px;
  // z-index: 1;
  transition: transform 0.5s ease-out; /* 标题上移动画 */
  margin-top:6vh;
}

/* 内容样式 */
.content11 {
  position: absolute;
  bottom: 0; /* 初始时将内容隐藏在下方 */
  left: 0;
  width: 60%;
  font-size: 20px;
  color: black;
  background-color: white;
  margin-bottom:20px;
  padding: 20px;
  text-align: left;
  transform: translateY(100%); /* 初始状态，内容被移动到容器底部之外 */
  transition: transform 0.5s ease-out; /* 内容滑入动画 */
}
 .discover-btn {
                    background: none;
                    border: none;
                    color: #15257b;
                    font-size: 1.1vw;
                    font-weight: 400;
                    padding: 0;
                    cursor: pointer;
                    transition: color 0.3s;
                    margin-top: 2vh;
                    margin-left:1.8vw;
                    &:hover {
                        color: darken(#15257b, 10%);
                    }
            }

/* 鼠标悬停时效果 */
.container11:hover .content11 {
  transform: translateY(25%); /* 鼠标悬停时内容向上滑入 */
}
.container11:hover .image {
  transform: translateY(-25%); /* 图片上移 */
}

.container11:hover .title11 {
  transform: translateY(-200%); /* 标题上移 */
}
.subcontainer{
  display: flex;
  align-items: center;
  width:100%;
  margin-top:1vh;
}
.container12 {
  position: relative;
  width: 50%;  /* 容器宽度 */
  height: fit-content;/* 容器高度 */
  overflow: hidden; /* 隐藏超出容器范围的内容 */
  // border: 1px solid #ddd; /* 可选：容器的边框 */
  border-radius: 15px;
}

/* 图片包装器 */
.image-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden; /* 隐藏图片超出容器的部分 */
}

/* 图片样式 */
.image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* 保持图片比例 */
  transition: transform 0.5s ease-out; /* 图片上移动画 */
  border-radius: 9px;
}

/* 标题样式 */
.title11 {
  position: relative;
  bottom: 40px;
  left: 20px;
  color: black;
  font-size: 30px;
  // z-index: 1;
  transition: transform 0.5s ease-out; /* 标题上移动画 */
  margin-top:6vh;
}

/* 内容样式 */
.content11 {
  position: absolute;
  bottom: 0; /* 初始时将内容隐藏在下方 */
  left: 0;
  width: 60%;
  font-size: 20px;
  color: black;
  padding: 20px;
  text-align: left;
  transform: translateY(100%); /* 初始状态，内容被移动到容器底部之外 */
  transition: transform 0.5s ease-out; /* 内容滑入动画 */
}
 .discover-btn {
                    background: none;
                    border: none;
                    color: #15257b;
                    font-size: 1.1vw;
                    font-weight: 400;
                    padding: 0;
                    cursor: pointer;
                    transition: color 0.3s;
                    margin-top: 1.5vh;
                    margin-left: 1.8vw;
                    &:hover {
                        color: darken(#15257b, 10%);
                    }
            }

/* 鼠标悬停时效果 */
.container12:hover .content11 {
  transform: translateY(25%); /* 鼠标悬停时内容向上滑入 */
}
.container12:hover .image {
  transform: translateY(-25%); /* 图片上移 */
}

.container12:hover .title11 {
  transform: translateY(-200%); /* 标题上移 */
}

.container13 {
  position: relative;
  width: 50%;  /* 容器宽度 */
  height: fit-content;/* 容器高度 */
  overflow: hidden; /* 隐藏超出容器范围的内容 */
  // border: 1px solid #ddd; /* 可选：容器的边框 */
  border-radius: 15px;
  margin-left: 1vw;
}

/* 图片包装器 */
.image-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden; /* 隐藏图片超出容器的部分 */
}

/* 图片样式 */
.image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* 保持图片比例 */
  transition: transform 0.5s ease-out; /* 图片上移动画 */
  border-radius: 9px;
}

/* 标题样式 */
.title11 {
  position: relative;
  bottom: 40px;
  left: 20px;
  color: black;
  font-size: 30px;
  // z-index: 1;
  transition: transform 0.5s ease-out; /* 标题上移动画 */
  margin-top:6vh;
}

/* 内容样式 */
.content11 {
  position: absolute;
  bottom: 0; /* 初始时将内容隐藏在下方 */
  left: 0;
  width: 100%;
  font-size: 20px;
  color: black;
  padding: 20px;
  text-align: left;
  transform: translateY(100%); /* 初始状态，内容被移动到容器底部之外 */
  transition: transform 0.5s ease-out; /* 内容滑入动画 */
}
 .discover-btn {
                    background: none;
                    border: none;
                    color: #15257b;
                    font-size: 1.1vw;
                    font-weight: 400;
                    padding: 0;
                    cursor: pointer;
                    transition: color 0.3s;
                    margin-top: 1.5vh;
                    margin-left:1.9vw;
                    &:hover {
                        color: darken(#15257b, 10%);
                    }
            }

/* 鼠标悬停时效果 */
.container13:hover .content11 {
  transform: translateY(25%); /* 鼠标悬停时内容向上滑入 */
}
.container13:hover .image {
  transform: translateY(-25%); /* 图片上移 */
}

.container13:hover .title11 {
  transform: translateY(-200%); /* 标题上移 */
}
}
  .screenConsultnew {
    //z-index: 10;
    width: 100vw;
    height: fit-content;
    // border-radius: 9px;
    padding: 5vh 4.09763142692085vw 0vh 4.09763142692085vw;
    background: transparent;
    // border: 1px solid #E1E1E1; /* 1px 宽度，实线，浅灰色边框 */
    // box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* 更重的阴影效果 */

    .title1 {

      //height: 5vh;
      opacity: 1;
      // font-size: 3vw;
      font-size: 60px;
       font-weight: 400;
      // color: #15257b;
      color: rgba(8,78,138,1);
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      line-height: 10vh;
    }

    .light1 {
      margin: 3vh auto 4vh auto;
      text-align: justify;
      width: 66vw;
      height: fit-content;
      font-size: 1.5vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #15257b;
      line-height: 4.94437577255871vh;

    }

    .title {

      margin-top: 6vh;
      height: 5vh;
      opacity: 1;
      font-size: 1.5vw;
      color: #15257b;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 3vh;
      text-align: left;
    }

    .light {
      margin: 0 auto 4vh auto;
      text-align: justify;
      width: 80vw;
      height: fit-content;
      font-size: 1.5vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #15257b;
      line-height: 4.94437577255871vh;

    }

    .serviceStyle {
      margin-top: 5vh;
      display: flex;
      //grid-template-columns:  minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
      column-gap: 0.5vw;
      // overflow: hidden;
      width:100%;
      border: 1px solid #E1E1E1; /* 1px 宽度，实线，浅灰色边框 */
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* 更重的阴影效果 */
      border-radius: 9px;
      .text-content {
            margin-left:1.5vw;
            width:27%;
            margin-top:5vh;
            .main-description {
                font-size: 1.6vw;
                line-height: 1.8;
                margin-bottom: 3vh;
                // color: #000000;
                color:#555555;
            }

            .sub-description {
                font-size: 1.1vw;
                line-height: 1.8;
                // color: #000000;
                color:#616161;
                margin-bottom: 4vh;
            }

            .discover-more {
                margin-top: 2vh;

                .discover-btn {
                    background: none;
                    border: none;
                    color: #15257b;
                    // color: #084E8A;
                    font-size: 1.1vw;
                    font-weight: 500;
                    padding: 0;
                    cursor: pointer;
                    transition: color 0.3s;

                    &:hover {
                        color: darken(#15257b, 10%);
                    }
                }
            }
          }

    //   .box, .box1 {
    //     transition: width 0.5s ease-in-out, opacity 0.5s ease-in-out, margin-top 0.5s ease-in-out;
    //   }

    //   //display: flex;
    //   .box {
    //     width: 42vw;
    //     cursor: pointer;
    //     margin: 0;
    //     height: 65vh;
    //     position: relative;
    //     border-bottom-right-radius: 33px;
    //     background-image: url("../assets/newback.png");
    //     background-position: center;
    //     background-size: contain;
    //     background-repeat: no-repeat;
    //     background-color: transparent;

    //     .back {
    //       display: flex;
    //     }

    //     .photo {

    //       position: absolute;
    //       width: 100%;
    //       height: 100%;
    //       z-index: 0;
    //       //background-image: url("../assets/pro1.jpg");
    //       background-size: cover;
    //       background-position: center;
    //       background-repeat: no-repeat;
    //       background-color: transparent; /* 这里设置背景颜色为透明 */
    //       opacity: 1.25;
    //       display: flex;
    //       border-radius: 9px;
    //     }

    //     .title {
    //       transition: margin-top 0.5s ease-in-out;
    //       position: relative;
    //       padding: 0;
    //       height: fit-content;
    //       opacity: 1;
    //       font-size: 1.8vw;
    //       z-index: 3;
    //       text-align: left;
    //       // color: white;
    //       color: #f8f8f8;
    //       font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";;
    //       font-weight: 700;
    //       line-height: 6vh;
    //       margin: 15vh 1vw 1vh 1vw;
    //       text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.7); /* 添加阴影 */
    //       margin-top: 30vh;
    //     }

    //     .span {
    //       animation: slideInFromLeft 0.5s ease-in-out forwards;
    //       position: relative;
    //       z-index: 3;
    //       margin: 2vh 0 8vh 1vw;
    //       width: 5vw;
    //       height: 0.2vh;
    //       background-color: white;
    //     }

    //     .light {
    //       animation: slideInFromLeft 0.5s ease-in-out forwards;
    //       position: relative;
    //       z-index: 3;
    //       margin: 0 1vw 0 1vw;
    //       text-align: left;
    //       height: fit-content;
    //       width: 95%;
    //       column-gap: 1vw;
    //       font-size: 0.9vw;
    //       font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";;
    //       font-weight: 400;
    //       color: #2C3E50;
    //       line-height: 2.5vh;

    //       .lightbehind {
    //         transition: transform 0.3s ease-out;
    //         cursor: pointer;
    //         display: flex;
    //         border-radius: 53px;
    //         color: white;
    //         margin-top: 4vh;

    //         .limit {
    //           margin: 0 auto 0 1.5vw;
    //           display: -webkit-box;
    //           align-items: center;
    //           font-size: 1.2vw;
    //           font-weight: bold;
    //           -webkit-box-orient: vertical;
    //           -webkit-line-clamp: 3; /* 限制在一个块元素显示的文本的行数 */
    //           overflow: hidden;
    //           line-height: 4vh;
    //           height: 4vh;
    //           text-overflow: ellipsis;
    //         }

    //       }

    //       .lightbehind:hover {
    //         font-weight: bold;
    //       }

    //       .biaoti {
    //         width: 2vw;
    //         height: fit-content;
    //         display: flex;
    //         align-items: center;
    //         margin: 0 0 auto 0;
    //       }

    //       .iconStyle {
    //         margin: 0 1vw auto auto;
    //         font-size: 1.2vw;
    //         //animation: pulse 2s infinite;
    //       }

    //       //@keyframes pulse { 0% { transform: translateX(0px); /* 原始大小 */ } 50% { transform: translateX(5px); /* 放大到120% */ } 100% { transform: translateX(-5px); /* 恢复到原始大小 */ } }
    //     }
    //   }

    //   @keyframes slideInFromLeft {
    //     from {
    //       transform: translateX(-50%); /* 从左侧隐藏的位置开始 */
    //       opacity: 0; /* 开始时完全透明 */
    //     }
    //     to {
    //       transform: translateX(0); /* 移动到正常位置 */
    //       opacity: 1; /* 结束时完全不透明 */
    //     }
    //   }

    //   .box1 {
    //     width: 20vw;
    //     cursor: pointer;
    //     margin: 0;
    //     height: 65vh;
    //     position: relative;
    //     border-bottom-right-radius: 33px;
    //     background-image: url("../assets/newback.png");
    //     background-position: center;
    //     background-size: contain;
    //     background-repeat: no-repeat;
    //     background-color: transparent;

    //     .back {
    //       display: flex;;
    //     }

    //     .photo {

    //       position: absolute;
    //       width: 100%;
    //       height: 100%;
    //       z-index: 0;
    //       //background-image: url("../assets/pro1.jpg");
    //       background-size: cover;
    //       background-position: center;
    //       background-repeat: no-repeat;
    //       background-color: transparent; /* 这里设置背景颜色为透明 */
    //       opacity: 1.25;
    //       display: flex;
    //       border-radius: 9px;
    //     }

    //     .title {
    //       transition: margin-top 0.5s ease-in-out;
    //       position: relative;
    //       padding: 0;
    //       height: fit-content;
    //       opacity: 1;
    //       font-size: 1.6vw;
    //       z-index: 3;
    //       text-align: left;
    //       // color: white;
    //       color: #f8f8f8;
    //       text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.7); /* 添加阴影 */
    //       font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";;
    //       font-weight: 700;
    //       line-height: 5vh;
    //       margin: 38vh 1vw 1vh 1vw;
    //       margin-top: 50vh;
    //     }

    //     .light {
    //       display: none;
    //       margin: 1vh 0 0 5%;
    //       text-align: left;
    //       height: fit-content;
    //       font-size: 0.9vw;
    //       width: 90%;
    //       font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";;
    //       font-weight: 400;
    //       color: #2C3E50;
    //       line-height: 2.5vh;

    //       .lightbehind {
    //         transition: transform 0.3s ease-out;
    //         cursor: pointer;
    //         display: flex;
    //         border: #8faffa 1px solid;
    //         background-color: #e4ecfd;
    //         margin-top: 1vh;
    //         border-radius: 53px;
    //         padding: 1vh 1vw;

    //         .limit {
    //           display: -webkit-box;
    //           align-items: center;
    //           font-size: 1.2vw;
    //           -webkit-box-orient: vertical;
    //           -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
    //           overflow: hidden;
    //           line-height: 3vh;
    //           height: 6vh;
    //           text-overflow: ellipsis;
    //         }

    //       }

    //       .lightbehind:hover {
    //         color: #8faffa;
    //       }

    //       .biaoti {
    //         width: 2vw;
    //         height: auto;
    //         margin: auto 0.5vw auto 0.5vw;
    //       }

    //       .iconStyle {
    //         margin: auto 1vw auto auto;
    //         //animation: pulse 2s infinite;
    //       }

    //       //@keyframes pulse { 0% { transform: translateX(0px); /* 原始大小 */ } 50% { transform: translateX(5px); /* 放大到120% */ } 100% { transform: translateX(-5px); /* 恢复到原始大小 */ } }
    //     }
    //   }

    //   @keyframes moveTitle {
    //     from {
    //       margin-top: 35vh;
    //     }
    //     to {
    //       margin-top: 10vh;
    //     }
    //   }

    //   .swiper-container {
    //     display: flex;
    //   }

    //   .swiper-slide {
    //     width: fit-content;
    //   }
    // }

    // ::v-deep img {
    //   object-fit: cover;
    // }
    // .pro2 {
    //   height: fit-content;
    //   width: 100%;
    //   z-index: 89156;
    //   display: grid;
    //   grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    //   column-gap: 0;
    //   row-gap: 1.8vh;

    //   .grand1 {
    //     cursor: pointer;
    //     width: 25.30329289428076vw;
    //     min-height: fit-content;
    //     padding: 0.98887515451174vh 0.12432120161756vw 0.98887515451174vh 0.19324090121317vw;
    //     opacity: 1;
    //     position: relative;

    //     .back {
    //       width: 100%;
    //       height: 100%;
    //       background: #EFF4FF;;
    //       box-shadow: 5px 0 14px 0px #D4E4FF;
    //       border: 2px solid #FFFFFF;
    //       border-radius: 5px;
    //       display: flex;
    //     }

    //     .photo {
    //       background-size: contain;
    //       background-position: center;
    //       background-repeat: no-repeat;
    //       background-color: transparent; /* 这里设置背景颜色为透明 */
    //       opacity: 1.25;
    //       margin: 3vh 0 3vh 0.8vw;
    //       display: flex;
    //       justify-content: center;
    //       text-align: center;
    //       width: 19vw;
    //       height: 17.44004944375773vh;
    //     }

    //     .title1 {
    //       opacity: 4;
    //       margin: 3.22496909765142vh 0.85540150202195vw 1.22496909765142vh 0.85540150202195vw;
    //       text-align: left;
    //       width: 12vw;

    //       height: 3vh;
    //       font-size: 0.97094165222415vw;
    //       white-space: nowrap; //换行
    //       font-family: Roboto, sans-serif;
    //       font-weight: bold;
    //       overflow: hidden;

    //       text-overflow: ellipsis;
    //       //color: #2168DB;
    //       line-height: 2.71940667490729vh;
    //     }

    //     .title2 {
    //       opacity: 4;
    //       margin: 0.94437577255871vh 0.85540150202195vw 0.94437577255871vh 1.54437577255871vh;
    //       //text-align: justify;
    //       display: flex;
    //       justify-content: center;
    //       align-items: center;
    //       font-size: 0.80878105141537vw;
    //       font-family: Roboto, sans-serif;
    //       font-weight: 500;
    //       //color: #2168DB;
    //       line-height: 2.51940667490729vh;
    //     }

    //     .consult {
    //       position: absolute;
    //       display: none;
    //       font-family: Roboto, sans-serif;
    //       font-weight: 500;
    //       font-size: 0.80878105141537vw;
    //       bottom: 3.94437577255871vh;
    //       left: 1.85540150202195vw;
    //     }
    //   }

    //   .grand1:hover {
    //     .back {
    //       box-shadow: 5px 0 14px 0px #9abaf3;
    //     }
    //   }

    //   .grand2 {
    //     cursor: pointer;
    //     width: 100%;
    //     min-height: 32.65636588380717vh;
    //     padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.39324090121317vw;
    //     opacity: 1;
    //     position: relative;

    //     .back {
    //       width: 100%;
    //       height: 100%;
    //       background: #EFF4FF;;
    //       box-shadow: 5px 0 14px 0px #D4E4FF;
    //       border: 2px solid #FFFFFF;
    //       border-radius: 9px;
    //       position: relative;
    //     }

    //     .photo {
    //       background-size: contain;
    //       background-position: center;
    //       background-repeat: no-repeat;
    //       background-color: transparent; /* 这里设置背景颜色为透明 */
    //       opacity: 1.25;
    //       //margin: 3vh auto auto auto;
    //       display: flex;
    //       justify-content: center;
    //       text-align: center;
    //       width: fit-content;
    //       //height: 4.73176761433869vh;
    //       .mengban {
    //         position: absolute;
    //         left: 0px;
    //         bottom: 0px;
    //         width: 100%;
    //         height: 20%;
    //         background: linear-gradient(to top, rgba(54, 64, 73, 0.9) 0%, rgba(54, 64, 73, 0.4) 50%, transparent 100%);
    //         opacity: 0.9;
    //         border-bottom-right-radius: 9px;
    //         border-bottom-left-radius: 9px;

    //         .title1 {
    //           position: absolute;
    //           height: 100%;
    //           color: white;
    //           margin-top: unset
    //         }
    //       }
    //     }

    //     .title1 {
    //       opacity: 4;
    //       margin-top: 2.22496909765142vh;
    //       text-align: center;
    //       display: flex;
    //       justify-content: center;
    //       align-items: center;
    //       height: 2.71940667490729vh;
    //       font-size: 1.17094165222415vw;
    //       font-family: Roboto, sans-serif;
    //       font-weight: bold;
    //       //color: #2168DB;
    //       line-height: 2.71940667490729vh;
    //     }

    //     .title2 {
    //       opacity: 4;
    //       margin: 1.5vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;
    //       //word-break: break-all;
    //       display: flex;
    //       justify-content: center;
    //       align-items: center;
    //       font-size: 0.80878105141537vw;
    //       font-family: Roboto, sans-serif;
    //       font-weight: 500;
    //       //color: #2168DB;
    //       line-height: 3.1940667490729vh;
    //     }

    //     .consult {
    //       position: absolute;
    //       display: none;
    //       font-family: Roboto, sans-serif;
    //       font-weight: 500;
    //       font-size: 0.80878105141537vw;
    //       bottom: 3.94437577255871vh;
    //       left: 1.85540150202195vw;
    //     }
    //   }
      .pro2 {
      width:70%;
      height: fit-content;
      // padding-top: 2vh;
      overflow-x: hidden;
      //z-index: 89156;

      .grand1 {
        cursor: pointer;
        width: 100%;
        border-radius: 9px;
        padding: 1vw;
        opacity: 1;
        position: relative;
        background-color: white;
        height: fit-content;
        .back {
          width: 100%;
          margin:0 auto 0 0;
          height:fit-content;
          border-radius: 9px;
          // border-radius:6px;
          // display: flex;
        }

        .back1 {
          width: 100%;
          height:fit-content;
          margin-top:2vh;
          border-top: none;
          border-bottom-right-radius: 9px;
          border-bottom-left-radius: 9px;
          // border-radius:6px;
          padding: 1vh 0;
          justify-content: space-between;
        }

        .photo {
          //background-image: url("../assets/pro1.jpg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          display: flex;
          height: 29vh;
          border-top-right-radius: 9px;
          border-top-left-radius: 9px;
          position: relative;
        }
        .photohover{
          position: absolute;
          bottom: 0;
          left: 0; /* 初始位置在容器左侧外面 */
          width: 0%; /* 初始宽度为0 */
          height: 2vh;
          background-color: #2f6dfd;
          transition: width 0.5s ease; /* 平滑过渡效果 */
        }
        //.photolist {
        //  font-size: 5vw;
        //  color: #e3e8ed;
        //  margin:auto 1vw 0 2vw;
        //  height: 100%;
        //  width: fit-content;
        //  font-weight: bold;
        //}

        .title1 {
          text-align: left;
          font-size: 1.5vw;
          font-family: Roboto, sans-serif;
          font-weight: bold;
          width: 60vw;
          overflow: hidden;
          height: fit-content;
          //line-height: 35px;
          margin-right:5vw;
          margin-top:-3vh;
        }
        .title6 {
          text-align: left;
          font-size: 1.2vw;
          font-family: Roboto, sans-serif;
          font-weight: 400;
          width: 60vw;
          overflow: hidden;
          height: fit-content;
          //line-height: 35px;
          margin-left:1.4vw;
        }
        .title3 {
          text-align: left;
          font-size: 1.2vw;
          font-family: Roboto, sans-serif;
          font-weight: 400;
          width: 60vw;
          overflow: hidden;
          height: fit-content;
          //line-height: 35px;
          margin-left:3vw;
        }
        .title4 {
          text-align: left;
          font-size: 1.2vw;
          font-family: Roboto, sans-serif;
          font-weight: 400;
          width: 60vw;
          overflow: hidden;
          height: fit-content;
          //line-height: 35px;
          margin-left:1.9vw;
        }
        .title5 {
          text-align: left;
          font-size: 1.2vw;
          font-family: Roboto, sans-serif;
          font-weight: 400;
          width: 60vw;
          overflow: hidden;
          height: fit-content;
          //line-height: 35px;
          margin-left:1.9vw;
        }

        .title2 {
          display: flex;
          opacity: 4;
          width: fit-content;
          height: fit-content;
          // text-align: justify;
          font-size:1vw;
          font-family: Roboto, sans-serif;
          font-weight: 500;
          color: white;
          margin-top: 2vh;
          border-radius: 23px;
          padding: 1vh 1vw;
          background-color: #2168DB;
          //line-height: 35px;
        }
      }
      .grand1:hover{
        .photohover {
          width: 100%; /* 鼠标悬浮时宽度变为100% */
        }
      }
    }
      //.grand2:hover {
      //  .back {
      //    background-color: rgb(135, 103, 215);
      //  }
      //
      //  .photo {
      //    display: none;
      //  }
      //
      //  .title1 {
      //    position: absolute;
      //    left:1.8vw;
      //    top: 2.22496909765142vh;
      //    height: 2.71940667490729vh;
      //    color: white;
      //    font-size: 0.97094165222415vw;
      //  }
      //
      //  .title2 {
      //    word-break: normal;
      //    margin-top: 8.22496909765142vh;
      //    color: white;
      //    font-size: 0.80878105141537vw;
      //  }
      //
      //  .consult {
      //    position: absolute;
      //    left: 1.8vw;
      //    cursor: pointer;
      //    display: block;
      //    color: white;
      //  }
      //}
    }

    .pro3 {
      //margin-top: -4vh;
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 0;
      row-gap: 2vh;

      .grand2 {
        cursor: pointer;
        width: 100%;

        height: fit-content;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.39324090121317vw;
        opacity: 1;

        .back {
          transition: transform 0.5s ease;
          width: 100%;
          position: relative;
          height: 40vh;
          background: #d4e4ff;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          //border: 2px solid #FFFFFF;
          border-radius: 9px;
        }

        .photo {
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          opacity: 1.25;
          margin: auto;
          background-color: white;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: 30vh;
        }


        .title1 {

          opacity: 4;
          margin-top: 3vh;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 2.71940667490729vh;
          font-size: 1.2vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: bold;
          color: #2168DB;
          line-height: 3.5vh;
        }

        .title2 {
          opacity: 4;
          margin: 1.94437577255871vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;
          text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.9vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          font-size: 0.9vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand2:hover {
        .back {
          transform: translateY(-10px);

        }

      }


    }

    .pro4 {
      //margin-top: -4vh;
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      padding: 0 10vw;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 0;
      row-gap: 2vh;

      .grand2 {
        cursor: pointer;
        width: 100%;

        height: fit-content;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.39324090121317vw;
        opacity: 1;

        .back {
          transition: transform 0.5s ease;
          width: 100%;
          position: relative;
          height: 40vh;
          background: #c0d8f9;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          //border: 2px solid #FFFFFF;
          border-radius: 9px;
        }

        .photo {
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          opacity: 1.25;
          margin: auto;
          background-color: white;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: 30vh;
        }


        .title1 {

          opacity: 4;
          margin-top: 3vh;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 2.71940667490729vh;
          font-size: 1.2vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: bold;
          color: #2168DB;
          line-height: 3.5vh;
        }

        .title2 {
          opacity: 4;
          margin: 1.94437577255871vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;
          text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.9vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          font-size: 0.9vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand2:hover {
        .back {
          transform: translateY(-10px);

        }

      }


    }


  }
  .screenMap {
    //z-index: 10;
    width: 100vw;
    height: fit-content;
    //border-radius: 9px;
    padding: 5vh 11.09763142692085vw 5vh 8.09763142692085vw;
    // background: #192079;
    background-color:rgba(245,245,245,1) ;
    background-image: url("../assets/new/关于我们背景.png");
    .title {

      //height: 5vh;
      opacity: 1;
      // font-size: 3vw;
      // color: white;
      font-size: 60px;
      font-weight: 400;
      color: rgba(8,78,138,1);
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      line-height: 10vh;
      text-align: center;

    }

    .light {
      margin: 3vh auto 6vh auto;
      text-align: center;
      width: 66vw;
      height: fit-content;
      font-size: 1.5vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      // color: white;
      color: rgba(97,97,97,1);
      line-height: 4.94437577255871vh;

    }
    .top{
      margin: 1vh 5vw 1vh 0 ;
      border-top: rgba(185, 185, 185, 0.5) 1px solid;
      padding: 2vh 0;
      cursor: pointer;
     width: 100%;
      //height: 13vh;

      .high1{
        color:white;
        // color:rgba(97,97,97,1);
        font-size: 1.2vw;
        line-height: 5vh;
        text-align: center;

      }
      .high2{
        // color: #fd8a08;
        // color:rgba(8,78,138,1);
        color: white;
        font-size: 1.2vw;
        font-weight: bold;
        width: fit-content;
        margin:0 auto;
        line-height: 4vh;
        text-align: center;

      }
    }

    .top:hover{
      .high2{
        font-size: 1.3vw;
      }

    }

  }
  .servicegrand {
    background-color: #123a74;
    width: 80vw;
    height: fit-content;
    margin: 4vh 0 0 0;

    .servicegrandback {
      padding: 4vh 4vh 0vh 4vh;

      .title {
        color: white;
        margin: 0;
        height: unset;
      }

      .light {
        width: fit-content;
        color: white;
        margin: unset;
        padding: 1vh 0 1vh 0
      }
    }
  }

  .servicecontent {
    display: grid;
    grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)  minmax(0, 1fr);
    width: 80vw;
    height: fit-content;
    background: linear-gradient(#123a74 50%, white 50%);

    .contentcard {
      height: fit-content;
      width: 19vw;
      background-color: #95d1d1;
      padding: 2vh 2vw 2vh 2vw;
      margin: auto
    }

    .contentcard :hover {
      cursor: pointer;
    }

    .servicecontenttitle {
      color: black;
      text-align: center;
      font-size: 1.0vw;
      font-weight: bolder;
    }
  }

  .screenGpts {
    //z-index: 10;
    width: 92vw;
    height: fit-content;
    margin: 5vh 11.09763142692085vw 8vh 4.09763142692085vw;
    background: white;
    position: relative;
    border: 1px solid #E1E1E1; /* 1px 宽度，实线，浅灰色边框 */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* 更重的阴影效果 */
    border-radius: 25px;
    margin-top:-0.6vh;
    margin-right:20vw;
    .title {

      height: fit-content;
      opacity: 1;
      font-size: 60px;
      font-weight: 400;
      // font-size:3vw;
      // color: #15257b;
      color: rgba(8,78,138,1);
     font-family: Roboto, sans-serif;
      line-height: 10vh
    }

    .light {
      margin: 3vh auto 3vh auto;
      text-align: center;
      width:66vw;
      height: fit-content;
      font-size: 1.5vw;
     font-family: Roboto, sans-serif;
      font-weight: 400;
      // color: #15257b;
      color:#555555;
      line-height: 6vh;
    }
    .login{
      width: fit-content;
      margin : 0 auto;
      display: grid;
      grid-template-columns: minmax(0,1fr) minmax(0,1fr);
      column-gap: 3vw;
      font-size: 1vw;
      margin-bottom:2vh;
      .loginUp{
        cursor: pointer;
        padding:1vh 3vw;
        border:1px solid #192079;
        color:#192079;
        font-family: Roboto, sans-serif;
        border-radius: 53px;
      }
      .loginUp:hover{
        background-color: #e0dddd;

      }
      .loginIn{
        cursor: pointer;
        padding:1vh 3vw;
        font-family: Roboto, sans-serif;
        // background-color:#192079;
        border:1px solid #192079;
        color:#192079;
        border-radius: 53px;
      }
      .loginIn:hover{
        // background-color:  #777996;
        background-color: #e0dddd;
      }
    }
    .searchBack{
      width:92vw;
      position: absolute;
      height: 52vh;
      top:23vh;
      display: block;
      // left:4vw;
      //filter: blur(10px);

      .searchContent{
        display: flex;
        animation: myMove 30s linear infinite;
        animation-fill-mode: forwards;
        border: rgba(128, 128, 128, 0.2) 1px solid;
        border-radius: 9px;
        padding: 1vh 2vw 1vh 0vw;
        // margin:3vh auto;
        height:fit-content;
      }
      .searchItem{
        height: 27vh;
        width:100%;
        gap:10px;
        margin-bottom:50vh;
        overflow-x: hidden;
        mask-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 1), rgba(0, 0, 0, 1), rgba(0, 0, 0, 1),  rgba(0, 0, 0, 1), rgba(0, 0, 0, 1),rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)); /* 新增 */
        -webkit-mask-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 1),rgba(0, 0, 0, 1),  rgba(0, 0, 0, 1), rgba(0, 0, 0, 1), rgba(0, 0, 0, 1), rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
      }
      .loginUp {
        cursor: pointer;
        margin-top: 5vh;
        font-family: Roboto, sans-serif;
        padding: 1vh 3vw;
        //border: 1px solid #192079;
        color: white;
        font-size:1vw;
        text-align: center;
        background-color: #192079;
        border-radius: 53px;
      }

      .loginUp:hover {
        background-color: rgba(25, 32, 121, 0.5);;
      }

      .photo{
        height: 7vh;
        width: 10vw;
        img{
          object-fit:contain ;
          height:100%;
          width:100%;
          margin-left:-2vw;
        }
      }
      .content{
        // margin: 0  0 0 2vw;
        transition: opacity 0.3s ease-in-out; /* 新增 */
        position: relative; /* 新增 */
        z-index: 1; /* 新增 */
        min-height: 3vh;     /* 增加内容区域的最小高度 (50% 视窗高度) */
        .title1{
          font-size: 1.3vw;
          line-height: 5.5vh;
        }
        .title2{
          font-size: 1vw;
          line-height: 2.4vh;
          width:8vw;
          margin-top:2.7vh;
          margin-left:-3vw;
        }
      }
      @keyframes myMove {
        0% {
          transform: translateX(0vW);
          //opacity: 0.5;
        }
        100% {
          transform: translateX(-200vW);
          //opacity: 0.5;
        }

      }
    }
    .pro3 {
      //margin-top: -4vh;
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr)  minmax(0, 1fr);
      column-gap: 0;
      row-gap: 2vh;

      .grand2 {
        cursor: pointer;
        width: 100%;

        height:fit-content;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.39324090121317vw;
        opacity: 1;

        .back {
          transition: transform 0.5s ease;
          width: 100%;
          position: relative;
          height: 100%;
          background: white;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
        }
        .photo {
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: #fff; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 0 auto auto auto;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: 35vh;
        }


        .title1 {
          padding: 5vh 0;
          opacity: 4;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 2.71940667490729vh;
          font-size: 1.17094165222415vw;
         font-family: Roboto, sans-serif;
          font-weight: bold;
          color: #2168DB;
          line-height: 3.5vh;
        }

        .title2 {
          opacity: 4;
          margin: 1.94437577255871vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;
          // //text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size:0.9vw;
         font-family: Roboto, sans-serif;
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .consult {
          position: absolute;
          display: none;
         font-family: Roboto, sans-serif;
          font-weight: 500;
          font-size:0.9vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand2:hover {
        .back {
          transform: translateY(-10px);

        }

      }
    }
  }
  .screennews6{
    width: 100vw;
    height: fit-content;
    padding: 7vh 4.09763142692085vw 0vh 4.09763142692085vw;
    background-color: white;
    // background-color: #f8f8f8;
    position: relative;
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
    .title-container {
        margin-bottom: 5vh;
        .title {
            color: #15257b;
            font-size: 3vw;
            font-weight: 700;
            text-align: center;
            margin: 0;
            line-height: 1.2;
        }
    }

.light {
margin: 3vh auto 3vh auto;
text-align: center;
width:66vw;
height: fit-content;
font-size: 1.5vw;
font-family: Roboto, sans-serif;
font-weight: 400;
color: #15257b;
line-height: 6vh;
}
.photocontent{
  display: flex;
  border: 1px solid #E1E1E1; /* 1px 宽度，实线，浅灰色边框 */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* 更重的阴影效果 */
  border-radius: 9px;
}
.photocontent1{
  width: 100%;
  position: relative;
  text-align: center; /* 让文字居中 */
  margin-top:2vh;
  margin-bottom:2vh;
}
.photocontent1 img{
  // width: 22vw;
  // height: 33vh;
  width: 20.8vw;
  height: 37vh;
  border-radius: 12px;
}
  .newtitle3 {
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.2vw;
  font-weight: 400;
  color: white; /* 文字颜色，建议与图片背景有对比 */
  padding: 5px 10px;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.3s ease;
  }
//   .newtitle3:hover {
//   color: #007bff

// }
  .newtitle4 {
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.2vw;
  font-weight: 400;
  color: white; /* 文字颜色，建议与图片背景有对比 */
  padding: 5px 10px;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.3s ease;
  }
//  .newtitle4:hover{
//   color: #007bff; /* 鼠标悬停时文字颜色变化 */
// }
.text-content {
            margin-right:5vw;
            width:70%;
            .main-description {
                font-size: 1.6vw;
                line-height: 1.8;
                margin-bottom: 3vh;
                // color: #000000;
                color:#555555;
            }

            .sub-description {
                font-size: 1.1vw;
                line-height: 1.8;
                // color: #000000;
                color:#616161;
                margin-bottom: 4vh;
            }

            .discover-more {
                margin-top: 2vh;

                .discover-btn {
                    background: none;
                    border: none;
                    color: #15257b;
                    font-size: 1.1vw;
                    font-weight: 500;
                    padding: 0;
                    cursor: pointer;
                    transition: color 0.3s;

                    &:hover {
                        color: darken(#15257b, 10%);
                    }
                }
            }
          }
}

  .screennews7{
    width: 100vw;
    height: fit-content;
    padding: 7vh 4.09763142692085vw 0vh 4.09763142692085vw;
    background-color: white;
    // background-color: #f8f8f8;
    position: relative;
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
    .title-container {
        margin-bottom: 5vh;
        .title {
            color: #15257b;
            font-size: 3vw;
            font-weight: 700;
            text-align: center;
            margin: 0;
            line-height: 1.2;
        }
    }

.light {
margin: 3vh auto 3vh auto;
text-align: center;
width:66vw;
height: fit-content;
font-size: 1.5vw;
font-family: Roboto, sans-serif;
font-weight: 400;
color: #15257b;
line-height: 6vh;
}
.photocontent{
  display: flex;

  border: 1px solid #E1E1E1; /* 1px 宽度，实线，浅灰色边框 */
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* 更重的阴影效果 */
      border-radius: 9px;
}
.photocontent1{
  width: 100%;
  position: relative;
  text-align: center; /* 让文字居中 */
  margin-top:2vh;
  margin-bottom:2vh;
}
.photocontent1 img{
  width: 20.8vw;
  height: 37vh;
  border-radius: 12px;
}
  .newtitle3 {
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.2vw;
  font-weight: 400;
  color: white; /* 文字颜色，建议与图片背景有对比 */
  padding: 5px 10px;
  border-radius: 5px;
  width:100%;
  cursor: pointer;
  transition: color 0.3s, transform 0.3s;
  }
// .newtitle3:hover {
//   color: #007bff; /* 鼠标悬停时文字颜色变化 */
// }
  .newtitle4 {
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.2vw;
  font-weight: 400;
  color: white; /* 文字颜色，建议与图片背景有对比 */
  padding: 5px 10px;
  border-radius: 5px;
  width:100%;
  cursor: pointer;
  }
//   .newtitle4:hover {
//   color: #007bff; /* 鼠标悬停时文字颜色变化 */
// }
.text-content {
            margin-left:6vw;
            margin-top:5vh;
            width:70%;
            .main-description {
                font-size: 1.6vw;
                line-height: 1.8;
                margin-bottom: 3vh;
                // color: #000000;
                color:#555555;
            }

            .sub-description {
                font-size: 1.1vw;
                line-height: 1.8;
                // color: #000000;
                color: #616161;
                margin-bottom: 4vh;
            }

            .discover-more {
                margin-top: 2vh;

                .discover-btn {
                    background: none;
                    border: none;
                    color: #15257b;
                    font-size: 1.1vw;
                    font-weight: 500;
                    padding: 0;
                    cursor: pointer;
                    transition: color 0.3s;

                    &:hover {
                        color: darken(#15257b, 10%);
                    }
                }
            }
        }
  }

.screennews8{
  width: 100vw;
    height: fit-content;
    padding: 7vh 4.09763142692085vw 0vh 4.09763142692085vw;
    background-color: white;
    // background-color: #f8f8f8;
    position: relative;
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
    .title-container {
        margin-bottom: 5vh;
        .title {
            color: #15257b;
            font-size: 3vw;
            font-weight: 700;
            text-align: center;
            margin: 0;
            line-height: 1.2;
        }
    }

.light {
margin: 3vh auto 3vh auto;
text-align: center;
width:66vw;
height: fit-content;
font-size: 1.5vw;
font-family: Roboto, sans-serif;
font-weight: 400;
color: #15257b;
line-height: 6vh;
}
.containerzong{
  width: 100%;
  display: flex;
  border: 1px solid #E1E1E1; /* 1px 宽度，实线，浅灰色边框 */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* 更重的阴影效果 */
  border-radius: 9px;
  // flex-direction: space-between; /* 确保整体的排布是等间距的 */
  // align-items: center;
  // gap: 20px; /* 元素之间的间隔 */
  }
//   .photocontent{
//   display: flex;
//   flex-direction: space-between; /* 确保整体的排布是等间距的 */
//   align-items: center;
//   gap: 20px; /* 元素之间的间隔 */

// }
  // .pro2 {
  //   width:48%;
  //     height: fit-content;
  //     padding-top: 2vh;
  //     overflow-x: hidden;
  //     //z-index: 89156;

  //     .grand1 {
  //       cursor: pointer;
  //       width: 100%;
  //       border-radius: 9px;
  //       padding: 1vw;
  //       opacity: 1;
  //       position: relative;
  //       background-color: white;
  //       height: fit-content;
  //       .back {
  //         width: 100%;
  //         margin:0 auto 0 0;
  //         height:fit-content;
  //         border-radius: 9px;
  //         // border-radius:6px;
  //         // display: flex;
  //       }

  //       .back1 {
  //         width: 100%;
  //         height:fit-content;
  //         margin-top:2vh;
  //         border-top: none;
  //         border-bottom-right-radius: 9px;
  //         border-bottom-left-radius: 9px;
  //         // border-radius:6px;
  //         padding: 1vh 0;
  //         justify-content: space-between;
  //       }

  //       .photo {
  //         //background-image: url("../assets/pro1.jpg");
  //         background-size: cover;
  //         background-position: center;
  //         background-repeat: no-repeat;
  //         background-color: transparent; /* 这里设置背景颜色为透明 */
  //         opacity: 1.25;
  //         width: 100%;
  //         display: flex;
  //         height: 45vh;
  //         border-top-right-radius: 9px;
  //         border-top-left-radius: 9px;
  //         position: relative;
  //       }
  //       .photohover{
  //         position: absolute;
  //         bottom: 0;
  //         left: 0; /* 初始位置在容器左侧外面 */
  //         width: 0%; /* 初始宽度为0 */
  //         height: 2vh;
  //         background-color: #2f6dfd;
  //         transition: width 0.5s ease; /* 平滑过渡效果 */
  //       }
  //       //.photolist {
  //       //  font-size: 5vw;
  //       //  color: #e3e8ed;
  //       //  margin:auto 1vw 0 2vw;
  //       //  height: 100%;
  //       //  width: fit-content;
  //       //  font-weight: bold;
  //       //}

  //       .title1 {
  //         text-align: left;
  //         font-size: 1.5vw;
  //         font-family: Roboto, sans-serif;
  //         font-weight: bold;
  //         width: 60vw;
  //         overflow: hidden;
  //         height: fit-content;
  //         //line-height: 35px;
  //         margin-left:6.3vw;
  //       }

  //       .title2 {
  //         display: flex;
  //         opacity: 4;
  //         width: fit-content;
  //         height: fit-content;
  //         // text-align: justify;
  //         font-size:1vw;
  //         font-family: Roboto, sans-serif;
  //         font-weight: 500;
  //         color: white;
  //         margin-top: 2vh;
  //         border-radius: 23px;
  //         padding: 1vh 1vw;
  //         background-color: #2168DB;
  //         //line-height: 35px;
  //       }
  //     }
  //     .grand1:hover{
  //       .photohover {
  //         width: 100%; /* 鼠标悬浮时宽度变为100% */
  //       }
  //     }

  //   }


.container{
  justify-content: center; /* 确保图片在容器中居中对齐 */
}
.photocontent{
  display: flex;
  flex-direction: space-between; /* 确保整体的排布是等间距的 */
  align-items: center;
  gap: 1vw; /* 元素之间的间隔 */

}
.photo-container{
  width: 80%; /* 可以根据需要调整 */
  justify-content: center; /* 确保图片在容器中居中对齐 */
  flex-wrap: wrap; /* 如果需要，超出宽度时换行 */
  margin-left: 70px;
}
.photocontent1{
  position: relative;
  text-align: center; /* 让文字居中 */
}
.photocontent1 img {
  height: auto;
  display: block;
  margin: 0 auto;
  border-radius: 12px;
  width: 100%;
}
.newtitle3 {
  position: absolute;
  top: 86%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.2vw;
  font-weight: 400;
  color: white; /* 文字颜色，建议与图片背景有对比 */
  // padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: color 0.3s, transform 0.3s;
}
// .newtitle3:hover {
//   color: #007bff; /* 鼠标悬停时文字颜色变化 */
// }
.photocontent2 {
  position: relative;
  text-align: center; /* 让文字居中 */
  width: 70%; /* 可以根据需要调整 */
  height: 100%;
}

.photocontent2 img {
  width: 100%; /* 可以根据需要调整 */
  height: 100%;
  border-radius: 12px;
}
.photocontent2 .newtitle4 {
  width: 100%;
  position: absolute;
  top: 23%;
  left: 30%;
  transform: translate(-50%, -50%);
  font-size: 1.8vw;
  font-weight: bold;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: color 0.3s, transform 0.3s;
}
 .newtitle4:hover {
  color: #007bff; /* 鼠标悬停时文字颜色变化 */
}
.text-content {
            // margin-left:1.8vw;
            width:28%;
            margin-top:-20vh;
            .main-description {
                font-size: 1.6vw;
                line-height: 1.8;
                margin-bottom: 3vh;
                // color: #000000;
                color:#555555;
            }

            .sub-description {
                font-size: 1.1vw;
                line-height: 1.8;
                // color: #000000;
                color:#616161;
                margin-bottom: 4vh;
            }

            .discover-more {
                margin-top: 2vh;

                .discover-btn {
                    background: none;
                    border: none;
                    color: #15257b;
                    font-size: 1.1vw;
                    font-weight: 500;
                    padding: 0;
                    cursor: pointer;
                    transition: color 0.3s;

                    &:hover {
                        color: darken(#15257b, 10%);
                    }
                }
            }
        }
}

.screennews9{
  width: 100vw;
    height: fit-content;
    padding: 7vh 4.09763142692085vw 4vh 4.09763142692085vw;
    background-color: white;
    // background-color: #f8f8f8;
    position: relative;
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
    .title-container {
        margin-bottom: 5vh;
        .title {
            color: #15257b;
            font-size: 3vw;
            font-weight: 700;
            text-align: center;
            margin: 0;
            line-height: 1.2;
        }
    }

.light {
margin: 3vh auto 3vh auto;
text-align: center;
width:66vw;
height: fit-content;
font-size: 1.5vw;
font-family: Roboto, sans-serif;
font-weight: 400;
color: #15257b;
line-height: 6vh;
}
.photocontent{
  // width:auto;
  // height:auto;
  display: flex;
  border: 1px solid #E1E1E1; /* 1px 宽度，实线，浅灰色边框 */
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* 更重的阴影效果 */
      border-radius: 9px;
}
.photocontent img{
  border-radius: 12px;
  width:38vw;
  height:37vh;
  margin-left:3.5vw;
  margin-top:2vh;
  margin-bottom: 2vh;
}
.text-content {
           width:40%;
            .main-description {
                font-size: 1.6vw;
                line-height: 1.8;
                margin-bottom: 3vh;
                // color: #000000;
                color: #555555;
            }

            .sub-description {
                font-size: 1.1vw;
                line-height: 1.8;
                // color: #000000;
                color: #616161;
                margin-bottom: 4vh;
            }

            .discover-more {
                margin-top: 2vh;

                .discover-btn {
                    background: none;
                    border: none;
                    color: #15257b;
                    font-size: 1.1vw;
                    font-weight: 500;
                    padding: 0;
                    cursor: pointer;
                    transition: color 0.3s;

                    &:hover {
                        color: darken(#15257b, 10%);
                    }
                }
            }
        }
}
  .screennews2{
    width: 100vw;
    height: fit-content;
    padding: 7vh 11.09763142692085vw 8vh 8.09763142692085vw;
    // background-color: white;
    background-color: #f8f8f8;
    position: relative;
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
    .title-container {
        margin-bottom: 5vh;

        .title {
            color: #15257b;
            font-size: 3vw;
            font-weight: 700;
            text-align: center;
            margin: 0;
            line-height: 1.2;
        }
    }
    .container-content{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      flex-direction: row;
      justify-content:flex-start;
      align-items: center;
    .content-photo1{
     position: relative;
     .photo-text{
      width:90%;
      position: absolute;
      top: 60px; /* 距离顶部的距离，调整为你想要的值 */
      left: 140px; /* 距离左侧的距离，调整为你想要的值 */
      font-size: 1.8rem; /* 可以根据需要调整字体大小 */
      font-weight: bold; /* 标题加粗 */
      color: black; /* 设置文字颜色为白色，使其在图片上更为明显 */
      z-index: 4; /* 保证标题在图片之上 */
      transition: font-size 0.3s ease; /* 添加平滑过渡效果 */
     }
     .photo-text:hover {
       font-size: 2rem;
     }
     .subphoto-text {
      position: absolute;
      top: 130px; /* 距离顶部的距离，放在标题下方，调整为你想要的值 */
      left: 140px; /* 距离左侧的距离 */
      font-size: 1.4rem; /* 内容文字的字体大小 */
      color: black; /* 设置文字颜色为白色 */
      z-index: 3; /* 保证内容文字在图片之上 */
}
img{
      width:90%;
      height: 100%;
      border-radius: 10px;
      margin-left: 5px;
      margin-left:5vw;
    }
    }
    .subphoto-content{
      display: flex;
      .content-photo2{
        position: relative;
        .photo-text{
          position:absolute;
          top: 70px; /* 距离顶部的距离，调整为你想要的值 */
          left: 140px; /* 距离左侧的距离，调整为你想要的值 */
          font-size: 1.8rem; /* 可以根据需要调整字体大小 */
          font-weight: bold; /* 标题加粗 */
          color: black; /* 设置文字颜色为白色，使其在图片上更为明显 */
          z-index: 4; /* 保证标题在图片之上 */
          transition: font-size 0.3s ease; /* 添加平滑过渡效果 */
        }
        .photo-text:hover {
       font-size: 2rem;
         }
        .subphoto-text {
      position: absolute;
      top: 140px; /* 距离顶部的距离，放在标题下方，调整为你想要的值 */
      left: 140px; /* 距离左侧的距离 */
      right:50px;
      font-size: 1.4rem; /* 内容文字的字体大小 */
      color: black; /* 设置文字颜色为白色 */
      z-index: 3; /* 保证内容文字在图片之上 */
      }
      img{
      width:90%;
      height: 100%;
      border-radius: 10px;
      margin-left: 4.5vw;
    }
    }
    .content-photo3{
      position: relative;
      margin-right:20px;
      .photo-text{
        position:absolute;
        top: 70px; /* 距离顶部的距离，调整为你想要的值 */
        left: 70px; /* 距离左侧的距离，调整为你想要的值 */
        font-size: 1.8rem; /* 可以根据需要调整字体大小 */
        font-weight: bold; /* 标题加粗 */
        color: black; /* 设置文字颜色为白色，使其在图片上更为明显 */
        z-index: 4; /* 保证标题在图片之上 */
        transition: font-size 0.3s ease; /* 添加平滑过渡效果 */
      }
      .photo-text:hover {
       font-size: 2rem;
      }
      .subphoto-text {
      position: absolute;
      top: 140px; /* 距离顶部的距离，放在标题下方，调整为你想要的值 */
      left: 70px; /* 距离左侧的距离 */
      right: 50px;
      font-size: 1.4rem; /* 内容文字的字体大小 */
      color: black; /* 设置文字颜色为白色 */
      z-index: 3; /* 保证内容文字在图片之上 */
      }
      img{
      width:90%;
      height: 100%;
      border-radius: 8px;
      margin-left: 35px;
    }
      }
    }
    // img{
    //   width:100%;
    //   height: 100%;
    //   border-radius: 10px;
    // }
//     .content-item1{
//       width: 100%;
//       height: 550px;
//       background-position: center ;
//       background-size:contain;
//       border-radius:10px;
//       background-repeat: no-repeat;
//       background-image: url('../assets/new/tuandui.png');
//       position: relative; /* 使内容可以在此容器内绝对定位 */
//     }
//       .content-title {
//         position: absolute; /* 绝对定位 */
//         top: 10%; /* 可以调整，控制文字距顶部的位置 */
//         left: 5%; /* 可以调整，控制文字距左侧的位置 */
//         color: black; /* 设置文字颜色 */
//        z-index: 3; /* 确保文字在图片之上 */
// }
//     .content-title-text {
//     font-size: 2vw; /* 调整字体大小 */
//     font-weight: bold;
//     }

//    .sub-title {
//      margin-top: 10px;
//      font-size: 1.5vw;
//      color: black;
// }

// .sub-title-text {
//   line-height: 1.4;
// }

    // .content-item2{
    //   width: 48%;
    //   height: 400px;
    //   background-position: center ;
    //   background-size:contain;
    //   border-radius:10px;
    //   background-repeat: no-repeat;
    //   background-image:url('../assets/new/quanqiu.png');
    // }
    // .content-item3{
    //   width: 48%;
    //   height: 400px;
    //   background-position: center ;
    //   background-size:contain;
    //   border-radius:10px;
    //   background-repeat: no-repeat;
    //   background-image:url('../assets/new/技术.png');
    // }
  }

  }

  .screennews5{
    width: 100%;
    height: fit-content;
    padding: 0.1vh 4.09763142692085vw 3vh 4.09763142692085vw;
    background-color: white;
    // background-color: #f8f8f8;
    position: relative;
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('../assets/new/关于我们背景.png');
    .content-containernews5{
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .content-itemnew1{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 4vw;
        width: 90%;
    .photo-1{
       width: 45%;
       height: 300px;
      img{
        border-radius: 10px;
      }
      }
      .title-content{
         flex: 2;
        margin-left: 20px;
      .main-title{
                font-size: 1.3vw;
                line-height: 1.8;
                margin-bottom: 3vh;
                // color: #15257b;
                color:#123456;
                // color:black;
                font-weight: bold; /* 加粗字体 */
                transition: font-size 0.3s ease; /* 添加平滑过渡效果 */
      }
      .main-title:hover {
        font-size: 1.5vw;
      }
      .sub-text{
                font-size: 1.2vw;
                line-height: 1.8;
                // color: #15257b;
                color: #123456;
                // color:black;
                margin-bottom: 4vh;
      }

      .item1111 {
       position: relative;
      padding-left: 30px;  /* 给文字左边留出空间 */
       }

       .item1111::before {
        content: '';
        position: absolute;
        left: 0;
         top: 50%;
         transform: translateY(-50%);
        width: 10px;
        height: 10px;
       background-color:  #15257b;;  /* 圆圈的颜色 */
        border-radius: 50%;
}
    }

    }
    .content-itemnew2{
      display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 4vw;
        width: 90%;
        .photo-1{
       width: 45%;
       height: 300px;
       img{
        border-radius: 10px;
      }
      }
      .title-content{
         flex: 1;
        //  margin-left:5px;
      .main-title{
                font-size: 1.3vw;
                line-height: 1.8;
                margin-bottom: 3vh;
                color: #15257b;
                // color: #000000;
                font-weight: bold; /* 加粗字体 */
                transition: font-size 0.3s ease; /* 添加平滑过渡效果 */
      }
      .main-title:hover {
        font-size: 1.5vw;
      }
      .sub-text{
                font-size: 1.2vw;
                line-height: 1.8;
                color: #15257b;
                // color:#000000;
                margin-bottom: 4vh;
      }

      .item1111 {
       position: relative;
      padding-left: 30px;  /* 给文字左边留出空间 */
       }

       .item1111::before {
        content: '';
        position: absolute;
        left: 0;
         top: 50%;
         transform: translateY(-50%);
        width: 10px;
        height: 10px;
       background-color:  #15257b;;  /* 圆圈的颜色 */
        border-radius: 50%;
}
    }
    }
    .content-itemnew3{
        display: flex;
        justify-content: space-between;
        // align-items: center;
        gap: 4vw;
        width: 100%;
        .photo-1{
       width: 45%;
       height: 280px;
       img{
        border-radius: 10px;
      }
      }
      .title-content{
         flex: 1;
         width: 100%;
         margin-left: 20px;
         display: flex;
         border: 1px solid #E1E1E1; /* 1px 宽度，实线，浅灰色边框 */
         box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* 更重的阴影效果 */
         border-radius: 9px;
         padding: 0.3vh 1vw;
         background-color: rgba(18,52,86,1);
        //  align-items: center;
         .section {
             width: 100%;
            padding: 2vh 1vw;
            height:fit-content;
            display:flex;
            flex-direction: column;
            align-items: center;
         }
          .vertical-line {
          width: 2px;
          background-color: rgba(255,255,255,1); /* 竖线颜色 */
          box-shadow: 0 0 5px rgba(0, 0, 0, 1); /* 为竖线添加明显的阴影 */
          margin: 1vh 2vw; /* 设置竖线的间距 */
}
      .main-title{
               font-size: 1.5vw;
               font-weight: 400;
                line-height: 1.8;
                margin-bottom: 2vh;
                // color: #15257b;
                // color:#123456;
                color:white;
                // color:black;
                transition: font-size 0.3s ease; /* 添加平滑过渡效果 */
      }
      .main-title:hover {
        font-size: 1.5vw;
      }
      .sub-text{
                font-size: 1vw;
                font-weight: 300;
                line-height: 1.8;
                // color: #15257b;
                // color:#123456;
                color:white;
                // color:black;
                // margin-bottom: 4vh;
      }
      .item1111 {
       position: relative;
      // padding-left: 30px;  /* 给文字左边留出空间 */
       }

//        .item1111::before {
//         content: '';
//         position: absolute;
//         left: 0;
//          top: 50%;
//          transform: translateY(-50%);
//         width: 10px;
//         height: 10px;
//        background-color:  #15257b;;  /* 圆圈的颜色 */
//         border-radius: 50%;
// }

    }
    }

    .content-itemnew4{
      display:grid;
      grid-template-columns: minmax(0, 1fr)  minmax(0, 1fr)  minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 3vw;
      width: 100%;
      margin-top: 4vh;
      position: relative; /* 为子元素定位提供参考 */
    }
      .item4{
        flex: 1;
      padding: 3vh 2vw;
      // background: white;
      background-color: transparent;
      border-radius: 8px;
      // box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
      transition: transform 0.3s ease;
      position: relative;   }
        .item4-title{
          color: #15257b;
         font-size: 4.7vw;
         font-weight: 600;
         text-align: center;
         transition: font-size 0.3s ease; /* 添加平滑过渡效果 */
        }
        .item4-title:hover {
          font-size: 5vw; /* 悬浮时字体变大 */
        }

      .Know-more{
        margin-top: 4vh;
        position: absolute;
      }
      .know-btn {
                    background: none;
                    border: none;
                    color: blue;
                    font-size: 1.1vw;
                    font-weight: 600;
                    padding: 0;
                    cursor: pointer;
                    transition: color 0.3s;
                    margin-top: 7vh ;
                    margin-left: 81vw;
                    &:hover {
                        color: darken(#15257b, 10%);
                    }
                }
  }
  .screennews {
    width: 100vw;
    height: fit-content;
    padding: 7vh 4.09763142692085vw 5vh 4.09763142692085vw;
    background-color: white;
    // background-color: #f8f8f8;
    position: relative;
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../assets/new/关于我们背景.png");
    // border: 2px solid #cccccc;

    .title-container {
        margin-bottom: 5vh;

        .title {
            color: #15257b;
            // font-size: 3vw;
            // font-weight: 700;
            font-size: 60px;
            font-weight: 400;
            text-align: center;
            margin: 0;
            line-height: 1.2;
        }
    }
    .zong-content{
      display:flex;
    }
    .sub-content1{
     height:100%;
     border: 1px solid #E1E1E1; /* 1px 宽度，实线，浅灰色边框 */
     box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* 更重的阴影效果 */
     border-radius: 9px;
     width:30vw;
     background-color: rgba(255,255,255,1);
    }
    .photo-111{
      width: 100%;
      height: fit-content;
      margin-left:30px;
      margin-top:10px;
      img{
        width: 50%;
        height: 50%;
      }
    }
    .photo-222{
      width: 100%;
      height: fit-content;
      margin-top:14vh;
      img{
        width: 80%;
        height: 80%;
      }
      .item4-text{
          text-align: center;
          // color: #15257b;
          color: #123456;
          font-size: 2vw;
          margin-bottom:2vh;
          font-weight:300;
        }
        .item4-title{
          font-size:9vw;
          color:#123456;
          font-weight:400;
          font-family: 'Noto Sans CJK SC', sans-serif; /* 字体设置为 Noto Sans CJK SC */
          margin-left: 2.8vw;
        }
        .item4-title .large-number {
        font-size: 10vw;  /* 设置 10 的字体大小比 K 更大 */
        font-weight:300;
        font-family: 'Noto Sans CJK SC', sans-serif; /* 字体设置为 Noto Sans CJK SC */
          }
        .item4-title .plus {
            font-size: 4vw;  /* 调整 + 的大小 */
            vertical-align: super;  /* 设置为上标对齐 */
            position: relative;
            top: -0.9em;  /* 微调位置，确保 + 符号位于右上角 */
            left:-0.5em;  /* 微调位置，确保 + 符号位于右上角 */
}
    }
    /* 媒体查询：适应小屏幕设备（例如手机） */
// @media  (max-width: 768px) {
//   .sub-content1 {
//     width: 90vw; /* 更宽的容器，适应小屏设备 */
//     height: 70vh; /* 更小的高度 */
//   }

//   .photo-111 {
//     margin-left: 10px;
//     margin-top: 5px;
//   }

//   .photo-111 img {
//     width: 100%; /* 图片宽度适应父容器 */
//     height: auto; /* 自动调整高度 */
//   }

//   .photo-222 {
//     margin-top: 10vh;
//     margin-left: 15px;
//   }

//   .photo-222 img {
//     width: 100%; /* 图片宽度适应父容器 */
//     height: auto; /* 自动调整高度 */
//   }

//   .item4-text {
//     font-size: 5vw; /* 更小的字体 */
//     margin-left: 5vw; /* 更大的左边距 */
//   }

//   .item4-title {
//     font-size: 50px; /* 更小的标题字体 */
//     margin-left: 5vw;
//   }

//   .item4-title .large-number {
//     font-size: 80px; /* 更小的数字 */
//   }

//   .item4-title .plus {
//     font-size: 10vw; /* 更小的加号 */
//   }
// }
    .sub-content2{
      height:100%;
      border: 1px solid #E1E1E1; /* 1px 宽度，实线，浅灰色边框 */
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* 更重的阴影效果 */
     border-radius: 9px;
     width:30vw;
     margin-left:2.1vw;
     background-color: rgba(18,52,86,1);
    }
    .photo-111{
      width: 100%;
      height: fit-content;
      margin-left:30px;
      margin-top:10px;
      img{
        width: 50%;
        height: 50%;
      }
    }
    .photo-555{
      width: 100%;
      height: fit-content;
      margin-top:14vh;
      img{
        width: 80%;
        height: 80%;
      }
    .item4-text{
          text-align: center;
          // color: #15257b;
          // color: #123456;
          color:white;
          font-size: 2vw;
          font-weight:300;
          margin-bottom:1.5vh;
        }
        .item4-title{
          font-size:9vw;
          // color:#123456;
          color:white;
          font-weight:400;
          font-family: 'Noto Sans CJK SC', sans-serif; /* 字体设置为 Noto Sans CJK SC *
        }
        .item4-title .large-number {
        // font-size: 11vw;  /* 设置 10 的字体大小比 K 更大 */
        font-size: 10vw;  /* 设置 10 的字体大小比 K 更大 */
        font-weight:300;
        font-family: 'Noto Sans CJK SC', sans-serif; /* 字体设置为 Noto Sans CJK SC */
          }
        .item4-title .plus {
            font-size: 4vw;  /* 调整 + 的大小 */
            vertical-align: super;  /* 设置为上标对齐 */
            position: relative;
            top: -0.9em;  /* 微调位置，确保 + 符号位于右上角 */
            left: 0.1em;  /* 微调位置，确保 + 符号位于右上角 */
}
      }
    .sub-content3{
      height:49%;
      border: 1px solid #E1E1E1; /* 1px 宽度，实线，浅灰色边框 */
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* 更重的阴影效果 */
      border-radius: 9px;
      width:30vw;
      margin-left:2.1vw;
      margin-bottom:1vh;
      background-color: rgba(255,255,255,1);
    }
    .photo-333{
      width: 100%;
      height: fit-content;
      margin-top:7vh;
      margin-left:25px;
      img{
        width: 80%;
        height: 80%;
      }
      .item4-text{
          text-align: center;
          // color: #15257b;
          color: #123456;
          font-size: 2vw;
          font-weight:300;
        }
        .item4-title{
          font-size:9vw;
          color:#123456;
          font-weight:400;
          font-family: 'Noto Sans CJK SC', sans-serif; /* 字体设置为 Noto Sans CJK SC */
        }
        .item4-title .large-number {
        // font-size: 11vw;  /* 设置 10 的字体大小比 K 更大 */
        font-size: 9vw;  /* 设置 10 的字体大小比 K 更大 */
        font-weight:300;
        font-family: 'Noto Sans CJK SC', sans-serif; /* 字体设置为 Noto Sans CJK SC */
          }
        .item4-title .plus {
            font-size: 4vw;  /* 调整 + 的大小 */
            vertical-align: super;  /* 设置为上标对齐 */
            position: relative;
            top: -0.8em;  /* 微调位置，确保 + 符号位于右上角 */
            left: 0.1em;  /* 微调位置，确保 + 符号位于右上角 */
}
    }

        .sub-content4{
      height:49.5%;
      border: 1px solid #E1E1E1; /* 1px 宽度，实线，浅灰色边框 */
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* 更重的阴影效果 */
      border-radius: 9px;
      width:30vw;
      margin-left:2.1vw;
      background-color: rgba(255,255,255,1);
    }
    .photo-444{
      width: 100%;
      height: fit-content;
      margin-top:7vh;
      margin-left:25px;
      img{
        width: 80%;
        height: 80%;
      }
      .item4-text{
          text-align: center;
          // color: #15257b;
          color: #123456;
          font-size: 2vw;
          // margin-left:0.5vw;
          font-weight:300;
        }
        .item4-title{
          font-size: 9vw;
          color:#123456;
          font-weight:400;
          font-family: 'Noto Sans CJK SC', sans-serif; /* 字体设置为 Noto Sans CJK SC */
        }
        .item4-title .large-number {
        // font-size: 11vw;  /* 设置 10 的字体大小比 K 更大 */
        font-size: 9vw;  /* 设置 10 的字体大小比 K 更大 */
        font-weight:300;
        font-family: 'Noto Sans CJK SC', sans-serif; /* 字体设置为 Noto Sans CJK SC */
          }
        .item4-title .plus {
            font-size: 4vw;  /* 调整 + 的大小 */
            vertical-align: super;  /* 设置为上标对齐 */
            position: relative;
            top: -0.8em;  /* 微调位置，确保 + 符号位于右上角 */
            left: 0.1em;  /* 微调位置，确保 + 符号位于右上角 */
}
    }


    .content-itemnew4{
      display:grid;
      grid-template-columns: minmax(0, 1fr)  minmax(0, 1fr)  minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 3vw;
      width: 100%;
      margin-top: 4vh;
      .item4{
        flex: 1;
      padding: 3vh 2vw;
      // background: white;
      background-color: transparent;
      border-radius: 8px;
      // box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
      transition: transform 0.3s ease;
      position: relative;
        .item4-title{
          color: #15257b;
         font-size: 4.7vw;
         font-weight: 600;
         text-align: center;
         transition: font-size 0.3s ease; /* 添加平滑过渡效果 */
        }
        .item4-title:hover {
          font-size: 4.9vw; /* 悬浮时字体变大 */
        }
        .item4-text{
          text-align: center;
          color: #15257b;
          font-size: 1.3vw;
        }
      }
      .Know-more{
        margin-top: 4vh;
        position: absolute;
      .know-btn {
                    background: none;
                    border: none;
                    color: blue;
                    font-size: 1.1vw;
                    font-weight: 600;
                    padding: 0;
                    cursor: pointer;
                    transition: color 0.3s;
                    margin-top: 7vh ;
                    margin-left: 81vw;
                    &:hover {
                        color: darken(#15257b, 10%);
                    }
                }
              }
    }
    .content-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 4vw;
       margin-top:1.5vh;
       border: 1px solid #E1E1E1; /* 1px 宽度，实线，浅灰色边框 */
       box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* 更重的阴影效果 */
       border-radius: 9px;
       background-color: rgba(255,255,255,1);
        .text-content {
            flex: 1;
            .text-title{
              font-size:  1.8vw;
              font-weight:bold;
              margin-top:3vh;
              margin-left:2vw;
            }
            .main-description {
                font-size: 1.2vw;
                line-height: 1.8;
                margin-bottom: 3vh;
                // color: #000000;
                color: #555555;
                margin-top:3vh;
                margin-left:2vw;
            }

            .sub-description {
                font-size: 1.2vw;
                line-height: 1.8;
                // color: #000000;
                color: #616161;
                margin-bottom: 4vh;
                margin-left:2vw;
            }

            .discover-more {
                margin-top: 2vh;

                .discover-btn {
                    background: none;
                    border: none;
                    color: #15257b;
                    font-size: 1.1vw;
                    font-weight: 600;
                    padding: 0;
                    cursor: pointer;
                    transition: color 0.3s;

                    &:hover {
                        color: darken(#15257b, 10%);
                    }
                }
            }
        }

        .video-container {
            width: 50%;
            min-width: 30vw;
            margin-top:1.5vh;
            margin-right:0.5vw;
            video {
                width: 99%;
                height: 100%;
                // border-radius: 0.5vw;
            }
        }
    }

    @media (max-width: 768px) {
        padding: 8vh 5vw;
        .title {
            font-size: 6vw;
        }

        .content-container {
            flex-direction: column;
            gap: 4vh;

            .text-content {
                .main-description,
                .sub-description {
                    font-size: 3.5vw;
                }

                .discover-btn {
                    font-size: 3vw;
                }
            }

            .video-container {
                width: 100%;
                min-width: unset;
            }
        }
    }
}
.screennews1 {
  width: 100vw;
  height: fit-content;
  padding: 9vh 11.09763142692085vw 13vh 8.09763142692085vw;
  background-color: #f8f8f8;
  position: relative;
  background-position: left;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  .platform-intro {
    margin-bottom: 6vh;
  }

  .main-title {
    font-size: 2vw;
    margin-bottom: 2vh;
    color: #15257b;
    font-weight: 700;
    line-height: 1.4;

    .highlight-red {
      color: #ff0000;
    }
  }

  .sub-title {
    font-size: 1.5vw;
    color: #15257b;
    line-height: 4vh;
    margin-top: 2vh;
  }

  .research-institutes {
    display: flex;
    justify-content: space-between;
    gap: 3vw;
    margin-top: 6vh;

    .institute-item {
      flex: 1;
      padding: 3vh 2vw;
      background: white;
      border-radius: 8px;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
      transition: transform 0.3s ease;

      // &:hover {
      //   transform: translateY(-5px);
      // }

      .institute-title {
        color: #15257b;
        font-size: 1.8vw;
        font-weight: 600;
        margin-bottom: 3vh;
        line-height: 1.4;
        min-height: 8vh;
      }

      .institute-list {
        list-style: none;
        padding: 0;
        text-align: left;

        li {
          margin: 1.5vh 0;
          padding-left: 1.5vw;
          position: relative;
          font-size: 1vw;
          color: #000000;
          line-height: 1.6;

          &:before {
            content: "•";
            color: #15257b;
            position: absolute;
            left: 0;
            font-size: 1.4vw;
            line-height: 1.4vw;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .research-institutes {
      flex-direction: column;
      gap: 4vh;
    }

    .main-title {
      font-size: 5vw;
    }

    .sub-title {
      font-size: 3vw;
    }

    .institute-item {
      .institute-title {
        font-size: 3.5vw;
      }

      .institute-list li {
        font-size: 2.5vw;
      }
    }
  }
}

  .screenGet {
    //z-index: 10;
    position: relative;
    width: 100vw;
    height: fit-content;
    //border-radius: 9px;
    padding: 13vh 11.09763142692085vw 13vh 8.09763142692085vw;
    background-image: url("../assets/getBack.png");
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    ::v-deep img {
      object-fit: contain;
    }
    .title {
      height: fit-content;
      margin: 0 auto;
      text-align: center;
      opacity: 1;
      font-size: 3vw;
      color: white;
      font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 700;
      line-height: 10vh
    }

    .light {
      margin-top: 3vh;
      text-align: left;
      width: 50vw;
      height: fit-content;
      font-size: 1.8vw;
      font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 400;
      color: #15257b;
      line-height: 6vh;
    }

    .pro2 {
      height: fit-content;
      padding-top: 12vh;
      display: grid;
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      overflow-x: hidden;
      //z-index: 89156;

      .grand1 {
        width: 100%;
        padding: 1vh 1vw 2vh 1vw;
        opacity: 1;
        position: relative;
        height: fit-content;

        .back {
          width: 100%;
          //margin:-8vh auto 0 0;
          height: 100%;
          border-radius: 9px;
          // border-radius:6px;
          // display: flex;
        }

        .back1 {
          width: 100%;
          height: fit-content;

          border-top: none;
          border-bottom-right-radius: 9px;
          border-bottom-left-radius: 9px;
          // border-radius:6px;
          padding: 1vh 0;
          justify-content: space-between;
        }

        .photo {
          //background-image: url("../assets/pro1.jpg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 6vw;
          display: flex;
          margin: 0 auto;
          height: 6vw;
          position: relative;
        }

        .photohover {
          position: absolute;
          bottom: 0;
          left: 0; /* 初始位置在容器左侧外面 */
          width: 0%; /* 初始宽度为0 */
          height: 2vh;
          background-color: #2f6dfd;
          transition: width 0.5s ease; /* 平滑过渡效果 */
        }

        .photolist {
          font-size: 4.5vw;
          color: #e3e8ed;
          margin: auto 1vw 0 1vw;
          height: 100%;
          font-weight: bold;
        }

        .title1 {
          margin-top: 4vh;
          text-align: center;
          font-size: 1.5vw;
          font-family: Roboto, sans-serif;
          font-weight: bold;
          color: white;
          overflow: hidden;
          height: fit-content;
          line-height: 5.5vh;
        }

        .title2 {
          margin-top: 4vh;
          text-align: center;
          font-size: 1.3vw;
          font-family: Roboto, sans-serif;
          font-weight: bold;
          color: white;
          overflow: hidden;
          height: fit-content;
          line-height: 4.5vh;
        }
      }

      .grand1:hover {
        .photohover {
          width: 100%; /* 鼠标悬浮时宽度变为100% */
        }
      }

    }
    .buttongroup{
      width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    }
    .about-us{
      background-color: #007bff;
    color: white;
    border: none;
    padding: 15px 30px;
    font-size: 1.2rem;
    border-radius: 30px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    position: absolute;
    right: 6vw;
    bottom: 3vh;
    }
    .about-us:hover{
      background-color: #0056b3;
      transform: scale(1.05);
    }
    //.pro2 {
    //  height: fit-content;
    //  width: 100%;
    //  margin-top: 10vh;
    //  //z-index: 89156;
    //  display: grid;
    //  grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
    //  row-gap:10vh;
    //  column-gap: 1vw;
    //  .grand1 {
    //    cursor: pointer;
    //    width: 100%;
    //    border-bottom-right-radius: 53px;
    //    border-top-left-radius: 9px;
    //    border-top-right-radius: 9px;
    //    border-bottom-left-radius: 9px;
    //
    //    padding: 1vh 1vw;
    //    opacity: 1;
    //    position: relative;
    //    background-image: url("../assets/groundBack.png");
    //    background-position: left;
    //    background-size: cover;
    //    background-repeat: no-repeat;
    //    background-color: transparent;
    //    height: fit-content;
    //    .back {
    //      width: 100%;
    //      margin:-8vh auto 0 0;
    //      height:fit-content;
    //      border-radius: 9px;
    //      // border-radius:6px;
    //      // display: flex;
    //    }
    //
    //    .back1 {
    //      width: 100%;
    //      height:fit-content;
    //      margin-top:1vh;
    //      border-top: none;
    //      border-bottom-right-radius: 9px;
    //      border-bottom-left-radius: 9px;
    //      // border-radius:6px;
    //      padding: 1vh 1vw;
    //      justify-content: space-between;
    //    }
    //
    //    .photo {
    //      position: relative;
    //      //background-image: url("../assets/pro1.jpg");
    //      background-size: cover;
    //      background-position: center;
    //      background-repeat: no-repeat;
    //      background-color: transparent; /* 这里设置背景颜色为透明 */
    //      opacity: 1.25;
    //      width: 12vw;
    //      display: flex;
    //      margin:-5vh 0  0 5%;
    //      border-radius: 9px;
    //      height: fit-content;
    //    }
    //    .photohover{
    //      position: absolute;
    //      bottom: 0;
    //      left: 0; /* 初始位置在容器左侧外面 */
    //      width: 0%; /* 初始宽度为0 */
    //      height: 2vh;
    //      background-color: #2f6dfd;
    //      transition: width 0.5s ease; /* 平滑过渡效果 */
    //    }
    //    .photolist {
    //      font-size: 5vw;
    //      color: #e3e8ed;
    //      margin:auto 0 0 2.5vw;
    //      height: 100%;
    //      font-weight: bold;
    //    }
    //
    //    .title1 {
    //      text-align: left;
    //      font-size: 1vw;
    //      font-family: Roboto, sans-serif;
    //      font-weight: bold;
    //      width: 60vw;
    //      overflow: hidden;
    //      height: fit-content;
    //      //line-height: 35px;
    //    }
    //
    //    .title2 {
    //      display: flex;
    //
    //      opacity: 4;
    //      width: fit-content;
    //      height: fit-content;
    //      // text-align: justify;
    //      font-size: 0.9vw;
    //      font-family: Roboto, sans-serif;
    //      font-weight: 500;
    //      color: white;
    //      margin-top: 2vh;
    //      border-radius: 23px;
    //      padding: 5px 15px;
    //      background-color: #2168DB;
    //      //line-height: 35px;
    //    }
    //  }
    //  .grand1:hover{
    //    .photohover {
    //      width: 100%; /* 鼠标悬浮时宽度变为100% */
    //    }
    //  }
    //
    //}

  }
  .screenSupport {
    //z-index: 10;
    width: 100vw;
    height: fit-content;
    //border-radius: 9px;
    padding: 18vh 11.09763142692085vw 18vh 15vw;
    position: relative;
    background-image: url("../assets/supportBack.png");
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    display: flex;
    .photo{
      top:6vh;
      width: 40vw;
      position: absolute;
      right:8vw;
      //height:55vh;
    }
    .title {

      height: fit-content;
      opacity: 1;
      font-size:3vw;
      color: white;
     font-family: Roboto, sans-serif;
      font-weight: 700;
      line-height: 8vh;
    }

    .light {
      margin-top: 3vh;
      text-align: left;
      width: 50vw;
      height: fit-content;
      font-size: 1.5vw;
     font-family: Roboto, sans-serif;
      font-weight: 400;
      color: white;
      line-height: 6vh;
    }
    .light2 {
      margin-top: 10vh;
      text-align: left;
      width: 30vw;
      height: fit-content;
      font-size: 1.3vw;
     font-family: Roboto, sans-serif;
      font-weight: 400;
      color: white;
      display: grid;
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);

      line-height: 5vh;
    }
    .login{
      width: fit-content;
      margin : 0 auto;
      display: grid;
      grid-template-columns: minmax(0,1fr) minmax(0,1fr);
      column-gap: 3vw;
      font-size: 1.3vw;
      .loginUp{
        cursor: pointer;
        padding:1vh 3vw;
        border:1px solid #192079;
        color:#192079;
        border-radius: 53px;
      }
      .loginUp:hover{
        background-color: #e0dddd;
      }
      .loginIn{
        cursor: pointer;
        padding:1vh 3vw;
        background-color:#192079;
        color: white;
        border-radius: 53px;
      }
      .loginIn:hover{
        background-color:  #777996;
      }
    }
    .pro3 {
      //margin-top: -4vh;
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr)  minmax(0, 1fr);
      column-gap: 0;
      row-gap: 2vh;

      .grand2 {
        cursor: pointer;
        width: 100%;

        height:fit-content;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.39324090121317vw;
        opacity: 1;

        .back {
          transition: transform 0.5s ease;
          width: 100%;
          position: relative;
          height: 100%;
          background: white;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
        }
        .photo {
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: #fff; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 0 auto auto auto;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: 35vh;
        }


        .title1 {
          padding: 5vh 0;
          opacity: 4;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 2.71940667490729vh;
          font-size: 1.17094165222415vw;
         font-family: Roboto, sans-serif;
          font-weight: bold;
          color: #2168DB;
          line-height: 3.5vh;
        }

        .title2 {
          opacity: 4;
          margin: 1.94437577255871vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;
          // //text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size:0.9vw;
         font-family: Roboto, sans-serif;
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .consult {
          position: absolute;
          display: none;
         font-family: Roboto, sans-serif;
          font-weight: 500;
          font-size:0.9vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand2:hover {
        .back {
          transform: translateY(-10px);

        }

      }
    }



  }
  .screenConsult1 {
    //z-index: 10;
    width: 100vw;
    height: fit-content;
    border-radius: 9px;
    padding: 5vh 11.09763142692085vw 5vh 8.09763142692085vw;
    background: transparent;


    .title {

      height: 5vh;
      opacity: 1;
      font-size: 2vw;
      color: #15257b;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 3vh
    }

    .light {
      margin:3vh auto 4vh auto;
      text-align: justify;
      width: 66vw;
      height: fit-content;
      font-size: 1.5vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #15257b;
      line-height: 4.94437577255871vh;

    }

    .pro2 {
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 0;
      row-gap: 0;

      .grand1 {
        transition: transform 0.5s ease;
        cursor: pointer;
        width: 100%;
        min-height: fit-content;
        //padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.19324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          transition: transform 0.5s ease;
          width: 100%;
          height: 100%;
          min-height: 20vh;
          //background: white;;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          //border: 2px solid #FFFFFF;
          border-radius: 9px;
          display: flex;
        }

        .photo {
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 3vh 0 2vh 0.8vw;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          border-radius: 9px;
          height: fit-content;
        }

        .titleAligen {
          position: absolute;
          left: 1.5vw;
          top: 3vh;
          text-align: justify;
          width: 90%;
        }

        .title1 {
          opacity: 4;
          margin: 3.22496909765142vh 0.85540150202195vw 1.22496909765142vh 0.85540150202195vw;;
          width: 25vw;

          height: 3vh;
          font-size: 1vw;
          white-space: nowrap; //换行
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: bold;
          overflow: hidden;
          text-overflow: ellipsis;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .title2 {
          opacity: 4;
          margin: 0.94437577255871vh 0.85540150202195vw 0.94437577255871vh 1.54437577255871vh;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 4; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 0.9vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          //color: #2168DB;
          line-height: 3.5vh;
          height: fit-content;
        }

        .consult {
          display: none; /* Hide by default */
          position: absolute;
          left: -100%; /* Start off-screen to the left */
          transition: left 5s ease; /* Animate the left property */
          padding: 2.5vh 1.5vw;
          color: white;
          border-bottom-left-radius: 9px;
          border-bottom-right-radius: 9px;
          height: 8vh;
          width: 95.5%;
          background: url("../assets/froundHover.png");
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          font-family: Arial, sans-serif, "Microsoft Yahei"; /* Corrected typo 'Sarial' */
          font-weight: 500;
          font-size: 0.9vw;
          bottom: 1vh;

        }
      }

      .grand1:hover .consult {
        display: block; /* Show on hover */
        left: 1vw; /* Slide into view */

      }

    }


  }

  .screen3 {
    //z-index: 10;
    width: 100vw;
    height: fit-content;
    //border-radius: 9px;
    padding: 5vh 11.09763142692085vw 5vh 8.09763142692085vw;
    background: white;


    .title {

      height: 5vh;
      opacity: 1;
      text-align: center;
      margin: 0 auto;
      font-size: 2vw;
      color: #15257b;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 3vh
    }

    .light {
      margin: 0 auto 4vh auto;
      text-align: justify;
      width: 66vw;
      height: fit-content;
      font-size: 1.5vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #15257b;
      line-height: 4.94437577255871vh;

    }


    .pro2 {

      border-radius: 9px;
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 0;

      .grand2 {
        transition: transform 0.5s ease;
        border-radius: 9px;
        cursor: pointer;
        width: 100%;
        min-height: 30.65636588380717vh;
        padding: 0.98887515451174vh 0.5vw 0.98887515451174vh 0.5vw;
        opacity: 1;
        position: relative;

        .back {

          width: 100%;
          height: 100%;
          //background: #D4E4FF;;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
        }

        .photo {

          border-radius: 9px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          border: 9px;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: fit-content;
        }

        .title1 {
          position: absolute;
          left: 5vh;
          top: 5vh;
          opacity: 4;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 2.71940667490729vh;
          font-size: 1.2vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: bold;
          color: #2168DB;
          line-height: 2.71940667490729vh;
        }


      }

      .grand2:hover {

        transform: translateY(-10px);

      }
    }


  }

  .screen8 {
    //z-index: 10;
    width: 100vw;
    height: fit-content;
    //border-radius: 9px;
    padding: 5vh 11.09763142692085vw 5vh 8.09763142692085vw;
    background: white;

    .title {

      text-align: center;
      height: 5vh;
      opacity: 1;
      font-size: 2vw;
      color: #15257b;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 3vh
    }

    .light {
      margin: 0 auto 4vh auto;
      text-align: justify;
      width: 66vw;
      height: fit-content;
      font-size: 1.5vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #15257b;
      line-height: 4.94437577255871vh;

    }

    .pro2 {
      transition: transform 0.5s ease;
      height: fit-content;
      width: 100%;
      z-index: 89156;
      //padding: 0 11.09763142692085vw 0 8.09763142692085vw;
      display: grid;
      grid-template-columns:minmax(0, 1fr);
      //column-gap: 1vw;
      row-gap: 2vh;
    }

    .pro2:hover {
      transform: translateY(-10px);
    }

  }

  ::v-deep .el-tooltip__content {
    font-size: 1.2vw !important; /* 你想要的字体大小 */
  }


}

.totalModel {
  background: #bed8f9;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
  position: relative;

  .research{
    background-image: url("../assets/image (5).png");
  }
  .about{
    background-image: url("../assets/image 29.png");
    background-size: cover;
  }
  .map {
    padding: 0 10px 20px 10px;

    .title {
      padding: 20px 0 10px 0;
      height: fit-content;
      opacity: 1;
      font-size: 20px;
      text-align: center;
      //color: #53A3F7;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 20px;
    }

    .light {
      margin: 0px auto 0 auto;
      text-align: justify;
      width: 98%;
      height: fit-content;
      font-size: 14px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #2C3E50;
      line-height: 22px;

    }
  }
  .screenFace {
    //z-index: 10;
    width: 100vw;
    height: fit-content;
    //border-radius: 9px;
    //background-image: url("../assets/faceBack.png");
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: white;
    padding: 0 10px 20px 10px;
    ::v-deep img{
      object-fit: contain;
    }
    .title {
      padding: 20px 0 10px 0;
      height: fit-content;
      opacity: 1;
      font-size: 20px;
      text-align: center;
      color: white;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 20px;
    }

    .light {
      margin: 0px auto 0 auto;
      text-align: justify;
      width: 98%;
      height: fit-content;
      font-size: 14px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #2C3E50;
      line-height: 22px;

    }

    .pro2 {
      height: fit-content;
      margin-top: 15px;
      overflow-x: hidden;
      //z-index: 89156;

      .grand1 {
        width: 100%;
        padding: 1vh 1vw 2vh 1vw;
        opacity: 1;
        position: relative;
        display: flex;
        height: fit-content;
        .back {
          width: 45px;
          //margin:-8vh auto 0 0;
          height:45px;
          border-radius: 9px;
          // border-radius:6px;
          // display: flex;
        }

        .back1 {
          width: 100%;
          height:fit-content;
          display: flex;
          border-top: none;
          border-bottom-right-radius: 9px;
          border-bottom-left-radius: 9px;
          // border-radius:6px;
          padding: 0 5%;
          justify-content: space-between;
        }

        .photo {
          //background-image: url("../assets/pro1.jpg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 40px;
          display: flex;
          height: 40px;
          margin-left: 10px;
          position: relative;
        }
        .photohover{
          position: absolute;
          bottom: 0;
          left: 0; /* 初始位置在容器左侧外面 */
          width: 0%; /* 初始宽度为0 */
          height: 2vh;
          background-color: #2f6dfd;
          transition: width 0.5s ease; /* 平滑过渡效果 */
        }
        .photolist {
          font-size: 4.5vw;
          color: #e3e8ed;
          margin:auto 1vw 0 1vw;
          height: 100%;
          font-weight: bold;
        }

        .title1 {
          text-align: left;
          font-size: 16px;
          height:44px ;
          display: flex;
          align-items: center;
          font-family: Roboto, sans-serif;
          font-weight: bold;
          color: white;
          overflow: hidden;
          line-height: 22px;
        }

        .title2 {
          display: flex;
          opacity: 4;
          width: fit-content;
          height: fit-content;
          // text-align: justify;
          font-size: 0.9vw;
          font-family: Roboto, sans-serif;
          font-weight: 500;
          color: white;
          margin-top: 2vh;
          border-radius: 23px;
          padding: 5px 15px;
          background-color: #2168DB;
          //line-height: 35px;
        }
      }
      .grand1:hover{
        .photohover {
          width: 100%; /* 鼠标悬浮时宽度变为100% */
        }
      }

    }

    //.pro2 {
    //  height: fit-content;
    //  width: 100%;
    //  margin-top: 10vh;
    //  //z-index: 89156;
    //  display: grid;
    //  grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
    //  row-gap:10vh;
    //  column-gap: 1vw;
    //  .grand1 {
    //    cursor: pointer;
    //    width: 100%;
    //    border-bottom-right-radius: 53px;
    //    border-top-left-radius: 9px;
    //    border-top-right-radius: 9px;
    //    border-bottom-left-radius: 9px;
    //
    //    padding: 1vh 1vw;
    //    opacity: 1;
    //    position: relative;
    //    background-image: url("../assets/groundBack.png");
    //    background-position: left;
    //    background-size: cover;
    //    background-repeat: no-repeat;
    //    background-color: transparent;
    //    height: fit-content;
    //    .back {
    //      width: 100%;
    //      margin:-8vh auto 0 0;
    //      height:fit-content;
    //      border-radius: 9px;
    //      // border-radius:6px;
    //      // display: flex;
    //    }
    //
    //    .back1 {
    //      width: 100%;
    //      height:fit-content;
    //      margin-top:1vh;
    //      border-top: none;
    //      border-bottom-right-radius: 9px;
    //      border-bottom-left-radius: 9px;
    //      // border-radius:6px;
    //      padding: 1vh 1vw;
    //      justify-content: space-between;
    //    }
    //
    //    .photo {
    //      position: relative;
    //      //background-image: url("../assets/pro1.jpg");
    //      background-size: cover;
    //      background-position: center;
    //      background-repeat: no-repeat;
    //      background-color: transparent; /* 这里设置背景颜色为透明 */
    //      opacity: 1.25;
    //      width: 12vw;
    //      display: flex;
    //      margin:-5vh 0  0 5%;
    //      border-radius: 9px;
    //      height: fit-content;
    //    }
    //    .photohover{
    //      position: absolute;
    //      bottom: 0;
    //      left: 0; /* 初始位置在容器左侧外面 */
    //      width: 0%; /* 初始宽度为0 */
    //      height: 2vh;
    //      background-color: #2f6dfd;
    //      transition: width 0.5s ease; /* 平滑过渡效果 */
    //    }
    //    .photolist {
    //      font-size: 5vw;
    //      color: #e3e8ed;
    //      margin:auto 0 0 2.5vw;
    //      height: 100%;
    //      font-weight: bold;
    //    }
    //
    //    .title1 {
    //      text-align: left;
    //      font-size: 1vw;
    //      font-family: Roboto, sans-serif;
    //      font-weight: bold;
    //      width: 60vw;
    //      overflow: hidden;
    //      height: fit-content;
    //      //line-height: 35px;
    //    }
    //
    //    .title2 {
    //      display: flex;
    //
    //      opacity: 4;
    //      width: fit-content;
    //      height: fit-content;
    //      // text-align: justify;
    //      font-size: 0.9vw;
    //      font-family: Roboto, sans-serif;
    //      font-weight: 500;
    //      color: white;
    //      margin-top: 2vh;
    //      border-radius: 23px;
    //      padding: 5px 15px;
    //      background-color: #2168DB;
    //      //line-height: 35px;
    //    }
    //  }
    //  .grand1:hover{
    //    .photohover {
    //      width: 100%; /* 鼠标悬浮时宽度变为100% */
    //    }
    //  }
    //
    //}

  }
  .screenGet {
    //z-index: 10;
    width: 100vw;
    height: fit-content;
    //border-radius: 9px;
    background-image: url("../assets/getBack.png");
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    padding: 0 10px 20px 10px;
    ::v-deep img{
      object-fit: contain;
    }
    .title {
      padding: 20px 0 10px 0;
      height: fit-content;
      opacity: 1;
      font-size: 20px;
      text-align: center;
      color: white;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 20px;
    }

    .light {
      margin: 0px auto 0 auto;
      text-align: justify;
      width: 98%;
      height: fit-content;
      font-size: 14px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #2C3E50;
      line-height: 22px;

    }

    .pro2 {
      height: fit-content;
      margin-top: 15px;
      overflow-x: hidden;
      //z-index: 89156;

      .grand1 {
        width: 100%;
        padding: 1vh 1vw 2vh 1vw;
        opacity: 1;
        position: relative;
        display: flex;
        height: fit-content;
        .back {
          width: 45px;
          //margin:-8vh auto 0 0;
          height:45px;
          border-radius: 9px;
          // border-radius:6px;
          // display: flex;
        }

        .back1 {
          width: 100%;
          height:fit-content;
          border-bottom-right-radius: 9px;
          border-bottom-left-radius: 9px;
          // border-radius:6px;
          padding: 0 5%;
          justify-content: space-between;
        }

        .photo {
          //background-image: url("../assets/pro1.jpg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 40px;
          display: flex;
          height: 40px;
          margin-left: 10px;
          position: relative;
        }
        .photohover{
          position: absolute;
          bottom: 0;
          left: 0; /* 初始位置在容器左侧外面 */
          width: 0%; /* 初始宽度为0 */
          height: 2vh;
          background-color: #2f6dfd;
          transition: width 0.5s ease; /* 平滑过渡效果 */
        }
        .photolist {
          font-size: 4.5vw;
          color: #e3e8ed;
          margin:auto 1vw 0 1vw;
          height: 100%;
          font-weight: bold;
        }

        .title1 {
          text-align: left;
          font-size: 16px;
          display: flex;
          align-items: center;
          font-family: Roboto, sans-serif;
          font-weight: bold;
          color: white;
          overflow: hidden;
          line-height: 25px;
        }

        .title2 {
          text-align: left;
          font-size: 14px;
          display: flex;
          align-items: center;
          font-family: Roboto, sans-serif;
          font-weight: bold;
          color: white;
          overflow: hidden;
          line-height: 22px;
        }
      }
      .grand1:hover{
        .photohover {
          width: 100%; /* 鼠标悬浮时宽度变为100% */
        }
      }

    }

    //.pro2 {
    //  height: fit-content;
    //  width: 100%;
    //  margin-top: 10vh;
    //  //z-index: 89156;
    //  display: grid;
    //  grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
    //  row-gap:10vh;
    //  column-gap: 1vw;
    //  .grand1 {
    //    cursor: pointer;
    //    width: 100%;
    //    border-bottom-right-radius: 53px;
    //    border-top-left-radius: 9px;
    //    border-top-right-radius: 9px;
    //    border-bottom-left-radius: 9px;
    //
    //    padding: 1vh 1vw;
    //    opacity: 1;
    //    position: relative;
    //    background-image: url("../assets/groundBack.png");
    //    background-position: left;
    //    background-size: cover;
    //    background-repeat: no-repeat;
    //    background-color: transparent;
    //    height: fit-content;
    //    .back {
    //      width: 100%;
    //      margin:-8vh auto 0 0;
    //      height:fit-content;
    //      border-radius: 9px;
    //      // border-radius:6px;
    //      // display: flex;
    //    }
    //
    //    .back1 {
    //      width: 100%;
    //      height:fit-content;
    //      margin-top:1vh;
    //      border-top: none;
    //      border-bottom-right-radius: 9px;
    //      border-bottom-left-radius: 9px;
    //      // border-radius:6px;
    //      padding: 1vh 1vw;
    //      justify-content: space-between;
    //    }
    //
    //    .photo {
    //      position: relative;
    //      //background-image: url("../assets/pro1.jpg");
    //      background-size: cover;
    //      background-position: center;
    //      background-repeat: no-repeat;
    //      background-color: transparent; /* 这里设置背景颜色为透明 */
    //      opacity: 1.25;
    //      width: 12vw;
    //      display: flex;
    //      margin:-5vh 0  0 5%;
    //      border-radius: 9px;
    //      height: fit-content;
    //    }
    //    .photohover{
    //      position: absolute;
    //      bottom: 0;
    //      left: 0; /* 初始位置在容器左侧外面 */
    //      width: 0%; /* 初始宽度为0 */
    //      height: 2vh;
    //      background-color: #2f6dfd;
    //      transition: width 0.5s ease; /* 平滑过渡效果 */
    //    }
    //    .photolist {
    //      font-size: 5vw;
    //      color: #e3e8ed;
    //      margin:auto 0 0 2.5vw;
    //      height: 100%;
    //      font-weight: bold;
    //    }
    //
    //    .title1 {
    //      text-align: left;
    //      font-size: 1vw;
    //      font-family: Roboto, sans-serif;
    //      font-weight: bold;
    //      width: 60vw;
    //      overflow: hidden;
    //      height: fit-content;
    //      //line-height: 35px;
    //    }
    //
    //    .title2 {
    //      display: flex;
    //
    //      opacity: 4;
    //      width: fit-content;
    //      height: fit-content;
    //      // text-align: justify;
    //      font-size: 0.9vw;
    //      font-family: Roboto, sans-serif;
    //      font-weight: 500;
    //      color: white;
    //      margin-top: 2vh;
    //      border-radius: 23px;
    //      padding: 5px 15px;
    //      background-color: #2168DB;
    //      //line-height: 35px;
    //    }
    //  }
    //  .grand1:hover{
    //    .photohover {
    //      width: 100%; /* 鼠标悬浮时宽度变为100% */
    //    }
    //  }
    //
    //}

  }
  .GEGPTs {
    padding: 0 10px 20px 10px;

    .title {
      padding: 20px 0 10px 0;
      height: fit-content;
      opacity: 1;
      font-size: 20px;
      text-align: center;
      //color: #53A3F7;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 20px;
    }

    .light {
      margin: 0px auto 0 auto;
      text-align: justify;
      width: 98%;
      height: fit-content;
      font-size: 14px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #2C3E50;
      line-height: 22px;

    }

    .pro2 {
      height: fit-content;
      width: 100%;
      margin-top: 55px;
      //z-index: 89156;

      .grand1 {
        cursor: pointer;
        width: 100%;
        border-bottom-right-radius: 53px;
        border-top-left-radius: 9px;
        border-top-right-radius: 9px;
        border-bottom-left-radius: 9px;

        padding: 15px 20px 15px 20px;
        opacity: 1;
        position: relative;
        background-image: url("../assets/groundback.png");
        background-position: left;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: transparent;
        height: fit-content;

        .back {
          width: 100%;
          margin: -50px auto 0 0;
          height: fit-content;
          border-radius: 9px;
          // border-radius:6px;
          // display: flex;
        }

        .back1 {

          width: 100%;
          height: fit-content;
          margin-top: 15px;
          border-top: none;
          border-bottom-right-radius: 9px;
          border-bottom-left-radius: 9px;
          // border-radius:6px;
          padding: 0 15px 10px 15px;
          justify-content: space-between;
        }

        .photo {
          //background-image: url("../assets/pro1.jpg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 40vw;
          display: flex;
          margin: -20px 0 0 5%;
          border-radius: 9px;
          height: fit-content;
        }

        .photolist {
          font-size: 55px;
          color: #e3e8ed;
          margin: auto 0 0 15px;
          height: 100%;
          font-weight: bold;
        }

        .title1 {
          text-align: left;
          font-size: 16px;
          font-family: Roboto, sans-serif;
          font-weight: bold;
          width: 60vw;
          overflow: hidden;
          height: fit-content;
          //line-height: 35px;
        }

        .title2 {
          display: flex;

          opacity: 4;
          width: fit-content;
          height: fit-content;
          // text-align: justify;
          font-size: 14px;
          font-family: Roboto, sans-serif;
          font-weight: 500;
          color: white;
          margin-top: 10px;
          border-radius: 23px;
          padding: 5px 15px;
          background-color: #2168DB;
          //line-height: 35px;
        }
      }

    }

    .GEGPTs_back_card {
      height: 96px;
      background-color: #d9e6f6;
      margin: 5px;
      background-image: url("../assets/beijing.png");
      display: flex;

      .card_img {
        height: 30px;
        width: fit-content;
        object-fit: contain;
        padding: 10px;
        margin: auto;
      }

      .title {
        font-size: 15px;
        margin: auto 2px;
        font-weight: bolder;
        padding: 0 2px;
        text-align: left;
      }
    }

  }
  .screenSupport {
    //z-index: 10;
    width: 100vw;
    height: fit-content;
    background-image: url("../assets/supportBack.png");
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    position: relative;
    padding: 0 10px 30px 10px;

    .title {
      padding: 20px 0 10px 0;
      height: fit-content;
      opacity: 1;
      font-size: 20px;
      text-align: center;
      color:white;
      //color: white;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 20px;
    }

    .light {
      margin: 15px auto 0 5px;
      text-align: justify;
      width: 50vw;
      height: fit-content;
      font-size: 14px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: white;
      line-height: 22px;

    }
    .photo{
      top:70px;
      width: 45vw;
      position: absolute;
      right:0;
    }
    .light2 {
      margin: 15px auto 0 5px;
      text-align: left;
      width: 60vw;
      height: fit-content;
      font-size: 13px;
      display: grid;
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
      font-family: Roboto, sans-serif;
      font-weight: 400;
      color: white;
      line-height: 22px;
    }
    .login{
      width: fit-content;
      margin : 0 auto;
      display: grid;
      grid-template-columns: minmax(0,1fr) minmax(0,1fr);
      column-gap: 3vw;
      font-size: 1.3vw;
      .loginUp{
        cursor: pointer;
        padding:1vh 3vw;
        border:1px solid #192079;
        color:#192079;
        border-radius: 53px;
      }
      .loginUp:hover{
        background-color: #e0dddd;
      }
      .loginIn{
        cursor: pointer;
        padding:1vh 3vw;
        background-color:#192079;
        color: white;
        border-radius: 53px;
      }
      .loginIn:hover{
        background-color:  #777996;
      }
    }



  }

  .up {
    cursor: pointer;
    position: fixed;
    top: 90%;
    right: 3%;
    padding: 5px;
    width: 45px;
    height: 45px;
    z-index: 999999999;
    border-radius: 50%;
    //border: 5px solid #1295d9;
    background-image: url("../assets/up.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
  }
  .screenMap {
    //z-index: 10;
    width: 100vw;
    height: fit-content;
    //border-radius: 9px;
    background: #f3f3f3;
    position: relative;
    padding: 0 10px 30px 10px;

    .title {
      padding: 20px 0 10px 0;
      height: fit-content;
      opacity: 1;
      font-size: 20px;
      text-align: center;
      //color: white;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 20px;
    }

    .light {
      margin: 15px auto 0 5px;
      text-align: justify;
      //width: 50vw;
      height: fit-content;
      font-size: 14px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #2C3E50;
      line-height: 22px;

    }
    .top{
      margin:15px 0 0 0 ;
      border-top: rgba(185, 185, 185, 0.5) 1px solid;
      padding-top: 1vh;
      cursor: pointer;

      .high1{
        color:gray;
        font-size: 16px;
        //line-height: 5vh;
        text-align: center;

      }
      .high2{
        color: #fd8a08;
        font-size: 16px;
        height: 20px;
        font-weight: bold;
        width: fit-content;
        margin:5px auto;
        //line-height: 3vh;
        text-align: center;

      }
    }

  }
  .screen1 {
    z-index: 3;
    position: relative;
    width: 100%;
    height: 100vh;
    padding: 0 0 30px 0;
    background-image: url("../assets/Group 22.png");
    background-position: center;
    background-size: auto;
    background-repeat: no-repeat;
    background-color: transparent;
    /* Add any additional styling as needed */

    .layer2 {
      .title1 {
        margin: 0 auto;
        width: fit-content;
        height: 23px;
        font-size: 23px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 500;
        color: #111111;
        line-height: 5vh;
      }

      .title2 {
        margin: 35px auto 0 auto;
        width: fit-content;
        height: 17px;
        font-size: 14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #111111;
        line-height: 24px;
      }

      .title3 {
        display: flex;
        text-align: center;
        margin: 20px auto 0 auto;
        width: fit-content;
        height: 35px;
        font-size: 8px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: bold;
        color: #2f6eff;
        line-height: 22px;
      }
    }

    .layer3 {
      position: absolute;
      display: flex;
      bottom: 15px;
      opacity: 1;
      width: 90%;
      justify-content: center;

      .communicity {
        z-index: 8;
        cursor: pointer;
        font-size: 14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2f6eff;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center; /* Vertically center the content */
        border-radius: 53px;
        width: fit-content;
        margin: 0 15px;
        padding: 10px 4.6875vw;
        height: 35px;
        border: #2f6eff 1px solid;
        overflow: hidden; /* Add overflow property */
      }

      .communicityGpt {
        z-index: 8;
        cursor: pointer;
        font-size: 14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: white;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center; /* Vertically center the content */
        border-radius: 53px;
        width: fit-content;
        margin: 0 15px;
        padding: 10px 4.6875vw;
        height: 35px;
        background-color: #2f6eff;
        border: #2f6eff 1px solid;
        overflow: hidden; /* Add overflow property */
      }

    }
  }


  .seriesService {
    background-color: white;
    padding: 0 10px 20px 10px;

    .title {
      padding: 20px 0 10px 0;
      height: fit-content;
      opacity: 1;
      font-size: 20px;
      text-align: center;
      //color: #53A3F7;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 20px;
    }

    .light {
      margin: 0px auto 0 auto;
      text-align: justify;
      width: 90%;
      height: fit-content;
      font-size: 14px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #2C3E50;
      line-height: 22px;

    }

    .box1 {
      box-shadow: 5px 0 14px 0px #D4E4FF;
      margin: 85px 5px 0 5px;
      padding: 10px 10px 20px 10px;
      height: 350px;
      border-bottom-right-radius: 33px;
      background-image: url("../assets/newback.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;

      .back1 {

        width: 100%;
        height: fit-content;
        margin-top: 15px;
        border-top: none;
        border-bottom-right-radius: 9px;
        border-bottom-left-radius: 9px;
        // border-radius:6px;
        padding: 10px 15px;
        justify-content: space-between;
      }

      .back {
        display: flex;
      }

      .photo {
        //background-image: url("../assets/pro1.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-color: transparent; /* 这里设置背景颜色为透明 */
        opacity: 1.25;
        width: 40vw;
        display: flex;
        margin: -75px 0 0 5%;
        border-radius: 9px;
        height: fit-content;
      }

      .photolist {

        //background-image: url("../assets/newicon1.png");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: transparent;
        color: #e3e8ed;
        margin: auto auto 0 auto;
        height: 100%;
        width: 10vw;
        font-weight: bold;
      }

      .title {
        padding: 0;
        height: fit-content;
        opacity: 1;
        font-size: 16px;
        margin: 15px 0 0 5%;
        text-align: left;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 20px;
      }

      .light {
        margin: 15px 0 0 5%;
        text-align: justify;
        height: fit-content;
        font-size: 12px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 28px;

        .lightbehind {
          display: flex;
          border: #8faffa 1px solid;
          background-color: #e4ecfd;
          margin-top: 10px;
          border-radius: 53px;
          padding: 5px 5px;
        }

        .biaoti {
          width: 15px;
          height: auto;
          margin: auto 10px auto 5px;
        }

        .iconStyle {
          margin: auto 5px auto auto;
          //animation: pulse 2s infinite;
        }

        //@keyframes pulse { 0% { transform: translateX(0px); /* 原始大小 */ } 50% { transform: translateX(5px); /* 放大到120% */ } 100% { transform: translateX(-5px); /* 恢复到原始大小 */ } }
      }
    }


  }

  .screenConsultnew {
    //z-index: 10;
    width: 100vw;
    height: fit-content;
    //border-radius: 9px;
    padding: 25px 20px;
    background: white;

    .title {

      height: fit-content;
      opacity: 1;
      font-size: 20px;
      //color: #53A3F7;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 20px;
    }

    .light {
      margin: 15px auto 0 auto;
      text-align: justify;
      width: 90%;
      height: fit-content;
      font-size: 16px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #2C3E50;
      line-height: 22px;

    }


    .pro3 {
      //margin-top: -4vh;
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      column-gap: 15px;
      row-gap: 0;

      .grand2 {
        cursor: pointer;
        width: 100%;
        height: fit-content;
        padding: 15px 0;
        opacity: 1;
        position: relative;

        .back {
          transition: transform 0.5s ease;
          width: 100%;
          height: 100%;
          background: #D4E4FF;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
        }


        .photo {
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 0 auto auto auto;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: 150px;
        }

        .title1 {
          opacity: 4;
          margin: 15px auto;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: fit-content;
          font-size: 16px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: bold;
          //color: #2168DB;
          line-height: 22px;
        }

        .title2 {
          opacity: 4;
          margin: 1.94437577255871vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;
          text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.9vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          font-size: 0.9vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }


    }


  }

  .screenGpts {
    //z-index: 10;
    width: 100vw;
    height: fit-content;
    border-radius: 9px;
    padding: 25px 20px;
    background: transparent;

    .title {

      height: fit-content;
      opacity: 1;
      font-size: 20px;
      //color: #53A3F7;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 20px;
    }

    .light {
      margin: 15px auto 0 auto;
      text-align: justify;
      width: 90%;
      height: fit-content;
      font-size: 16px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #2C3E50;
      line-height: 22px;

    }


    .pro3 {
      //margin-top: -4vh;
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      column-gap: 15px;
      row-gap: 0;

      .grand2 {
        cursor: pointer;
        width: 100%;
        height: fit-content;
        padding: 15px 0;
        opacity: 1;
        position: relative;

        .back {
          transition: transform 0.5s ease;
          width: 100%;
          height: 100%;
          background: white;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
        }


        .photo {
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 0 auto auto auto;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: 150px;
        }

        .title1 {
          opacity: 4;
          margin: 15px auto;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: fit-content;
          font-size: 16px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: bold;
          //color: #2168DB;
          line-height: 22px;
        }

        .title2 {
          opacity: 4;
          margin: 1.94437577255871vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;
          text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.9vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          font-size: 0.9vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }


    }


  }

  .screen3 {
    //z-index: 10;
    width: 100vw;
    height: fit-content;
    //border-radius: 9px;
    padding: 25px 20px;
    background: white;

    .title {
      height: fit-content;
      opacity: 1;
      font-size: 20px;
      //color: #53A3F7;
      text-align: center;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 20px;
    }

    .light {
      margin: 15px auto 0 auto;
      text-align: justify;
      width: 90%;
      height: fit-content;
      font-size: 16px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #2C3E50;
      line-height: 22px;

    }


    .pro3 {
      //margin-top: -4vh;
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      column-gap: 15px;
      row-gap: 0;

      .grand2 {
        cursor: pointer;
        width: 100%;
        height: fit-content;
        padding: 15px 0;
        opacity: 1;
        position: relative;

        .back {
          transition: transform 0.5s ease;
          width: 100%;
          height: 100%;
          background: white;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
        }


        .photo {
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 0 auto auto auto;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: 150px;
        }

        .title1 {
          opacity: 4;
          margin: 15px auto;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: fit-content;
          font-size: 16px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: bold;
          //color: #2168DB;
          line-height: 22px;
        }

        .title2 {
          opacity: 4;
          margin: 1.94437577255871vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;
          text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.9vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          font-size: 0.9vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }


    }


  }

  .screenConsult1 {
    //z-index: 10;
    width: 100vw;
    border-radius: 9px;
    padding: 25px 20px;
    background: transparent;

    .title {

      height: fit-content;
      opacity: 1;
      font-size: 20px;
      //color: #53A3F7;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 20px;
    }

    .light {
      margin: 15px auto 0 auto;
      text-align: justify;
      width: 90%;
      height: fit-content;
      font-size: 16px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #2C3E50;
      line-height: 22px;

    }


    .pro2 {
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr);
      column-gap: 0;
      row-gap: 0;

      .grand1 {
        transition: transform 0.5s ease;
        cursor: pointer;
        width: 100%;
        padding: 15px 0 0 0;
        opacity: 1;
        position: relative;

        .back {
          transition: transform 0.5s ease;
          width: 100%;
          background: white;;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          display: flex;
        }

        .photo {
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 15px 0 0 15px;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 125px;
          border-radius: 9px;
          height: 110px;
        }


        .title1 {
          opacity: 4;
          margin: 15px 15px 5px 15px;
          text-align: center;

          height: fit-content;
          font-size: 16px;
          white-space: nowrap; //换行
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: bold;
          overflow: hidden;

          text-overflow: ellipsis;
          //color: #2168DB;
          line-height: 22px;
        }

        .title2 {
          opacity: 4;
          margin: 0 15px 15px 15px;
          text-align: justify;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 4; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 14px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          //color: #2168DB;
          line-height: 22px;
          height: fit-content;
          max-height: 88px;
        }

        .consult {
          transition: transform 0.5s ease;
          position: absolute;
          display: none;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
        }
      }


    }


  }

  .screen8 {
    //z-index: 10;
    width: 100vw;
    height: fit-content;
    //border-radius: 9px;
    padding: 25px 20px;
    background: transparent;

    .title {

      text-align: center;
      height: fit-content;
      opacity: 1;
      font-size: 20px;
      //color: #53A3F7;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 20px;
    }

    .light {
      margin: 15px auto 0 auto;
      text-align: justify;
      width: 90%;
      height: fit-content;
      font-size: 16px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #2C3E50;
      line-height: 22px;

    }

    .pro2 {
      transition: transform 0.5s ease;
      height: fit-content;
      width: 100%;
      z-index: 89156;
      padding: 15px;
      display: grid;
      grid-template-columns:minmax(0, 1fr);
      //column-gap: 1vw;
    }


  }

}
// /* 右下角固定按钮 */
// .buttongroup {
//   background-color: #007bff; /* 背景颜色，可根据页面设计调整 */
//   color: white; /* 字体颜色为白色 */
//   padding: 10px 20px; /* 减小内边距，使按钮显得更小 */
//   font-size: 1.2em; /* 字体大小增大 */
//   font-weight: bold; /* 加粗字体 */
//   border-radius: 30px; /* 椭圆形的边框 */
//   text-align: center; /* 文字居中 */
//   cursor: pointer; /* 鼠标指针变为手型 */
//   transition: background-color 0.3s, transform 0.3s; /* 添加过渡效果 */
//   display: inline-block; /* 让按钮宽度根据文字内容自动调整 */
//   white-space: nowrap; /* 防止文字换行 */
// }

// .buttongroup:hover {
//   background-color: #0056b3; /* 鼠标悬停时，按钮的背景颜色变暗 */
//   transform: scale(1.1); /* 鼠标悬停时按钮放大 */
// }
</style>

